import { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import Swal from "sweetalert2";
import {
  useAvisByVisitorQuery,
  useDeleteAvisMutation,
} from "../../../../utils/api/avis/avis.api";
import { COLORS } from "../../../../utils/constants";
import { getAvatar } from "../../../../utils/utils";
import Pagination from "../../../shared/Pagination";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import AvisForm from "./AvisForm";
import "./Faq.css";

const AvisAdmin = () => {
  const [page, setPage] = useState(0);
  const { data = { results: [] } } = useAvisByVisitorQuery({ page });

  // const sortedFaq = data?.slice().sort((a, b) => moment(b.created_at  - a.created_at))
  // console.log('sortedFaq', sortedFaq)
  const [deleteAvis, { isError, error, isSuccess }] = useDeleteAvisMutation();

  const onDeleteFaq = (slug: string) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cet avis",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn  mx-2",
      },
      preConfirm: () => {
        return deleteAvis(slug);
      },
    });
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Avis supprimée avec succès",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      console.log(err);
      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);
  const modalAddAvisId = "AddAvis";
  return (
    <div className="parametre-page">
      <TopBar index={10} />
      <SideBarAdmin index={10} />
      <AvisForm modalId={modalAddAvisId} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="maajjal-card maajjal-admin-card px-2 py-2 p-xxl-3">
            <h1 className="admin-component-title">Avis sur maajjal</h1>
            <div className="faq-add-btn-container flex-cc">
              <button
                className="faq-add-btn"
                data-bs-toggle="modal"
                data-bs-target={`#${modalAddAvisId}`}
              >
                <BsPlusCircle style={{ fontSize: 20 }} />
                Ajouter
              </button>
            </div>
            <div className="faq-container mt-5">
              {data.results.map((avis) => {
                const modalId = `updateAvis${avis.id}`;
                return (
                  <div key={avis.id}>
                    <div className="faq-card-item px-3 py-3 mb-3">
                      <div className="info-title-container-container flex-fill">
                        <img
                          style={{ width: 80, height: 80, borderRadius: "50%" }}
                          src={getAvatar(avis.avatar)}
                        />
                        <small> {avis.fonction}</small>
                        <h3 className="faq-card-title">{avis.fullname}</h3>
                        <p className="faq-publish-date">{avis.avis}</p>
                      </div>
                      <div className="faq-info-action-container">
                        <div className="action-btn-container d-flex gap-2">
                          <button
                            data-bs-toggle="modal"
                            data-bs-target={`#${modalId}`}
                            className="faq-action-btn border-blue-dark"
                          >
                            <MdModeEdit />
                          </button>
                          <button
                            className="faq-action-btn border-red"
                            type="button"
                            onClick={() => onDeleteFaq(avis.slug)}
                          >
                            <FiTrash2 />
                          </button>
                        </div>
                      </div>
                    </div>
                    <AvisForm modalId={modalId} item={avis} />
                  </div>
                );
              })}
              <Pagination
                page={page}
                onPageChange={setPage}
                total={data.count}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvisAdmin;
