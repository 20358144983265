import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { FiChevronRight, FiChevronUp } from "react-icons/fi";
import { useAppSelector } from "../../../../redux/store";
import { useAchatsByCommercantQuery } from "../../../../utils/api/achat/achat.api";
import { Achat } from "../../../../utils/api/achat/achat.type";
import { ORDER_STATUT } from "../../../../utils/constants";
import { formatAmout } from "../../../../utils/utils";
import CommandeDetails from "../../TableauDeBord/mesCommandes/CommandeDetails";
import MesCommandesSkeleton from "./MesCommandesSkeleton";
function MesCommandesTable() {
  const user = useAppSelector((s) => s.user.user);
  const { data = [], isLoading } = useAchatsByCommercantQuery(
    user?.id as number
  );

  const etatFormat = (cell: any, row: Achat) => {
    const statut = ORDER_STATUT[row.status || "en_attente"];

    return (
      <div
        className="etat-commande-non-valide"
        style={{ backgroundColor: statut.color }}
      >
        <span className="text-statut-format">{statut.label}</span>
      </div>
    );
  };

  const dateFormatter = (cell: any, row: any) => {
    return <span>{moment(cell).format("DD/MM/YYYY")}</span>;
  };

  const expandRow = {
    renderer: (row: Achat, cell) => <CommandeDetails order={row} />,
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <b className="d-none">-</b>;
      }
      return <b className="d-none">+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <FiChevronUp className="dashboard-commandes-icon-collapse" />
          </b>
        );
      }
      return (
        <b>
          <FiChevronRight className="dashboard-commandes-icon-collapse" />
        </b>
      );
    },
  };

  const columns = [
    {
      dataField: "code",
      text: "N° Commande",
      style: {
        cursor: "pointer",
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "created_at",
      text: "Date",
      style: {
        cursor: "pointer",
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
    },
    {
      dataField: "produit.name",
      text: "Nom du produit",
      formatter: (cell, row) => row.produit?.name,
      style: {
        cursor: "pointer",
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "user_id.first_name",
      text: "Client",
      style: {
        cursor: "pointer",
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {},
    {
      dataField: "montant_total",
      text: "Montant total",
      formatter: (cell) => formatAmout(cell),
      style: {
        cursor: "pointer",
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "status",
      text: "Statut de la commande",
      formatter: (cell: any, row: any) => etatFormat(cell, row),
      style: {
        cursor: "pointer",
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
  ];

  const pageButtonRenderer = ({ page, active, onPageChange }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    let activeStyle: any = {};
    if (active) {
      activeStyle.backgroundColor = "#0FBF56";
      activeStyle.color = "#FFFFFF";
      activeStyle.padding = "10px 15px";
      activeStyle.borderRadius = "10px";
      activeStyle.margin = "0px 5px";
      activeStyle.textDecoration = "none";
    } else {
      activeStyle.backgroundColor = "#FFFFFF";
      activeStyle.color = "rgba(0, 0, 0, 0.7)";
      activeStyle.border = "1px solid #0FBF56";
      activeStyle.padding = "10px 15px";
      activeStyle.borderRadius = "10px";
      activeStyle.margin = "0px 5px";
      activeStyle.textDecoration = "none";
    }
    if (typeof page === "string") {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "black";
    }

    return (
      <li className="page-item my-5">
        <a href="#/" onClick={handleClick} style={activeStyle}>
          {page}
        </a>
      </li>
    );
  };

  const options = {
    pageButtonRenderer,
    prePageText: "Précédent",
    nextPageText: "Suivant",
  };

  return (
    <>
      {isLoading && <MesCommandesSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive"
          expandRow={expandRow}
          pagination={paginationFactory(options)}
        />
      )}
    </>
  );
}

export default MesCommandesTable;
