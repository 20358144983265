import { NavLink } from "react-router-dom";
import { getRoutePath } from "../../../routes/routes";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import MesOffresTable from "../../Tables/MesOffres/MesOffresTable";
import Sidebar from "../Sidebar/Sidebar";
import "./MesOffres.css";
const MesOffres = () => {
  return (
    <div className="campagne-page-container">
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={1} />
          <div className="col-md-8 col-lg-9 col-xl-10 dashboard-page-content">
            <div className="m-tableau-bord">
              <div className="row my-2">
                <div className="col-md-8">
                  <h1 className="majjal-table-caption">Mes offres</h1>
                </div>
                <div className="col-md-4 justify-content-end">
                  <NavLink
                    to={getRoutePath("AjoutOffre")}
                    className="btn-shop btn-shop-next fw-500 market-btn-link"
                  >
                    Créer une offre
                  </NavLink>
                </div>
              </div>
              <div className="maajjal-table-card px-3 py-3">
                <MesOffresTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MesOffres;
