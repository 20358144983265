import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from '../../../../../redux/store';
import { useAddQuestionMutation } from '../../../../../utils/api/faq/faq.api';
import { FaqFormData } from '../../../../../utils/api/faq/faq.type';
import { COLORS } from '../../../../../utils/constants';

function UseAjoutFaq(onHide: ()=> any) {
    const validationSchema = useMemo(
        () =>
          yup.object().shape({
            question: yup.string().required("Le champs est requis"),
            response: yup.string().nullable()
          }),
        []
      );
    
      const [message, setMessage] = useState("");
    
      const [sendData, { isSuccess, isError, isLoading, error }] =
        useAddQuestionMutation();
    
      const { register, handleSubmit, formState, clearErrors, setValue, reset } =
        useForm<FaqFormData>({
          resolver: yupResolver(validationSchema),
          defaultValues: {
            question: "",
            reponse: ""
          },
        });
    
      const user = useAppSelector((s) => s.user);
    
      const onSubmit = async (data: FaqFormData) => {
        const datas = {
          ...data,
          user: user?.user?.id,
        //   projet: projectId,
        };
        await sendData(datas);
    
        console.log("datas", datas);
      };
    
      useEffect(() => {
        if (formState.errors) {
          setTimeout(() => {
            Object.entries(formState.errors).map(([key]: any) => {
              return clearErrors(key);
            });
          }, 3000);
        }
      }, [formState]);
    
      useEffect(() => {
        // console.log("response", isSuccess, isLoading, isError, error);
        if (isSuccess) {
          Swal.fire({
            icon: "success",
            title: "Question envoyé avec succès!",
            iconColor: COLORS.green,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            onHide()
            reset();
          });
        }
    
        if (isError) {
          const err = error as any;
          setMessage(
            err?.data?.message?.toString()
              ? err?.data?.message?.toString()
              : `Une erreur de statut ${
                  err?.status ? err?.status : "inconnu"
                } est survenue.`
          );
        }
      }, [isSuccess, isError, error]);
    
      return {
        register,
        errors: formState.errors,
        onSubmit: handleSubmit(onSubmit),
        clearErrors,
        setValue,
        isLoading,
        isSuccess,
      };
}

export default UseAjoutFaq