import { useEffect } from "react";
import Swal from "sweetalert2";
import { COLORS } from "../../../../utils/constants";
import { useLocationState, useModalActions } from "../../../../utils/utils";
import BtnCloseModal from "../../../shared/BtnCloseModal";
import BtnSubmit from "../../../shared/BtnSubmit";
import PhoneInput from "../../../shared/PhoneInput";
import UseAjoutAdminForm from "../../admin/Parametre/ParametresTabs/requestAdmin/UseAjoutAdminForm";
import { usePhoneHandler } from "../../auth/Register/Register";
import { Permissions } from "./ModifierAdministrateurModal";

const AjouterAdministrateurModal = () => {
  const {
    register,
    onSubmit,
    clearErrors,
    formState,
    isSuccess,
    isLoading,
    error,
    isError,
    setValue,
    setError,
    reset,
  } = UseAjoutAdminForm();
  const ma = useModalActions("ajoutAdminModal");
  const initState = useLocationState<{ phone: string }>(null);
  const [phone, setPhone] = usePhoneHandler(
    register,
    setValue,
    initState?.phone || ""
  );

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Admin ajouté avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        reset();
      });
      ma.hide();
    }

    if (isError) {
      const err = error as any;
      if (
        err?.data?.email?.length &&
        err?.data?.email[0] === "user with this email already exists."
      ) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déja associé à un compte.",
          showConfirmButton: true,
        });
      }
    }
  }, [isSuccess, isError, error]);

  return (
    <div className="modal fade" id="ajoutAdminModal">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content py-3 px-2 px-xl-4">
          <div className="flex-csb mb-4">
            <h1 className="admin-modal-title m-0">Ajouter un administrateur</h1>
            <BtnCloseModal />
          </div>
          <form action="" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6 form-group mb-3">
                <label htmlFor="prenom" className="admin-form-label">
                  Prénom
                </label>
                <input
                  type="text"
                  id="prenom"
                  className="form-control admin-form-control"
                  placeholder="Alpha"
                  {...register("first_name")}
                />
                {formState?.errors.first_name && (
                  <div className="alert alert-danger sia-alert-danger closer">
                    {formState?.errors.first_name?.message?.toString()}
                  </div>
                )}
              </div>
              <div className="col-md-6 form-group mb-3">
                <label htmlFor="prenom" className="admin-form-label">
                  Nom
                </label>
                <input
                  type="text"
                  id="nom"
                  className="form-control admin-form-control"
                  placeholder="Diallo"
                  {...register("last_name")}
                />
                {formState?.errors.first_name && (
                  <div className="alert alert-danger sia-alert-danger closer">
                    {formState?.errors.first_name?.message?.toString()}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group mb-3">
                <label htmlFor="prentelom" className="admin-form-label">
                  Téléphone
                </label>
                <PhoneInput
                  placeholder="Téléphone"
                  error={formState.errors.phone?.message?.toString()}
                  value={phone}
                  onChnage={setPhone}
                  name="phone"
                />
              </div>
              <div className="col-md-6 form-group mb-3">
                <label htmlFor="email" className="admin-form-label">
                  Adresse mail
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-control admin-form-control"
                  placeholder="mail@gmail.com"
                  {...register("email")}
                />
                {formState?.errors.first_name && (
                  <div className="alert alert-danger sia-alert-danger closer">
                    {formState?.errors.first_name?.message?.toString()}
                  </div>
                )}
              </div>
              <Permissions register={register} />
            </div>

            <div className="btn-container flex-r mt-5">
              {isLoading && (
                <button disabled className="admin-submit-btn">
                  En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
              {!isLoading && <BtnSubmit label="Ajouter" />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AjouterAdministrateurModal;
