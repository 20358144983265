import { useCallback, useState } from 'react'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/store'
import { isAccessGranted } from '../../Modals/Administrateur/ModifierAdministrateurModal'
import EditPassword from '../../TableauDeBord/MonCompte/EditPassword'
import SideBarAdmin from '../Layouts/SideBarAdmin/SideBarAdmin'
import TopBar from '../Layouts/TopBar/TopBar'
import TeamList from '../team/Team'
import './Parametre.css'
import AjoutConditionsEtTermes from './ParametresTabs/AjoutConditionsEtTermes'
import AjoutMentionsLegales from './ParametresTabs/AjoutMentionsLegales'
import GestionMontants from './ParametresTabs/GestionMontants'
import GestionPoints from './ParametresTabs/GestionPoints'
import InfoPersonnelles from './ParametresTabs/InfoPersonnelles'
import ListesDesAdmins from './ParametresTabs/ListesDesAdmins'

const tabs = [
  'Informations',
  'Sécurité',
  'Administrateur',
  'Paramètre du site',
  'Montants minimums',
  'Conditions',
  'Mentions legales',
  "L'équipe",
]

const Parametre = () => {
  const user = useAppSelector((s) => s.user.user)
  const granted = isAccessGranted(user, 'parametre')
  const filtredTabs = granted ? tabs : tabs.filter((_, i) => i < 2)
  const [params, setParams] = useSearchParams()
  const defaultIndex = params.get('index')
  const [index, setIndex] = useState(defaultIndex ? parseInt(defaultIndex) : 0)
  const go = useCallback((index: number) => {
    setIndex(index)
    const sp = createSearchParams()
    sp.set('index', String(index))
    setParams(sp)
  }, [])

  return (
    <div className="parametre-page">
      <TopBar index={9} />
      <SideBarAdmin index={9} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="maajjal-card maajjal-admin-card px-2 py-2 p-xxl-3">
            <h1 className="admin-component-title">Paramètres</h1>
            <ul className="tabs-container mt-4">
              {filtredTabs.map((tab, key) => (
                <li
                  className={`tab-item  ${index === key && 'tab-active-item'}`}
                  key={key}
                  style={{ fontSize: 13 }}
                  onClick={() => go(key)}
                >
                  {tab}
                </li>
              ))}
            </ul>
            <div className="tabs-component-container mt-5">
              {index === 0 && <InfoPersonnelles />}
              {index === 1 && (
                <div className="p-4">
                  <EditPassword admin />
                </div>
              )}
              {index === 2 && granted && <ListesDesAdmins />}
              {index === 3 && granted && <GestionPoints />}
              {index === 4 && granted && <GestionMontants />}
              {index === 5 && granted && <AjoutConditionsEtTermes />}
              {index === 6 && granted && <AjoutMentionsLegales />}
              {index === 7 && granted && <TeamList />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Parametre
