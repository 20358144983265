import React from 'react'
import Skeleton from 'react-loading-skeleton'

function CommandesAdminSkeleton() {
  return (
    <div className="col-md-12 as-col-table-container">
    <table className="table table-striped">
        <thead>
            <tr>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}>Date</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}>Nom du produit</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}>Commerçant</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}>Client</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}>Montant total</th>
                <th scope="col" style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}>Statut de la commande</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
           
        </tbody>
    </table>
</div>
  )
}

export default CommandesAdminSkeleton