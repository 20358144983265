import { Navigate, Route } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import { User } from "../../utils/api/user/user.type";
import { isAccessGranted } from "../modules/Modals/Administrateur/ModifierAdministrateurModal";
import { SEO } from "../shared/SEO";

import {
  adminRoutes,
  authRoutes,
  commercantRoutes,
  commonRoutes,
  gestRedirectRoute,
  getRoutePath,
  userRoutes,
} from "./routes";

export function RenderCommonRoutes() {
  return Object.keys(commonRoutes).map((key) => {
    const { component: Component, path } = commonRoutes[key];
    return <Route path={path} element={<Component />} key={path} />;
  });
}

export function RenderAuthRoutes() {
  const { user } = useAppSelector((s) => s.user);
  return Object.keys(authRoutes).map((key) => {
    const { component: Component, path } = authRoutes[key];
    return (
      <Route
        key={path}
        path={path}
        element={
          !user ? (
            <>
              <SEO title="Authentification" />
              <Component />
            </>
          ) : (
            <Navigate to={gestRedirectRoute(user)} />
          )
        }
      />
    );
  });
}

export function RenderUserRoutes() {
  const { user } = useAppSelector((s) => s.user);
  return Object.keys(userRoutes).map((key) => {
    const { component: Component, path } = userRoutes[key];
    const redirectPath = user
      ? user?.is_double_type
        ? getRoutePath("MerchantDashboard")
        : null
      : getRoutePath("login");
    return (
      <Route
        key={path}
        path={path}
        element={
          !redirectPath ? (
            <>
              <SEO title="Dashboard investisseur" />
              <Component />
            </>
          ) : (
            <Navigate to={redirectPath} />
          )
        }
      />
    );
  });
}

export function RenderCommercantRoutes() {
  const { user } = useAppSelector((s) => s.user);
  return Object.keys(commercantRoutes).map((key) => {
    const { component: Component, path } = commercantRoutes[key];
    const redirectPath = user
      ? !user?.is_double_type
        ? getRoutePath("ListeProjets")
        : null
      : getRoutePath("login");
    return (
      <Route
        key={path}
        path={path}
        element={
          !redirectPath ? (
            <>
              <SEO title="Dashboard commerçant" />
              <Component />
            </>
          ) : (
            <Navigate to={redirectPath} />
          )
        }
      />
    );
  });
}

export function RenderAdminRoutes() {
  const { user } = useAppSelector((s) => s.user);
  return Object.keys(adminRoutes).map((key) => {
    const { component: Component, path, p: permission } = adminRoutes[key];
    return (
      <Route
        key={path}
        path={path}
        element={
          isAdmin(user) ? (
            isAccessGranted(user, permission) ? (
              <>
                <SEO title="Dashboard admin" />
                <Component />
              </>
            ) : (
              <Navigate to={"/not-found"} />
            )
          ) : (
            <Navigate to={getRoutePath("login")} />
          )
        }
      />
    );
  });
}

export function isInvestisseur(user?: User) {
  return user && !isAdmin(user) && !isCommercant(user);
}

export function isCommercant(user?: User) {
  return user && user?.is_double_type && !isAdmin(user);
}

export function isAdmin(user?: User) {
  return user && user.user_type === "admin";
}
