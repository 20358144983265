import { User } from "../../utils/api/user/user.type";
import AvisAdmin from "../modules/admin/Avis/Avis";
import Category from "../modules/admin/category/category";
import CategoryProduct from "../modules/admin/category_product/category_product";
import CommandesAdmin from "../modules/admin/Commandes/CommandesAdmin";
import DetailsCommandeAdmin from "../modules/admin/Commandes/DetailsCommandeAdmin";
import DashboardAdmin from "../modules/admin/DashboardAdmin/DashboardAdmin";
import InformationsDonnateur from "../modules/admin/Donnateur/InformationsDonnateur";
import ListeDonnateurAdmin from "../modules/admin/Donnateur/ListeDonnateursAdmin";
import FaqAdmin from "../modules/admin/Faq/Faq";
import Messages from "../modules/admin/Messages/Messages";
import Parametre from "../modules/admin/Parametre/Parametre";
import AjoutProduitAdmin from "../modules/admin/Produits/AjoutProduitAdmin";
import DetailsProduitsAdmin from "../modules/admin/Produits/DetailsProduitsAdmin";
import ListeProduitsAdmin from "../modules/admin/Produits/ListeProduitsAdmin";
import ModifProduitAdmin from "../modules/admin/Produits/ModifProduitAdmin";
import DetailsProjetAdmin, {
  AddProjectAdmin,
  UpdateProject,
} from "../modules/admin/Projets/detailsProjet/DetailsProjetAdmin";
import ListeDesProjetsAdmin from "../modules/admin/Projets/ListeDesProjetsAdmin";
import DetailsUtilisateurAdmin from "../modules/admin/utilisateurs/DetailsUtilisateurAdmin";
import UtilisateursAdmin from "../modules/admin/utilisateurs/UtilisateursAdmin";
import Apropos from "../modules/apropos/Apropos";
import { CGU, PC } from "../modules/apropos/CGU";
import ForgetPassword from "../modules/auth/ForgetPassword/ForgetPassword";
import ResetPassword from "../modules/auth/ForgetPassword/ResetPassword";
import Login from "../modules/auth/Login/Login";
import Register from "../modules/auth/Register/Register";
import CampainsDetails from "../modules/compains/CampainsDetails/CampainsDetails";
import CompainsListe from "../modules/compains/CompainsListe/CompainsListe";
import ContactezNous from "../modules/ContactezNous/ContactezNous";
import HomePage from "../modules/home/HomePage";
import LaunchACampain from "../modules/LaunchACampaingn/LaunchACampain";
import UpdateCampain from "../modules/LaunchACampaingn/UpdateCampain";
import UpdateOffre from "../modules/LaunchACampaingn/UpdateOffre";
import MerchantDashboard from "../modules/merchant/MerchantDashboard/MerchantDashboard";
import { PERMISSIONS } from "../modules/Modals/Administrateur/ModifierAdministrateurModal";
import MyShoppingCart from "../modules/MyCart/MyShoppingCart";
import AjoutOffre from "../modules/Offres/AjoutOffre";
import MarketPlace from "../modules/shop/MarketPlace/MarketPlace";
import ProductMarketDetail from "../modules/shop/MarketPlace/ProductMarketDetail";
import MesCommandes from "../modules/TableauDeBord/mesCommandes/MesCommandes";
import MesDons from "../modules/TableauDeBord/mesDons/MesDons";
import DetailsProjet from "../modules/TableauDeBord/mesprojets/DetailsProjet/DetailsProjet";
import ListeProjets from "../modules/TableauDeBord/mesprojets/ListeProjets/ListeProjets";
import MonCompte from "../modules/TableauDeBord/MonCompte/MonCompte";
import DetailOffre from "../modules/TableauDeBord/Offre/DetailOffre";
import MesOffres from "../modules/TableauDeBord/Offre/MesOffres";
import MesPoints from "../modules/TableauDeBord/Points/MesPoints";

type RouteObj = {
  [key: string]: { path: string; component: any; p?: keyof typeof PERMISSIONS };
};
export const commonRoutes: RouteObj = {
  home: {
    path: "/",
    component: HomePage,
  },
  CompainsListe: {
    path: "/app/campagnes",
    component: CompainsListe,
  },
  CampainsDetails: {
    path: "/app/campagnes/:slug",
    component: CampainsDetails,
  },
  LaunchACampain: {
    path: "/app/lancer-une-campagne",
    component: LaunchACampain,
  },
  MarketPlace: {
    path: "/app/marketplace",
    component: MarketPlace,
  },
  ProductMarketDetail: {
    path: "/app/marketplace/:slug",
    component: ProductMarketDetail,
  },
  AjoutOffre: {
    path: "/app/ajout-offre",
    component: AjoutOffre,
  },
  Apropos: {
    path: "/app/qui-sommes-nous",
    component: Apropos,
  },
  CGU: {
    path: "/app/conditions-et-termes",
    component: CGU,
  },
  PC: {
    path: "/app/mentions-legales",
    component: PC,
  },
  ContactezNous: {
    path: "/app/nous-contacter",
    component: ContactezNous,
  },
  MyShoppingCart: {
    path: "/app/mon-panier",
    component: MyShoppingCart,
  },
} as const;

export const authRoutes: RouteObj = {
  login: {
    component: Login,
    path: "/se-connecter",
  },
  forgetPassword: {
    component: ForgetPassword,
    path: "/recuperation-mot-de-passe",
  },
  register: {
    component: Register,
    path: "/s'inscrire",
  },
  resetPassword: {
    component: ResetPassword,
    path: "/reset-password",
  },
} as const;

export const userRoutes: RouteObj = {
  ListeProjets: {
    path: "/app/tableau-de-bord/mes-projets",
    component: ListeProjets,
  },
  UpdateCampain: {
    path: "/app/tableau-de-bord/mes-projets/modifier-projet/:slug",
    component: UpdateCampain,
  },
  DetailsProjet: {
    path: "/app/tableau-de-bord/mes-projets/:slug",
    component: DetailsProjet,
  },
  MesDons: {
    path: "/app/tableau-de-bord/mes-dons",
    component: MesDons,
  },
  MesCommandes: {
    path: "/app/tableau-de-bord/mes-commandes",
    component: MesCommandes,
  },
  MonCompte: {
    path: "/app/tableau-de-bord/mon-compte",
    component: MonCompte,
  },

  MesPoints: {
    path: "/app/tableau-de-bord/mes-points",
    component: MesPoints,
  },
};

export const commercantRoutes: RouteObj = {
  MerchantDashboard: {
    path: "/commercant/dashbord",
    component: MerchantDashboard,
  },
  ProductList: {
    path: "/commercant/liste-des-produits",
    component: MesOffres,
  },
  UpdateOffre: {
    path: "/commercant/liste-des-produits/modifier/:slug",
    component: UpdateOffre,
  },
  AjouterProduit: {
    path: "/commercant/ajouter-produit",
    component: AjoutOffre,
  },
  CommandeList: {
    path: "/commercant/liste-des-commandes",
    component: MesCommandes,
  },
  ParametreProfil: {
    path: "/commercant/parametres",
    component: MonCompte,
  },
  DetailOffre: {
    path: "/commercant/liste-des-produits/:slug",
    component: DetailOffre,
  },
  MesPointsCommercant: {
    path: "/commercant/mes-points",
    component: MesPoints,
  },
};

export const adminRoutes: RouteObj = {
  DashboardAdmin: {
    path: "/admin/tableau-de-bord",
    // p: "dashboard",
    component: DashboardAdmin,
  },
  ListeDonnateurAdmin: {
    path: "/admin/liste-donnateurs",
    p: "dashboard",
    component: ListeDonnateurAdmin,
  },
  InformationsDonnateur: {
    path: "/admin/liste-donnateurs/:slug",
    p: "dashboard",
    component: InformationsDonnateur,
  },
  ListeDesProjetsAdmin: {
    path: "/admin/projets",
    p: "project",
    component: ListeDesProjetsAdmin,
  },
  UpdateProject: {
    path: "/admin/projets/modifier/:slug",
    p: "project",
    component: UpdateProject,
  },
  DetailsProjetAdmin: {
    path: "/admin/projets/:slug",
    p: "project",
    component: DetailsProjetAdmin,
  },
  AjoutProjetAdmin: {
    path: "/admin/ajouter-projets",
    p: "project",
    component: AddProjectAdmin,
  },
  UtilisateursAdmin: {
    path: "/admin/utilisateurs",
    p: "users",
    component: UtilisateursAdmin,
  },
  DetailsUtilisateurAdmin: {
    path: "/admin/utilisateurs/:slug",
    p: "users",
    component: DetailsUtilisateurAdmin,
  },
  ListeProduitsAdmin: {
    path: "/admin/produits",
    p: "products",
    component: ListeProduitsAdmin,
  },
  AjoutProduitAdmin: {
    path: "/admin/ajouter-produits",
    p: "products",
    component: AjoutProduitAdmin,
  },
  ModifProduitAdmin: {
    path: "/admin/modifier-produits",
    p: "products",
    component: ModifProduitAdmin,
  },
  DetailsProduitsAdmin: {
    path: "/admin/details-produits",
    p: "products",
    component: DetailsProduitsAdmin,
  },
  CommandesAdmin: {
    path: "/admin/commandes",
    p: "commande",
    component: CommandesAdmin,
  },
  DetailsCommandeAdmin: {
    path: "/admin/commandes/:slug",
    p: "commande",
    component: DetailsCommandeAdmin,
  },
  FaqAdmin: {
    path: "/admin/faq",
    p: "faq",
    component: FaqAdmin,
  },
  AvisAdmin: {
    path: "/admin/avis",
    p: "avis",
    component: AvisAdmin,
  },
  Messages: {
    path: "/admin/messages",
    p: "message",
    component: Messages,
  },
  Parametre: {
    path: "/admin/parametres",
    component: Parametre,
  },
  CategoryProduct: {
    path: "/admin/categorie-produit",
    p: "categoryproduct",
    component: CategoryProduct,
  },
  Category: {
    path: "/admin/categorie-projet",
    p: "categoryprojet",
    component: Category,
  },
};

type AuthRoutes = keyof typeof authRoutes;
type UserRoutes = keyof typeof userRoutes;
type AdminRoutes = keyof typeof adminRoutes;
type CommonRoutes = keyof typeof commonRoutes;
type CommercantRoutes = keyof typeof commercantRoutes;

export type Routes =
  | AuthRoutes
  | UserRoutes
  | AdminRoutes
  | CommonRoutes
  | CommercantRoutes;

export function getRoutePath(
  name: Routes,
  params?: { [key: string]: string | number | undefined }
): string {
  const getRoute = (): string => {
    if (authRoutes[name]) return authRoutes[name].path;
    if (userRoutes[name]) return userRoutes[name].path;
    if (adminRoutes[name]) return adminRoutes[name].path;
    if (commonRoutes[name]) return commonRoutes[name].path;
    if (commercantRoutes[name]) return commercantRoutes[name].path;
    throw new Error(`Route ${name} not found`);
  };
  let route = getRoute();
  if (params) {
    for (let key of Object.keys(params)) {
      if (params[key]) {
        route = route.replace(`:${key}`, String(params[key]));
      }
    }
  }
  return route;
}

export function gestRedirectRoute(user?: User) {
  if (user?.user_type === "investisseur") {
    return getRoutePath("ListeProjets");
  }
  if (user?.user_type === "commercant") {
    return getRoutePath("MerchantDashboard");
  }
  return getRoutePath("DashboardAdmin");
}
