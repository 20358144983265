import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import "./ContactezNous.css";
import UseContactForm from "./requestContact/useContactForm";
const ContactezNous = () => {
  const { register, setValue, formState, onSubmit, isLoading } =
    UseContactForm();
  return (
    <div className="contactez-page">
      <FrontHeader />
      <div className="">
        <div className="quisommesnous-page-couverture-container">
          <h2 className="contactez-nous-title">C O N T A C T E Z - N O U S</h2>
        </div>
        <div className="custom-container my-5 px-3">
          <div className="row">
            <form
              onSubmit={onSubmit}
              className="col-xxl-6 col-md-10 col-lg-8 offset-xxl-3 offset-md-1 offset-lg-2"
            >
              <div className="form-group mb-4">
                <label htmlFor="fullname" className="form-contact-label">
                  Nom et prénom
                </label>
                <span className="text-danger">*</span>
                <input
                  type="text"
                  className="form-contact-input form-control"
                  placeholder="Noms"
                  {...register("fullname")}
                />
                {formState.errors.fullname && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {formState.errors.fullname?.message?.toString()}
                  </div>
                )}
              </div>
              <div className="form-group mb-4">
                <label htmlFor="email_from" className="form-contact-label">
                  Email
                </label>
                <span className="text-danger">*</span>
                <input
                  type="email"
                  className="form-contact-input form-control"
                  placeholder="Email"
                  {...register("email_from")}
                />
                {formState.errors.email_from && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {formState.errors.email_from?.message?.toString()}
                  </div>
                )}
              </div>

              <div className="form-group mb-4">
                <label htmlFor="message" className="form-contact-label">
                  Message
                </label>
                <span className="text-danger">*</span>
                <textarea
                  className="form-contact-textarea form-control"
                  placeholder="Message"
                  {...register("message")}
                />
                {formState.errors.message && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {formState.errors.message?.message?.toString()}
                  </div>
                )}
              </div>
              <div className="form-contact-btn-container ">
                {isLoading && (
                  <button disabled className="form-contact-btn px-4 py-3">
                    En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isLoading && (
                  <button className="form-contact-btn px-4 py-3" type="submit">
                    ENVOYER
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default ContactezNous;
