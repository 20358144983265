import React from "react";

interface PropsType {
	label: string;
}

function BtnSubmit({ label }: PropsType) {
	return <button className="admin-submit-btn">{label}</button>;
}

export default BtnSubmit;
