import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useProduitFromLocation } from "../../../utils/api/produit/produit.api";
import {
  Produit,
  ProduitFormData,
} from "../../../utils/api/produit/produit.type";
import { getImage } from "../../../utils/utils";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import GoBackBtn from "../../shared/GoBackBtn";
import UseAjouterProduitForm from "../merchant/ProductList/UseAjouterProduitForm";
import { OffreForm } from "../Offres/AjoutOffre";
import Sidebar from "../TableauDeBord/Sidebar/Sidebar";
import { useFieldHandler } from "./LaunchACampain";
import "./LaunchACampain.css";

const UpdateOffre = ({ produit }: { produit: Produit }) => {
  const navigate = useNavigate();

  const { register, onSubmit, errors, setValue, isLoading } =
    UseAjouterProduitForm(produit.id);

  const [description, setDescription] = useFieldHandler(
    register,
    setValue,
    produit.description,
    "description"
  );

  useEffect(() => {
    const fields: (keyof ProduitFormData)[] = [
      "category",
      "name",
      "prix",
      "discount",
      "point",
      "description",
    ];
    for (let field of fields) {
      let val = produit[field];
      if (field === "category") {
        val = produit?.category?.id || produit?.category;
      }
      register(field);
      setValue(field, val);
    }
  }, []);

  return (
    <div className="campagne-page-container">
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={1} />
          <div className="col-md-8 col-lg-9 col-xl-10 dashboard-page-content">
            <div className="m-tableau-bord py-5">
              <div className="maajjal-table-card px-3 py-4">
                <GoBackBtn
                  className="detail-offre-back-btn detail-offre-title"
                  label="Modifier produit"
                />

                <OffreForm
                  {...{
                    errors,
                    onSubmit,
                    register,
                    isLoading,
                    update: true,
                    description,
                    setDescription,
                    setValue,
                    initFile: getImage(produit.avatar),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default () => {
  const [item] = useProduitFromLocation();
  if (!item) return null;
  return <UpdateOffre produit={item} />;
};
