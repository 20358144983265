interface PropsType {
  modalId: any;
  navigation: any;
}
export const ButtonBack = ({ modalId, navigation }: PropsType) => {
  return (
    <button
      type="button"
      className="btn-shop btn-shop-cancel"
      {...(!navigation ? { "data-bs-dismiss": "modal" } : {})}
      onClick={(e) => {
        if (navigation) {
          navigation.previous(e);
        }
      }}
    >
      Annuler
    </button>
  );
};

export const ButtonNext = () => {
  return (
    <button type="submit" className="btn-shop-next btn-shop">
      Confirmer
    </button>
  );
};
