import { useEffect } from "react";
import { useAppSelector } from "../../redux/store";
import {
  PAYMENT_TYPE,
  Projet,
} from "../../utils/api/launchCampagn/projet.type";
import { formatAmout, useModalActions } from "../../utils/utils";
import { usePhoneHandler } from "../modules/auth/Register/Register";
import PhoneInput from "../shared/PhoneInput";
import { ConfirmInvestModal } from "./ConfirmationInvestModal";
import "./InvestissementModal.css";
import useAddInvestsForm from "./stepsComponents/useAddInvests";
function InvestissementModal({
  modalId,
  projet,
  type,
  admin,
}: {
  modalId: string;
  projet: Projet;
  type: PAYMENT_TYPE;
  admin?: boolean;
}) {
  const ma = useModalActions(modalId);
  const confirmModalId = `${modalId}_confirm`;
  const cma = useModalActions(confirmModalId);
  const user = useAppSelector((s) => s.user?.user);
  const {
    errors,
    isLoading,
    onSubmit,
    register,
    setValue,
    getValues,
    validate,
  } = useAddInvestsForm(projet, cma.hide, admin);
  const [phone, setPhone] = usePhoneHandler(
    register,
    setValue,
    admin ? "" : user?.phone || ""
  );

  useEffect(() => {
    if (type) {
      register("mode_paiement");
      setValue("mode_paiement", type);
    }
  }, [type]);

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        data-bs-backdrop="static"
        data-backdrop="false"
        data-bs-keyboard="false"
        aria-labelledby={`label${modalId}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content custom-modal-investissement">
            <div className="modal-header modal-header-investissement">
              <h5 className="modal-title" id={`label${modalId}`}>
                {admin ? "Ajouter un don" : "Contribuer au projet"}
              </h5>
            </div>
            <div className="modal-body modal-invessetissement-body">
              <div className="modal-contribution-steps">
                {!admin && (
                  <div className="modal-investissement-description-mode-paie">
                    Je participe
                  </div>
                )}
                <div className="modal-investissement-montants-input">
                  <input
                    type="text"
                    className="form-control-contribution-steps form-control form-control-lg"
                    {...register("prenom")}
                    placeholder={admin ? "Prénom" : "Votre prénom"}
                  />
                  {errors.prenom && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.prenom?.message?.toString()}
                    </div>
                  )}
                </div>
                <div className="modal-investissement-montants-input">
                  <input
                    type="text"
                    className="form-control-contribution-steps form-control form-control-lg"
                    {...register("nom")}
                    placeholder={admin ? "Nom" : "Votre nom"}
                  />
                  {errors.nom && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.nom?.message?.toString()}
                    </div>
                  )}
                </div>
                <div className="modal-investissement-montants-input">
                  <input
                    type="text"
                    className="form-control-contribution-steps form-control form-control-lg"
                    {...register("email")}
                    placeholder="Email"
                  />
                  {errors.email && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.email?.message?.toString()}
                    </div>
                  )}
                </div>
                <div className="modal-investissement-montants-input">
                  <PhoneInput
                    placeholder="Téléphone"
                    error={errors.phone?.message?.toString()}
                    value={phone}
                    onChnage={setPhone}
                    name="phone"
                  />
                </div>
                <div className="modal-investissement-montants-input">
                  <input
                    type="number"
                    className="form-control-contribution-steps form-control form-control-lg"
                    {...register("funds")}
                    placeholder={`Montant à participer (FCFA) ${
                      projet.min_amount &&
                      `Montant minimum: ${formatAmout(projet.min_amount)}`
                    }`}
                  />
                  {errors.funds && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.funds?.message?.toString()}
                    </div>
                  )}
                </div>
                <div className="modal-investissement-montants">
                  <div className="form-check modal-investissement-montants-radio-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register("anonyme")}
                    />
                    <label className="form-check-label pt-1" htmlFor="anonyme	">
                      Participation anonyme
                    </label>
                  </div>
                </div>
                <div>
                  <div className="modal-investissement-description-mode-paie">
                    Commentaire
                  </div>
                  <div className="modal-investissement-montants-input">
                    <textarea
                      className="form-control-contribution-steps form-control form-control-lg"
                      {...register("commentaire")}
                      rows={4}
                    ></textarea>
                  </div>
                </div>
                <div className="modal-investissement-montants-input-bouton">
                  <div className="modal-investissement-montants-button-container">
                    <button
                      type="button"
                      className="btn  modal-investissement-montants-btn-annuler"
                      onClick={() => {
                        ma.hide();
                      }}
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      className="btn modal-investissement-montants-btn-continuer"
                      onClick={() => {
                        validate((data) => {
                          console.error("validated", data);
                          ma.hide();
                          setTimeout(() => {
                            cma.show();
                          }, 1000);
                        })();
                      }}
                    >
                      Continuer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmInvestModal
        projectName={projet.name}
        funds={getValues().funds}
        onHide={() => cma.hide()}
        loading={isLoading}
        onConfirm={() => {
          onSubmit();
        }}
        modalId={confirmModalId}
      />
    </>
  );
}

export default InvestissementModal;
