import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { FiEye } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useGetcommandesQuery } from "../../../../../utils/api/achat/achat.api";
import { Achat } from "../../../../../utils/api/achat/achat.type";
import { ORDER_STATUT } from "../../../../../utils/constants";
import { formatMontant, formattedDate } from "../../../../../utils/utils";
import Pagination from "../../../../shared/Pagination";
import CommandesAdminSkeleton from "./CommandesAdminSkeleton";

function CommandesAdminTable({
  word1,
  word2,
  word3,
}: {
  word1: string;
  word2: string;
  word3: string;
}) {
  const [page, setPage] = useState(1);
  const { data = { results: [] }, isLoading } = useGetcommandesQuery({
    page,
    wordproduit: word1,
    wordcommercant: word2,
    wordclient: word3,
  });
  useEffect(() => {
    setPage(1);
  }, [word1, word2, word3]);
  const etatFormat = (cell: any, row: Achat) => {
    const statut = ORDER_STATUT[row.status || "en_attente"];
    return (
      <div
        className="etat-commande-livrer"
        style={{ backgroundColor: statut.color }}
      >
        <span className="text-statut-format">{statut.label}</span>
      </div>
    );
  };

  const actionFormatter: any = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance">
        <NavLink
          className="action-btn "
          title="Voir"
          to={`/admin/commandes/${row?.slug}`}
          state={{ commande: row }}
        >
          <FiEye className="voir-btn" />
        </NavLink>
      </div>
    );
  };
  const productNameFormatter: any = (cell, row) => {
    return row?.produit?.name;
  };
  const dateFormatter: any = (cell, row) => {
    return formattedDate(cell);
  };
  const commercantFormatter: any = (cell, row) => {
    return (
      row?.produit?.commercant?.first_name +
      " " +
      row?.produit?.commercant?.last_name
    );
  };
  const clientFormatter: any = (cell, row) => {
    return row?.user_id?.first_name + " " + row?.user_id?.last_name;
  };
  const montantFormatter: any = (cell, row) => {
    return formatMontant(cell);
  };

  const columns = [
    {
      dataField: "created_at",
      text: "Date",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "400",
      },
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
    },
    {
      dataField: "nom_produit",
      text: "Nom du produit",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "400",
      },
      formatter: (cell: any, row: any) => productNameFormatter(cell, row),
    },
    {
      dataField: "commercant",
      text: "Commerçant",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "400",
      },
      formatter: (cell: any, row: any) => commercantFormatter(cell, row),
    },
    {
      dataField: "client",
      text: "Client",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "400",
      },
      formatter: (cell: any, row: any) => clientFormatter(cell, row),
    },
    {},
    {
      dataField: "montant_total",
      text: "Montant total",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "400",
      },
      formatter: (cell: any, row: any) => montantFormatter(cell, row),
    },
    {
      dataField: "status",
      text: "Statut de la commande",
      formatter: (cell: any, row: any) => etatFormat(cell, row),
      style: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      style: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  return (
    <>
      {isLoading && <CommandesAdminSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data.results}
          columns={columns}
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive"
        />
      )}
      <Pagination onPageChange={setPage} page={page} total={data.count} />
    </>
  );
}

export default CommandesAdminTable;
