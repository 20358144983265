import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { onSetUserToken } from "../../../../redux/slices/user.slice";
import { useAppDispatch } from "../../../../redux/store";
import { useLoginMutation } from "../../../../utils/api/auth/auth.api";
import { AuthFormData } from "../../../../utils/api/auth/auth.type";
import { getRoutePath } from "../../../routes/routes";

export function validatePassword(Yup, message) {
  return Yup.string()
    .required(message)
    .min(8, "Mot de passe doit être d'au moins 8 caractères");
}

function UseLoginForm() {
  const [login, { error, data, isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email()
          .required("L'email est requis")
          .label("Email"),
        password: yup.string().required("Le mot de passe est requis."),
      }),
    []
  );

  const { register, handleSubmit, formState, setError, clearErrors } =
    useForm<AuthFormData>({
      resolver: yupResolver(validationSchema),
    });

  useEffect(() => {
    if (error) {
      const err = error as any;
      console.log(err)
      const { message = "Une erreur inconnue s'est produite" } = err.data || {};
      setError("email", { message: message });
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      dispatch(onSetUserToken({ token: data.token, user: data.data }));
      setTimeout(() => {
        if (data.data.user_type === "admin") {
          navigate(getRoutePath("DashboardAdmin"));
        } else {
          if (data.data.is_double_type) {
            navigate(getRoutePath("MerchantDashboard"));
          } else {
            navigate(getRoutePath("ListeProjets"));
          }
        }
      }, 400);
    }
  }, [data]);

  return {
    register,
    errors: formState.errors,
    onSubmit: handleSubmit(login),
    clearErrors,
    isLoading,
  };
}

export default UseLoginForm;
