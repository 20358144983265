import { useState } from "react";
import UtilisateurAdminTable from "../../Tables/admin/utilisateurs/UtilisateurAdminTable";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import "./UtilisateurAdmin.css";

function UtilisateursAdmin() {
  const [word, setWord] = useState("");
  return (
    <div className="parametre-page">
      <TopBar index={2} />
      <SideBarAdmin index={2} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="p4 p-xxl-3">
            <div className="row">
              <div className="col-md-12 maajjal-table-card px-3 py-4  ml-2 mr-3">
                <div className="col-md-12">
                  <h3 className="tb-mes-projet-titre mb-4">
                    Liste des utilisateurs
                  </h3>
                </div>
                <div className="dashboard-commandes-filtre-container ">
                  <form className="row g-3 dashboard-commandes-filtre-form">
                    <div className="col-auto">
                      <label
                        htmlFor="staticEmail2"
                        className="dashboard-commandes-filtre-label"
                      >
                        Filtrer
                      </label>
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control dashboard-commandes-filtre-input"
                        id="inputPassword2"
                        value={word}
                        onChange={(e) => setWord(e.target.value)}
                        placeholder="Chercher..."
                      />
                    </div>
                  </form>
                </div>
                <UtilisateurAdminTable {...{ word }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UtilisateursAdmin;
