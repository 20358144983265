import React from "react";

interface PropsType {
  label: string;
  onCheck: (checked: boolean) => any;
  checked: boolean;
}

const FilterMarketItem: React.FC<PropsType> = ({ label, onCheck, checked }) => {
  return (
    <li className="filter-market-item mb-2 item-c gap-3">
      <input
        id={label}
        type="checkbox"
        checked={checked}
        onChange={(e) => onCheck(!!e.target.checked)}
        className="form-check-input form-filter-check-input m-0"
      />
      <label
        className="form-filter-label m-0"
        htmlFor={label}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </li>
  );
};

export default FilterMarketItem;
