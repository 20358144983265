import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import InvestApi from "../../../../utils/api/invest/invest.api";
import { formatAmout } from "../../../../utils/utils";
import DetailProjetSkeleton from "./DetailProjetSkeleton";

function DetailProjetTable({ projet }: { projet: any }) {
  const { data = [], isLoading } = InvestApi.useInvestsbyProjetQuery(
    projet?.id as number
  );

  const columns = [
    {
      dataField: "createt_at",
      text: "Date",
      formatter: (cell, row) => moment(cell).format("DD/MM/YYYY"),
      style: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "user_id",
      formatter: (cell, row) =>
        row?.anonyme ? "Anonyme" : `${row?.prenom} ${row?.nom}`,
      text: "Donnateur",
      style: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "funds",
      text: "Montant",
      formatter: (cell) => formatAmout(cell),
      style: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
  ];

  return (
    <>
      {isLoading && <DetailProjetSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          // striped
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive"
        />
      )}
    </>
  );
}

export default DetailProjetTable;
