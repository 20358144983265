import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiUrl } from "../../constants";
import { prepareHeaders } from "../user/user.api";
import { Condition } from "./condition.type";

const ConditionApi = createApi({
  reducerPath: "condition",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["condition_generale_utilisation", "condition_generale_vente"],
  endpoints: (build) => ({
    addCondition: build.mutation<Condition, { id?: number; data: Condition }>({
      query: ({ id, data }) => ({
        url: id ? `/condition/${id}/` : `/condition/`,
        method: id ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: [
        "condition_generale_utilisation",
        "condition_generale_vente",
      ],
    }),
    condition_generale_utilisation: build.query<Condition | null, void>({
      query: () => `/condition_generale_utilisation/`,
      transformResponse: ({ results }) => (results ? results[0] : null),
      providesTags: ["condition_generale_utilisation"],
    }),
    condition_generale_vente: build.query<Condition | null, void>({
      query: () => `/condition_generale_vente/`,
      transformResponse: ({ results }) => (results ? results[0] : null),
      providesTags: ["condition_generale_vente"],
    }),
  }),
});

export default ConditionApi;
