import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../redux/store";
import { useAddProjetMutation } from "../../../utils/api/launchCampagn/projet.api";
import { ProjetFormData } from "../../../utils/api/launchCampagn/projet.type";
import { COLORS } from "../../../utils/constants";
import { getRoutePath } from "../../routes/routes";
export const exts = ["png", "jpeg", "gif", "jpg"];
function UseLaunchCampagnForm(projetId) {
  const isAdmin = useAppSelector((s) => s.user.user?.user_type === "admin");
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required("Le nom du projet est requis"),
        description: yup
          .string()
          .required("La description du projet est requis"),
        objectif_levee: yup
          .number()
          .typeError("Vous devez entrer un nombre")
          .label("Montant")
          .min(0)
          .required()
          .test(
            "funds-match",
            "Le montant doit être supérieur au montant minimum",
            function (value) {
              // @ts-ignore
              const min = this.parent.min_amount;
              console.log({ min, value });
              if (!min) return true;
              return parseInt(min) < parseInt(String(value));
            }
          ),

        minim_souscription: yup.number().nullable(),
        min_amount: yup.number().nullable(),
        telephone: yup.string().required("Le numéro de téléphone est requis"),
        email: yup.string().email().required("L'email est requis"),
        network: yup.boolean(),
        nb_investisseur: yup.number().nullable(),
        nb_like: yup.number().nullable(),
        pourcentage: yup.number().nullable(),
        date_debut: yup.string(),
        date_fin: yup.string(),
        links: yup.string(),
        video_link: yup.string(),
        category_id: yup.number().nullable(),
        photo: projetId
          ? yup.mixed()
          : validateImage().required("L'image est  requise.").label("Image"),
      }),
    []
  );

  const navigate = useNavigate();

  const [sendData, { isSuccess, isError, isLoading, error }] =
    useAddProjetMutation();

  const { register, handleSubmit, formState, clearErrors, setValue } =
    useForm<ProjetFormData>({
      resolver: yupResolver(validationSchema),
    });

  const user = useAppSelector((s) => s.user);

  const onSubmit = async (data: ProjetFormData) => {
    let duree = moment(data.date_fin).diff(moment(data.date_debut), "days");
    // console.log(data,duree)
    if (duree >= 0) {
      let fd = new FormData();
      if (!projetId && user?.user?.id) {
        fd.append("user", String(user?.user?.id));
      }
      Object.keys(data).map((key) => {
        fd.append(key, data[key]);
      });
      //@ts-ignore
      await sendData({ data: fd, id: projetId });
    } else {
      Swal.fire({
        icon: "error",
        iconColor: COLORS.red,
        showConfirmButton: false,
        timer: 2000,
        title: "Vérifier les dates de début et de fin du projet",
      });
    }
  };

  useEffect(() => {
    if (formState.errors) {
      console.log(formState.errors);
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          return clearErrors(key);
        });
      }, 7000);
    }
  }, [formState.errors]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: projetId
          ? "Projet modifié avec succès!"
          : "Projet ajouté avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        if (isAdmin) {
          navigate(getRoutePath("ListeDesProjetsAdmin"));
        } else {
          navigate(getRoutePath(projetId ? "ListeProjets" : "CompainsListe"));
        }
      });
    }
    if (isError) {
      const err = error as any;
      Swal.fire({
        icon: "error",
        iconColor: COLORS.red,
        showConfirmButton: false,
        timer: 2000,
        title: "Une erreur est survenue lors de l'ajout",
      });
    }
  }, [isSuccess, isError, error]);

  return {
    register,
    errors: formState.errors,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    setValue,
    isLoading,
    isSuccess,
  };
}

export default UseLaunchCampagnForm;

export function validateImage() {
  return yup
    .mixed()
    .test(
      "file-type",
      `Format de fichier accepté: (${exts.join(", ")})`,
      (value: FileList | any) => {
        if (value) {
          const file: File = value instanceof FileList ? value[0] : value;
          if (!file) return false;
          const name = typeof file === "string" ? file : file.name;
          const parts = name.split(".");
          const ext = parts[parts.length - 1].toLowerCase();
          return exts.includes(ext);
        }
        return true;
      }
    );
}
