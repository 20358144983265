import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRoutePath } from "../../../components/routes/routes";
import { ApiUrl } from "../../constants";
import { useLocationState } from "../../utils";
import { PaginationResults, QueryUrl } from "../common.api";
import { prepareHeaders } from "../user/user.api";
import { Produit, ProduitFormData } from "./produit.type";

const tags = {
  listProduit: "listProduit",
  prod_commercant: "prod_commercant",
  products: "products",
  productBySlug: "productBySlug",
};
type ProduitByVisitorQuery = {
  page?: number;
  name__icontains?: string;
  commercant?: number[];
  category?: number[];
};

const ProduitApi = createApi({
  reducerPath: "listProduit",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: Object.values(tags),
  endpoints: (build) => ({
    productsByFilter: build.query<
      PaginationResults<Produit>,
      ProduitByVisitorQuery
    >({
      query: (query) => QueryUrl("/produitbyfilter/", query),
      providesTags: [tags.listProduit],
    }),
    productsByUser: build.query<Produit[], number>({
      query: (id) => `/produitbycommercant/${id}/`,
      providesTags: [tags.prod_commercant],
    }),
    getProducts: build.query<
      PaginationResults<Produit> | any,
      { page: number; wordproduit: string; wordcommercant: string }
    >({
      query: (query) => QueryUrl(`/produitbyfilteradmin/`, query),
      providesTags: ["products"],
    }),
    addProduit: build.mutation<Produit, ProduitFormData | any>({
      query: (data) => {
        if (data.id) {
          return {
            url: `/produit/${data.id}/`,
            method: "PUT",
            body: data.data,
          };
        }
        return {
          url: `/produit/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["listProduit", "prod_commercant", "products", "state"],
    }),

    deleteProduit: build.mutation<Produit, number>({
      query: (id) => ({
        url: `/produit/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["listProduit", "prod_commercant", "products"],
    }),
    editProduct: build.mutation<Produit, ProduitFormData | any>({
      query: ({ slug, data }) => ({
        url: `/produit_slug/${slug}/`,
        body: data,
        method: "PUT",
      }),
      invalidatesTags: [tags.listProduit, tags.prod_commercant, tags.products],
    }),
    produitBySlug: build.query<Produit, string>({
      query: (slug) => `/produitbyvisitor_slug/${slug}/`,
    }),
  }),
});

export const {
  useAddProduitMutation,
  useLazyProduitBySlugQuery,
  useProductsByFilterQuery,
  useProductsByUserQuery,
  useDeleteProduitMutation,
  useGetProductsQuery,
  useEditProductMutation,
} = ProduitApi;

export default ProduitApi;

export function useProduitFromLocation(): [
  Produit,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<Produit | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyProduitBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!itemState) {
      findBySlug(slug as any);
    }
  }, [itemState]);
  useEffect(() => {
    if (isError) {
      navigate(getRoutePath("home"));
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return [item as Produit, isLoading, slug as string, findBySlug as any];
}
