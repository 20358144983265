import GoBackBtn from '../../../shared/GoBackBtn'
import SideBarAdmin from '../Layouts/SideBarAdmin/SideBarAdmin'
import TopBar from '../Layouts/TopBar/TopBar'
import './ListeDonnateursAdmin.css'

function InformationsDonnateur() {
  return (
    <div className="parametre-page">
        <TopBar index={0} />
        <SideBarAdmin index={0} />
        <div className="parametre-component m-tableau-bord pt-3 flex-r">
            <div className="admin-page-container">
                <div className="p4 p-xxl-3">
                    <GoBackBtn className='mad-donnateur-infos-perso-boutton-back' label="Retour" />
                    <div className="row">
                        <div className="col-md-12 maajjal-table-card px-3 py-4 ml-2 mr-3">
                            <div className="mad-donnateur-infos-perso-title mb-4">Informations personnelles</div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-nom-label">Nom</div>
                                <div className="mad-donnateur-infos-perso-nom">Ba</div>
                            </div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-nom-label">Prenom</div>
                                <div className="mad-donnateur-infos-perso-nom">Abdou</div>
                            </div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-nom-label">Téléphone</div>
                                <div className="mad-donnateur-infos-perso-nom">77 000 00 00</div>
                            </div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-nom-label">Email</div>
                                <div className="mad-donnateur-infos-perso-nom">diopabdou@gmail.com</div>
                            </div>
                        </div>
                        <div className="col-md-12 maajjal-table-card px-3 py-4 ml-2 mr-3 mt-3">
                            <div className="mad-donnateur-infos-perso-title mb-4">Historique des contributions</div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-date-header">Date</div>
                                <div className="mad-donnateur-infos-perso-projet-header">Projet</div>
                                <div className="mad-donnateur-infos-perso-montant-header">Montant</div>
                            </div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-date">01/02/2022</div>
                                <div className="mad-donnateur-infos-perso-projet">Point café</div>
                                <div className="mad-donnateur-infos-perso-montant">Janvier</div>
                            </div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-date">01/02/2022</div>
                                <div className="mad-donnateur-infos-perso-projet">Point café</div>
                                <div className="mad-donnateur-infos-perso-montant">Janvier</div>
                            </div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-date">01/02/2022</div>
                                <div className="mad-donnateur-infos-perso-projet">Point café</div>
                                <div className="mad-donnateur-infos-perso-montant">Janvier</div>
                            </div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-date">01/02/2022</div>
                                <div className="mad-donnateur-infos-perso-projet">Point café</div>
                                <div className="mad-donnateur-infos-perso-montant">Janvier</div>
                            </div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-date">01/02/2022</div>
                                <div className="mad-donnateur-infos-perso-projet">Point café</div>
                                <div className="mad-donnateur-infos-perso-montant">Janvier</div>
                            </div>
                            <div className="mad-donnateur-infos-perso-nom-container mb-3">
                                <div className="mad-donnateur-infos-perso-date">01/02/2022</div>
                                <div className="mad-donnateur-infos-perso-projet">Point café</div>
                                <div className="mad-donnateur-infos-perso-montant">Janvier</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default InformationsDonnateur