import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ApiUrl } from '../../constants'
import { prepareHeaders } from '../user/user.api'
import { Config } from './config.type'

const ConfigApi = createApi({
  reducerPath: 'config',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ['config'],
  endpoints: (build) => ({
    addConfig: build.mutation<Config, Config | any>({
      query: ({ id, slug, data }) => ({
        url: slug ? `/config/${slug}/` : `/config/`,
        method: slug ? 'PUT' : 'POST',
        body: data,
      }),
      invalidatesTags: ['config'],
    }),
    getConfig: build.query<Config | null, void>({
      query: () => `/configbyvisitor/`,
      providesTags: ['config'],
    }),
  }),
})

export default ConfigApi
