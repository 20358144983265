import { useEffect } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import Swal from "sweetalert2";
import TeamApi from "../../../../utils/api/team/team.api";
import { Team } from "../../../../utils/api/team/team.type";
import { COLORS } from "../../../../utils/constants";
import { getAvatar } from "../../../../utils/utils";
import "./Faq.css";
import TeamForm from "./TeamForm";

const TeamList = () => {
  const { data = [] } = TeamApi.useGetTeamQuery();

  const [deleteTeam, { isError, error, isSuccess }] =
    TeamApi.useDeleteTeamMutation();
  const onDeleteFaq = (slug: string) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cet membre",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn  mx-2",
      },
      preConfirm: () => {
        return deleteTeam(slug);
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Membre supprimée avec succès",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);

  return (
    <div className="maajjal-card maajjal-admin-card px-2 py-2 p-xxl-3">
      <div className="faq-add-btn-container flex-cc">
        <button
          className="faq-add-btn"
          data-bs-toggle="modal"
          data-bs-target="#AddTeam"
        >
          <BsPlusCircle style={{ fontSize: 20 }} />
          Ajouter
        </button>
      </div>
      <div className="faq-container mt-5">
        {data.map((item) => (
          <div key={item.id}>
            <div className="faq-card-item px-3 py-3 mb-3">
              <div className="info-title-container-container flex-fill">
                <div className="avatar-messages-container pb-3">
                  <img
                    src={getAvatar(item.avatar)}
                    alt="avatar"
                    style={{ width: 80, height: 80, borderRadius: 40 }}
                    className="custom-avatar-message"
                  />
                </div>
                <h3 className="faq-card-title">{item.fullname}</h3>
                <p className="faq-publish-date">{item.fonction}</p>
              </div>
              <div className="faq-info-action-container">
                <div className="action-btn-container d-flex gap-2">
                  <ResponseButton item={item} />
                  <button
                    className="faq-action-btn border-red"
                    type="button"
                    onClick={() => onDeleteFaq(item.slug)}
                  >
                    <FiTrash2 />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <TeamForm modalId="AddTeam" />
    </div>
  );
};

export default TeamList;

export function ResponseButton({ item }: { item: Team }) {
  const modalId = `UpdateTeam${item.id}`;
  return (
    <>
      <button
        className="faq-action-btn border-blue-dark"
        {...{
          "data-bs-toggle": "modal",
          "data-bs-target": `#${modalId}`,
        }}
      >
        <MdModeEdit />
      </button>
      <TeamForm modalId={modalId} item={item} />
    </>
  );
}
