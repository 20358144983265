import { FiSearch } from "react-icons/fi";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import FilterMarketItem from "./Common/FilterMarketItem";
import "./MarketPlace.css";

import React from "react";
import { NavLink } from "react-router-dom";
import { useCategory_productQuery } from "../../../../utils/api/categorie/categorie.api";
import { useProductsByFilterQuery } from "../../../../utils/api/produit/produit.api";
import { useSellersQuery } from "../../../../utils/api/user/user.api";
import { EmptyMessage } from "../../../../utils/emptyMessage";
import { getRoutePath } from "../../../routes/routes";
import Pagination from "../../../shared/Pagination";
import { SEO } from "../../../shared/SEO";
import { SearchInput } from "../../compains/CompainsListe/CompainsListe";
import ProductsMarketList from "./Common/ProductsMarketList";
const MarketPlace = () => {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [searchSeller, setSearchSeller] = React.useState("");
  const [selectedSellers, setSelectedSellers] = React.useState<number[]>([]);
  const { data: sellers = [] } = useSellersQuery();
  const { data: categories = [], isLoading } = useCategory_productQuery();
  const [selectedCats, setSelectedCats] = React.useState<number[]>([]);
  const { data = { results: [] } } = useProductsByFilterQuery({
    category: selectedCats,
    commercant: selectedSellers,
    name__icontains: search,
    page: page,
  });
  React.useEffect(() => {
    setSelectedSellers([]);
  }, [searchSeller]);

  const filterSellers = React.useCallback(() => {
    const s = searchSeller.trim().toLowerCase();
    let filtred = sellers;
    if (s) {
      filtred = sellers.filter((seller) =>
        `${seller.first_name} ${seller.last_name}`.toLowerCase().includes(s)
      );
    } else {
      filtred = [];
    }
    return filtred.slice(0, 10);
  }, [searchSeller, sellers]);
  React.useEffect(() => {
    setPage(1);
  }, [search, selectedSellers, selectedCats]);
  const filtred = filterSellers();
  return (
    <>
      <SEO title="Marketplace" />
      <div className="marketplace-page">
        <FrontHeader />
        <div className="custom-container margin-container  px-md-4 px-3">
          <h1 className="market-title">
            Profitez de nos réductions avec vos points
          </h1>
          <div className="mb-4 market-place-component d-flex flex-column gap-3 flex-md-row justify-content-md-between">
            <div className="col-md-6">
              <SearchInput value={search} onChange={setSearch} />
            </div>
            <NavLink
              to={getRoutePath("AjoutOffre")}
              className="btn-shop btn-shop-next fw-500 market-btn-link"
            >
              Créer une offre
            </NavLink>
          </div>
          <div className="row px-3">
            <div className="market-place-filter-container maajjal-card py-2 px-3 col-xl-2 col-md-3">
              <h6 className="filter-market-title">Catégories</h6>
              <ul className="fiter-market-item-container">
                {categories.map((category) => (
                  <FilterMarketItem
                    checked={selectedCats.includes(category.id)}
                    label={category.name}
                    key={category.id}
                    onCheck={(checked) => {
                      if (checked) {
                        setSelectedCats((old) => [...old, category.id]);
                      } else {
                        setSelectedCats((old) =>
                          old.filter((f) => f !== category.id)
                        );
                      }
                    }}
                  />
                ))}
              </ul>
              <div className="sellers-filter-container mt-4">
                <h6 className="filter-market-title m-0">Vendeur</h6>
                <form>
                  <div className="form-group position-relative">
                    <input
                      type="text"
                      value={searchSeller}
                      onChange={(e) => setSearchSeller(e.target.value)}
                      className="market-form-search-seller-input mt-2 mb-3 p-0"
                      placeholder="Chercher un vendeur"
                    />
                    <FiSearch
                      style={{
                        position: "absolute",
                        top: "30%",
                        right: 18,
                      }}
                    />
                  </div>
                </form>
                <ul className="fiter-market-item-container">
                  {filtred.length === 0 &&
                    !!search &&
                    EmptyMessage("Pas de résultat")}
                  {filtred.map((seller) => (
                    <FilterMarketItem
                      label={`${seller.first_name} ${seller.last_name}</br>${
                        seller.phone && seller.phone !== "null"
                          ? seller.phone
                          : ""
                      }`}
                      key={seller.id}
                      checked={selectedSellers.includes(seller.id)}
                      onCheck={(checked) => {
                        if (checked) {
                          setSelectedSellers((old) => [...old, seller.id]);
                        } else {
                          setSelectedSellers((old) =>
                            old.filter((f) => f !== seller.id)
                          );
                        }
                      }}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xl-10 col-md-9 p-0 mt-4 mt-md-0 px-md-3">
              <ProductsMarketList
                produits={data.results}
                isLoading={isLoading}
              />
              <Pagination
                onPageChange={setPage}
                page={page}
                total={data.count}
              />
            </div>
          </div>
        </div>
        <FrontFooter />
      </div>
    </>
  );
};

export default MarketPlace;
