import React from "react";

export const GoogleIcon = () => {
	return (
		<svg
			width="17"
			height="18"
			viewBox="0 0 17 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.5665 7.33397C16.6407 7.33397 16.7009 7.39884 16.7009 7.47885V8.99995C16.7009 9.56926 16.6519 10.1259 16.5578 10.6659C15.8298 14.8604 12.4012 18.0302 8.29158 17.9996C3.68026 17.9654 -0.0101886 13.9497 2.11331e-05 8.97966C0.0101656 4.01855 3.74498 0 8.35046 0C10.6068 0 12.654 0.964752 14.1571 2.53187C14.2114 2.58843 14.2128 2.68167 14.1594 2.73918L12.1627 4.89112C12.1113 4.94653 12.0283 4.94797 11.9756 4.89393C11.0322 3.92612 9.75585 3.33195 8.35046 3.33195C5.44831 3.33195 3.11132 5.83281 3.09162 8.96061C3.07182 12.1089 5.43396 14.6679 8.35046 14.6679C10.7168 14.6679 12.7182 12.9831 13.3782 10.6659H8.48488C8.41064 10.6659 8.35046 10.6011 8.35046 10.521V7.47882C8.35046 7.3988 8.41064 7.33394 8.48488 7.33394H16.5665V7.33397Z"
				fill="#2196F3"
			/>
			<path
				d="M16.5644 7.33447H15.5563C15.6305 7.33447 15.6907 7.39934 15.6907 7.47935V9.00045C15.6907 9.56976 15.6416 10.1264 15.5475 10.6664C14.8531 14.668 11.7006 17.7369 7.84375 17.9842C7.99132 17.9935 8.13987 17.999 8.28946 18.0002C12.3991 18.0307 15.8277 14.8608 16.5556 10.6664C16.6497 10.1264 16.6988 9.56976 16.6988 9.00045V7.47932C16.6988 7.39934 16.6386 7.33447 16.5644 7.33447V7.33447Z"
				fill="#1E88E5"
			/>
			<path
				d="M3.57268 6.62628L1.03662 4.65136C2.4594 1.87786 5.20109 0 8.34941 0C10.6058 0 12.653 0.964752 14.1561 2.53187C14.2103 2.58843 14.2117 2.68167 14.1583 2.73918L12.1617 4.89112C12.1104 4.94642 12.0273 4.94814 11.9748 4.89421C11.0314 3.92623 9.75493 3.33198 8.34941 3.33198C6.2314 3.33198 4.40571 4.68141 3.57268 6.62628V6.62628Z"
				fill="#F44336"
			/>
			<path
				d="M2.84082 6.0562L3.57295 6.62633C4.3333 4.85116 5.9208 3.57267 7.80259 3.36297C7.81675 3.36132 7.83035 3.35931 7.84477 3.35784C7.67916 3.34089 7.51134 3.33203 7.34159 3.33203C5.42826 3.33203 3.76077 4.41916 2.84082 6.0562V6.0562Z"
				fill="#E53935"
			/>
			<path
				d="M13.1474 2.53187C13.2017 2.58843 13.2031 2.68167 13.1497 2.73922L11.5291 4.48588C11.6843 4.61314 11.833 4.74927 11.974 4.89397C12.0266 4.948 12.1097 4.94656 12.1611 4.89116L14.1578 2.73922C14.2112 2.68167 14.2097 2.58847 14.1555 2.53187C12.6524 0.964752 10.6052 0 8.34885 0C8.17953 0 8.01154 0.006082 7.84473 0.0168046C9.90103 0.148816 11.7571 1.08231 13.1474 2.53187V2.53187Z"
				fill="#E53935"
			/>
			<path
				d="M14.4764 15.1163C12.9514 16.8898 10.7712 17.9999 8.35078 17.9999C5.0815 17.9999 2.25109 15.975 0.880371 13.0251L3.4695 11.1128C4.24646 13.1964 6.13934 14.6679 8.35078 14.6679C9.73858 14.6679 11.0007 14.0884 11.9403 13.1417L14.4764 15.1163Z"
				fill="#4CAF50"
			/>
			<path
				d="M3.46807 11.1128L2.70459 11.6767C3.59134 13.4571 5.33512 14.6678 7.34122 14.6678C7.51087 14.6678 7.67857 14.6589 7.84411 14.642C5.85275 14.4374 4.1851 13.0358 3.46807 11.1128Z"
				fill="#43A047"
			/>
			<path
				d="M8.34898 18.0001C10.7694 18.0001 12.9496 16.89 14.4746 15.1165L13.8534 14.6328C12.4216 16.5534 10.2736 17.8275 7.84619 17.9837C8.01255 17.9944 8.18014 18.0001 8.34898 18.0001Z"
				fill="#43A047"
			/>
			<path
				d="M3.09153 9.00045C3.09153 9.74723 3.2256 10.4604 3.46923 11.1134L0.880061 13.0256C0.316894 11.8145 0 10.4473 0 9.00045C0 7.42321 0.376358 5.94082 1.03768 4.65186L3.57374 6.62678C3.26425 7.34857 3.09153 8.15298 3.09153 9.00045V9.00045Z"
				fill="#FFC107"
			/>
			<path
				d="M2.70729 11.6772L3.47077 11.1133C3.22714 10.4604 3.09308 9.74722 3.09308 9.00043C3.09308 8.15296 3.2658 7.34855 3.57528 6.62677L2.84315 6.05664C2.36773 6.90267 2.0918 7.89544 2.08509 8.96113C2.07889 9.94388 2.30484 10.8692 2.70729 11.6772V11.6772Z"
				fill="#FFB300"
			/>
		</svg>
	);
};

export const FacebookIcon = () => {
	return (
		<svg
			width="8"
			height="18"
			viewBox="0 0 8 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.22492 9.58679H1.75333V17.7097C1.75333 17.8701 1.85399 18 1.97823 18H4.56972C4.69396 18 4.79461 17.8701 4.79461 17.7097V9.62508H6.55168C6.66592 9.62508 6.76205 9.51441 6.7751 9.36791L7.04196 6.37748C7.04928 6.29522 7.0291 6.21281 6.98645 6.15108C6.94378 6.08931 6.8827 6.05394 6.81859 6.05394H4.79472V4.17937C4.79472 3.61431 5.0304 3.32775 5.49532 3.32775C5.56158 3.32775 6.81859 3.32775 6.81859 3.32775C6.94283 3.32775 7.04348 3.19774 7.04348 3.03743V0.292465C7.04348 0.132082 6.94283 0.00214453 6.81859 0.00214453H4.99491C4.98206 0.00133594 4.95349 0 4.91139 0C4.59496 0 3.49509 0.0801913 2.62627 1.11199C1.66363 2.25541 1.79745 3.62443 1.82942 3.86181V6.05387H0.224893C0.100654 6.05387 0 6.18381 0 6.34419V9.29644C2.72333e-05 9.45679 0.100681 9.58679 0.22492 9.58679Z"
				fill="#385C8E"
			/>
		</svg>
	);
};

export const TwitterIcon = () => {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_93_3476)">
				<path
					d="M24.46 2.92233C23.5441 3.41 22.5726 3.7301 21.5779 3.872C22.614 3.12559 23.4096 1.94362 23.7842 0.53531C22.7992 1.23784 21.7216 1.73281 20.5978 1.99885C19.6824 0.826755 18.3784 0.0942383 16.935 0.0942383C14.1638 0.0942383 11.917 2.79464 11.917 6.12524C11.917 6.59801 11.9614 7.05826 12.0469 7.49979C7.87652 7.24819 4.17909 4.84716 1.7041 1.19824C1.27226 2.08899 1.0248 3.12513 1.0248 4.23028C1.0248 6.32275 1.91076 8.16869 3.25712 9.25029C2.46026 9.2203 1.68093 8.96162 0.984199 8.49584C0.983913 8.52111 0.983913 8.54637 0.983913 8.57175C0.983913 11.4939 2.71359 13.9316 5.00905 14.4856C4.27013 14.7272 3.49504 14.7625 2.74301 14.589C3.38151 16.9851 5.23472 18.7287 7.43044 18.7775C5.7131 20.395 3.54938 21.3593 1.1985 21.3593C0.793404 21.3593 0.394043 21.3307 0.00146484 21.275C2.22212 22.9862 4.85972 23.9847 7.69347 23.9847C16.9234 23.9847 21.9705 14.7945 21.9705 6.82469C21.9705 6.5631 21.9658 6.303 21.956 6.0444C22.9384 5.19083 23.7863 4.1336 24.46 2.92233Z"
					fill="#55ACEE"
				/>
			</g>
			<defs>
				<clipPath id="clip0_93_3476">
					<rect width="24.4609" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const InstagramIcon = () => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="28"
				height="28"
				rx="5.8"
				fill="url(#paint0_radial_708_9205)"
			/>
			<rect
				width="28"
				height="28"
				rx="5.8"
				fill="url(#paint1_radial_708_9205)"
			/>
			<circle
				cx="13.6996"
				cy="14.7001"
				r="4.3"
				stroke="white"
				strokeWidth="1.6"
			/>
			<circle cx="20.1003" cy="8.0998" r="1.3" fill="white" />
			<rect
				x="4.89971"
				y="5.0999"
				width="18.2"
				height="18.6"
				rx="3.1"
				stroke="white"
				strokeWidth="1.4"
			/>
			<defs>
				<radialGradient
					id="paint0_radial_708_9205"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(23.66 31.78) rotate(-105.945) scale(36.6918 54.5748)"
				>
					<stop offset="0.197635" stopColor="#F50B5E" />
					<stop offset="0.52034" stopColor="#DF0897" />
					<stop offset="0.768785" stopColor="#962FBF" />
					<stop offset="0.933441" stopColor="#4F5BD5" />
				</radialGradient>
				<radialGradient
					id="paint1_radial_708_9205"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(9.38 28) rotate(-54.9721) scale(18.2935 20.0095)"
				>
					<stop offset="0.09375" stopColor="#FEDA75" />
					<stop offset="0.475046" stopColor="#FA7E1E" />
					<stop
						offset="1"
						stopColor="#FA7E1E"
						stopOpacity="0"
					/>
				</radialGradient>
			</defs>
		</svg>
	);
};
