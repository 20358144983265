import React, { useEffect, useMemo } from 'react'
import { useForgetPasswordMutation } from '../../../../utils/api/auth/auth.api';
import Swal from "sweetalert2";
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { COLORS } from '../../../../utils/constants';
import { AuthFormData } from '../../../../utils/api/auth/auth.type';

function UseforgetPasswordForm() {
    const [login, { error, isSuccess, isLoading, originalArgs, isError }] =
    useForgetPasswordMutation();
    const navigate = useNavigate();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required("L'email est requis").label("Email"),
      }),
    []
  );
  const { register, handleSubmit, formState, setError, clearErrors } =
    useForm<AuthFormData>({
      resolver: yupResolver(validationSchema),
    });
  useEffect(() => {
    if (error) {
      const err = error as any;
      // console.log(err)
      const { message = "Une erreur inconnue s'est produite" } = err.data || {};
      setError("email", { message: message });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: COLORS.green,
        confirmButtonColor: COLORS.green,
        title: "Un mail vous a été envoyé, merci de vérifier",
      });
      navigate("/reset-password", {
        state: { email: originalArgs?.email },
      });
    }

   

    // if (isError) {
    //   const err = error as any
    //   console.log(err)
    // if (
    //   err?.data?.error?.email?.length &&
    //   err?.data?.error?.email[0] === "Email must be a valid email"
    // ) {
    //   setError("email", {
    //     message: "Cet email est",
    //   });
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: `${
    //       err?.data?.message?.toString()
    //         ? err?.data?.message?.toString()
    //         : `Une erreur de status ${err?.status} est survenue!`
    //     }`,
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }
  // }

  }, [isSuccess, originalArgs]);

  return {
    register,
    errors: formState.errors,
    onSubmit: handleSubmit(login),
    clearErrors,
    isLoading,
    setError
  };
}

export default UseforgetPasswordForm