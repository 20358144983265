import React from 'react'
import { useAppSelector } from '../../../../redux/store'
import { useProduitFromLocation } from '../../../../utils/api/produit/produit.api'
import { formatAmout, getImage } from '../../../../utils/utils'
import GoBackBtn from '../../../shared/GoBackBtn'
import SideBarAdmin from '../Layouts/SideBarAdmin/SideBarAdmin'
import TopBar from '../Layouts/TopBar/TopBar'

function DetailsProduitsAdmin() {
    const [item] = useProduitFromLocation();
    if (!item) return null;
    const commercant = useAppSelector((s) => {
      const user = s.user.user;
      if (item?.commercant?.id) return item.commercant?.id;
      if (user?.id === item.commercant) return user;
  
      return null;
    });
    const DATA = [
      { value: item.name, label: "Nom produit" },
      { value: formatAmout(item.prix), label: "Prix" },
      {
        value: <span dangerouslySetInnerHTML={{ __html: item.description }} />,
        label: "Description",
      },
      { value: `${item.discount}%`, label: "Discount" },
      { value: item.point, label: "Point" },
    ];
    const DATA2 = [
      {
        value: `${item.commercant?.first_name} ${item.commercant?.last_name}`,
        label: "Prénom et Nom",
      },
      { value: item.commercant?.adress, label: "Adresse" },
      { value: item.commercant?.email, label: "Adresse mail" },
      { value: item.commercant?.phone, label: "Numéro de téléphone" },
    ];
  return (
    <div className="parametre-page">
        <TopBar index={3} />
        <SideBarAdmin index={3} />
        <div className="parametre-component m-tableau-bord pt-3 flex-r">
            <div className="admin-page-container">
                <div className="p4 p-xxl-3">
                    <GoBackBtn
                        className="mad-donnateur-infos-perso-boutton-back"
                        label="Retour"
                    />
                </div>
                <div className="flex-c mt-3">
                  <img
                    src={getImage(item.avatar)}
                    alt="produit"
                    style={{ width: "100%", height: "auto" }}
                    className="img-info-produit"
                  />
                </div>
                {DATA.map(({ label, value }) => (
                  <div className="infos-ligne row">
                    <h5 className="col-6 px-0 fw-600">{label}</h5>
                    <h6 className="col-6 px-0 fw-400">{value}</h6>
                  </div>
                ))}
                <div className="mt-4 mb-4 border-b  row">
                  <h3 className="detail-offre-title p-0">
                    Information commerçant
                  </h3>
                </div>
                {DATA2.map(({ label, value }) => (
                  <div className="infos-ligne row">
                    <h5 className="col-6 px-0 fw-600">{label}</h5>
                    <h6 className="col-6 px-0 fw-400">{value}</h6>
                  </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default DetailsProduitsAdmin