import { NavLink } from 'react-router-dom'
import Export from '../../assets/icons/export.png'
import Frame from '../../assets/icons/Frame.png'
import Group from '../../assets/icons/Group.png'
import FrontFooter from '../../shared/FrontFooter/FrontFooter'
import FrontHeader from '../../shared/FrontHeader/FrontHeader'
import Banniere from './Banniere'
import './HomePage.css'
// import Carousel from "react-multi-carousel";

import ReactPlayer from 'react-player'
import { useAvisByVisitorQuery } from '../../../utils/api/avis/avis.api'
import ConfigApi from '../../../utils/api/config/config.api'
import { useProjetsOnlineQuery } from '../../../utils/api/launchCampagn/projet.api'
import { useProductsByFilterQuery } from '../../../utils/api/produit/produit.api'
import Imgbg from '../../assets/appImage/img-video.png'
import PlayImg from '../../assets/icons/Play.png'
import { getRoutePath } from '../../routes/routes'
import { SEO } from '../../shared/SEO'
import ProduitMarketplaceItem from './ProduitMarketplaceItem'
import ProjectsList from './ProjectsList'
import Testimonials from './Testimonials'
import { createMarkup, videoIdFromUrl } from '../../../utils/utils'
// import VideoPlay from '../../assets/appImage/Volkeno.mp4'

const HomePage = () => {
  const { data = { results: [] } } = useProjetsOnlineQuery({
    statut: 'online',
  })
  const {
    data: products = { results: [] },
    isLoading,
  } = useProductsByFilterQuery({})
  const { data: avis = { results: [] } } = useAvisByVisitorQuery({})
  const projects = data.results.slice(0, 6)
  const { data: config } = ConfigApi.useGetConfigQuery()
  let videoId = config?.how_it_work && videoIdFromUrl(config?.how_it_work)
  const WUY_MAJJAL_DATA = [
    {
      title: config?.title_why_one,
      image: Frame,
      description: createMarkup(config?.description_why_one),
      index: 1,
    },
    {
      title: config?.title_why_two,
      image: Group,
      description: createMarkup(config?.description_why_two),
      index: 2,
    },
    {
      title: config?.title_why_three,
      image: Export,
      description: createMarkup(config?.description_why_three),
      index: 3,
    },
  ]
  return (
    <>
      <SEO />
      <div className="home-page-component">
        <div className="mb-5">
          <FrontHeader />
          <div className="marge-after-header">
            <Banniere config={config} />
            <section className="why-maajjal-section">
              <div className="container-maajjal">
                <div className="pb-4">
                  <h2 className="titre-section-why-maajjal">
                    Pourquoi maajjal?
                  </h2>
                </div>
                <div className="row mt-5">
                  {WUY_MAJJAL_DATA.map((item) => (
                    <WhyMajjalItem {...item} key={item.index} />
                  ))}
                </div>
              </div>
            </section>
            <section className="projet-en-cours-campagne-section">
              <div className="container-maajjal">
                <div className="texte-display-space-between">
                  <div>
                    <h2 className="titre-section-why-maajjal">
                      Les projets en cours de campagne
                    </h2>
                  </div>
                  <div>
                    <NavLink
                      to={getRoutePath('CompainsListe')}
                      className="text-theme"
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      Voir tous les {data.count || 0} projets
                    </NavLink>
                  </div>
                </div>
                <div className="mt-5 home-projects-list-container">
                  <ProjectsList projects={projects} isLoading={isLoading} />
                </div>
              </div>
            </section>
            <section className="projet-en-cours-campagne-section">
              <div className="container-maajjal">
                <div className="texte-display-space-between mb-5">
                  <div>
                    <h2 className="titre-section-why-maajjal">
                      Faites aussi vos achats et ventes
                    </h2>
                  </div>
                  <div>
                    <NavLink
                      to={getRoutePath('MarketPlace')}
                      className="text-theme"
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      Voir tous les produits
                    </NavLink>
                  </div>
                </div>
                <div>
                  <ProduitMarketplaceItem
                    products={products.results}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </section>
            <section className="projet-en-cours-campagne-section">
              <div className="container-maajjal">
                <div>
                  <h2 className="titre-section-why-maajjal">
                    Comment ça marche ?
                  </h2>
                </div>
                {!!config?.how_it_work && (
                  <div className="mt-5">
                    <ReactPlayer
                      // url={config.how_it_work}
                      url={`https://youtube.com/embed/${videoId}?controls=0`}
                      width="100%"
                      height="500px"
                      // playing
                      playIcon={<img src={PlayImg} alt="video" />}
                      // light={Imgbg}
                      controls
                      className="maajjal-video-player"
                    />
                  </div>
                )}
              </div>
            </section>
            <section className="projet-en-cours-campagne-section">
              <div className="container-maajjal">
                <div>
                  <h2 className="titre-section-why-maajjal">
                    Nos utilisateurs s’expriment
                  </h2>
                </div>
                <div className="mt-5">
                  <Testimonials avis={avis.results} />
                </div>
              </div>
            </section>
          </div>
        </div>
        <FrontFooter />
      </div>
    </>
  )
}
export default HomePage

function WhyMajjalItem({ image, title, description, index }) {
  return (
    <div className="col-md-4 pb-3">
      <div className="about-maajjal-component">
        <div>
          <div className="bloc-entete-about">
            <div className="icon-container">
              <img
                src={image}
                alt="about-maajjal-icon"
                className="icon-about-maajjal"
              />
            </div>
            <div className="chiffre-about-container">
              <p className="chiffre-position-item-about">{index}</p>
            </div>
          </div>
          <div className="pt-3">
            <p className="titre-about-maajjal-position-item">{title}</p>
            <p
              className="texte-about-maajjal-position-item"
              dangerouslySetInnerHTML={description}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
