import { useModalActions } from "../../../../utils/utils";
import BtnCloseModal from "../../../shared/BtnCloseModal";
import BtnSubmit from "../../../shared/BtnSubmit";
import UseAjoutFaq from "../../admin/Faq/requestFaq/UseAjoutFaq";

const AjouterFaqModal = () => {
  const modalId = "ajouterFaqModal";
  const ma = useModalActions(modalId);
  const { register, onSubmit, errors, isLoading } = UseAjoutFaq(ma.hide);

  return (
    <div className="modal fade" id={modalId}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content px-4 py-3">
          <div className="flex-csb mb-4">
            <h1 className="admin-modal-title m-0">Question</h1>
            <BtnCloseModal />
          </div>
          <div className="reponse-form-container">
            {/* <h3 className="admin-modal-sous-title">Réponse</h3> */}
            <form onSubmit={onSubmit}>
              <div className="form-group mb-4">
                <label htmlFor="question" className="admin-form-label">
                  Question
                </label>
                <input
                  type="text"
                  id="question"
                  className="form-control admin-form-control"
                  placeholder="Ajouter une question"
                  {...register("question")}
                />
              </div>
              {/* <div className="form-group">
                <label htmlFor="question" className="admin-form-label">
                  Réponses
                </label>
                <textarea
                  id="reponse"
                  placeholder="Ajouter une réponse"
                  className="form-control form-control-lg form-admin-textarea"
                  {...register("reponse")}
                />
              </div> */}
              <div className="btn-container flex-r mt-5">
                {isLoading && (
                  <button disabled className="admin-submit-btn">
                    En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isLoading && <BtnSubmit label="Envoyer" />}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterFaqModal;
