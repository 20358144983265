import moment from 'moment'
import { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import Swal from 'sweetalert2'
import {
  useDeleteMontantConfigMutation,
  useGetMontantConfigQuery,
} from '../../../../utils/api/montantConfig/montantConfig.api'
import { useGetUsersAdminQuery } from '../../../../utils/api/userAdmin/userAdmin.api'
import { COLORS } from '../../../../utils/constants'
import { IconDelete, IconTabEdit } from '../../../shared/Icons'
import AddMontantModal from '../../Modals/MontantModal/AddMontantModal'

function MontantsTable() {
  const [
    deleteItem,
    { isSuccess, isError, error },
  ] = useDeleteMontantConfigMutation()
  const { data: results = [], isLoading } = useGetMontantConfigQuery()
  useEffect(() => {
    console.log(results)
  }, [results])

  const onDeleteMontant = (slug) => {
    Swal.fire({
      title: 'Êtes vous sûr de vouloir supprimer ce montant',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: 'swal-custom-btn mx-2',
        cancelButton: 'swal-custom-btn  mx-2',
      },
      preConfirm: () => {
        return deleteItem(slug)
      },
    }).then((result: any) => {
      console.log('res', result)
      if (result.value) {
        Swal.fire({
          icon: 'success',
          title: 'Montant supprimé avec succès',
          iconColor: COLORS.blue,
          showConfirmButton: false,
          timer: 1500,
        })
      }
    })
  }

  useEffect(() => {
    if (isError) {
      const err = error as any
      console.log(err)

      Swal.fire({
        icon: 'error',
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      })
    }
  }, [isError])

  const actionFormatter = (cell, row) => {
    return (
      <div>
        <button
          className="btn-edit bg-transparent border-none"
          data-bs-toggle="modal"
          data-bs-target={`#EditMontant${row?.slug}`}
        >
          <IconTabEdit />
        </button>
        <button
          className="btn-delete bg-transparent border-none"
          onClick={() => onDeleteMontant(row?.slug)}
        >
          <IconDelete />
        </button>
        <AddMontantModal item={row} modalId={`EditMontant${row?.slug}`} />
      </div>
    )
  }
  const columns = [
    {
      dataField: 'montant',
      text: 'Montant',
      formatter: (cell, row) => cell + 'F CFA',
      style: { textAlign: 'justify', verticalAlign: 'middle' },
      headerStyle: {
        textAlign: 'justify',
        fontWeight: 600,
        color: '#021849',
      },
    },
    {
      dataField: 'created_at',
      text: 'Date de création',
      formatter: (cell, row) => moment(cell).format('DD/MM/YYYY'),
      style: { textAlign: 'justify', verticalAlign: 'middle' },
      headerStyle: {
        textAlign: 'justify',
        fontWeight: 600,
        color: '#021849',
      },
    },
    {
      dataField: '',
      text: 'Actions',
      style: { textAlign: 'justify', verticalAlign: 'middle' },
      headerStyle: {
        textAlign: 'justify',
        fontWeight: 600,
        color: '#021849',
      },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ]

  return (
    <div className="liste-admins-table mt-5">
      {/* {isLoading && <ListeAdminsSkeleton />} */}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={results}
          columns={columns}
          noDataIndication="Aucune donnée disponible"
          bordered={false}
          condensed={false}
          responsive={true}
          striped={true}
          wrapperClasses="table-responsive"
          rowStyle={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '17px',
            color: 'rgba(0, 0, 0, 0.7)',
          }}
          headerClasses="table-header-style"
        />
      )}
      {/* <Pagination {...{ page, onPageChange: setPage, total: data.count }} /> */}
    </div>
  )
}

export default MontantsTable
