import React, { useEffect } from "react";
import "../common/Auth.css";
import { Link } from "react-router-dom";
import AuthPanel from "../common/AuthPanel";
import CustomAuthPanel from "../common/CustomAuthPanel";
import UseforgetPasswordForm from "../UseAuthForm/UseforgetPasswordForm";

function ForgetPassword() {
  const { register, errors, onSubmit, isLoading, setError } =
    UseforgetPasswordForm();

  useEffect(() => {
    if (errors) {
      errors?.email?.message?.toString() === "Email must be a valid email" &&
        setError("email", { message: "L'email doit être valide" });
    }
  }, [errors.email]);
  return (
    <div className="auth-component">
      <div className="row empty-header no-view-mobile"></div>
      <div className="auth-container py-5">
        <div className="auth-majjal-container">
          <div className="row">
            <CustomAuthPanel />
            <div className="col-md-8 auth-panel-right px-4 py-md-5 auth-majjal-panel-right">
              <h1 className="auth-title">Réinitialisez votre mot de passe</h1>
              <div className="auth-recuperation-pass-description mb-5">
                Veuillez entrer votre email Nous vous enverrons un code pour
                modifier le mot de passe
              </div>
              <div className="auth-form-container mt-4">
                <form onSubmit={onSubmit}>
                  <div className="form-group mb-4">
                    <input
                      type="email"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Adresse mail"
                      {...register("email")}
                      data-testid="emailId"
                    />
                    {errors.email && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {errors.email?.message?.toString()}
                      </div>
                    )}
                  </div>
                  <div className="btn-submit-form-container mb-3">
                    {!isLoading && (
                      <button
                        type="submit"
                        className="btn-theme-blue w-100"
                        data-testid="submitBtnId"
                      >
                        RÉINITIALISER
                      </button>
                    )}
                    {isLoading && (
                      <button
                        type="button"
                        disabled
                        className="btn-theme-blue w-100"
                      >
                        Chargement...
                      </button>
                    )}
                  </div>
                  <div className="text-center mt-4 mb-5">
                    <Link
                      to="/reset-password"
                      className="register-link"
                      // loading={isLoading}
                    >
                      J'ai déjà un code
                    </Link>
                  </div>
                  <div className="flex-r gap-2">
                    <p className="auth-link-message">
                      Vous n’avez pas de compte?
                    </p>
                    <Link
                      to="/s'inscrire"
                      className="register-link"
                      // loading={isLoading}
                    >
                      S’inscrire
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
