import React from 'react'
import Skeleton from 'react-loading-skeleton'

function ProductItemSkeleton() {
  return (
    <div>
        <div className="produit-marketplace-item-component mx-3">
        <div>
            <div className="card position-relative">
                <div className="product-img-container">
                    <Skeleton
                        height={120}
                        circle={false}
                    />
                </div>
            <div className="card-body">
                <div className="entete-produit-item">
                <h5 className="card-title titre-produit">
                    <Skeleton
                        height={10}
                        width={100}
                        circle={false}
                    />
                </h5>
                <p className="texte-reduction">
                    <Skeleton
                        height={10}
                        width={100}
                        circle={false}
                    />
                </p>
                </div>
                <div className='w-100'>
                    <Skeleton
                        height={30}
                        circle={false}
                        borderRadius='0.35rem'
                    />
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default ProductItemSkeleton