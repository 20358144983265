import { PhoneNumberUtil } from "google-libphonenumber";
import PhoneInput2 from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";
export type PhoneInputProps = {
  error?: string;
  [key: string]: any;
  onChnage: (value: string) => any;
  placeholder: string;
  value: string;
};

const phoneUtil = PhoneNumberUtil.getInstance();

export function validatePhone(yup: typeof Yup) {
  return yup
    .string()
    .test("passwords-match", "Numéro téléphone invalide", validateNumber)
    .label("Téléphone");
}

function validateNumber(phone?: string) {
  if (!phone) return false;
  try {
    const number = phoneUtil.parse(phone, "SN");
    if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
      return true;
    }
  } catch (ex) {
    console.log("error phone", ex);
  }
  return false;
}

export default function PhoneInput(props: PhoneInputProps) {
  const { error, onChnage, placeholder, value, ...rest } = props;
  return (
    <div className="form-group mb-3 warning-asterix-content">
      <span className="warning-asterix">*</span>
      <PhoneInput2
        masks={{ sn: ".. ... .. .." }}
        inputClass="majjal-form-control form-control form-control-lg"
        country={"sn"}
        inputProps={{
          ...rest,
        }}
        prefix="+"
        value={value}
        copyNumbersOnly={false}
        enableAreaCodes={true}
        placeholder={placeholder}
        onChange={(v) => onChnage(v.includes("+") ? v : "+" + v)}
        countryCodeEditable={true}
        inputStyle={{ width: "100%", height: 48 }}
      />
      {error && (
        <div className="alert alert-danger sia-alert-danger closer mt-2">
          {error}
        </div>
      )}
    </div>
  );
}
