import React from "react";
import Skeleton from "react-loading-skeleton";

function ListeAdminsSkeleton() {
	return (
		<div className="col-md-12 as-col-table-container">
			<table className="table table-striped">
				<thead>
					<tr>
						<th
							scope="col"
							style={{ color: "#021849" }}
						>
							N°
						</th>
						<th
							scope="col"
							style={{ color: "#021849" }}
						>
							Prénom & Nom
						</th>
						<th
							scope="col"
							style={{ color: "#021849" }}
						>
							Téléphone
						</th>
						<th
							scope="col"
							style={{ color: "#021849" }}
						>
							Adresse Mail
						</th>
						<th
							scope="col"
							style={{ color: "#021849" }}
						>
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
					<tr>
						<th scope="row">
							<Skeleton />
						</th>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
						<td>
							<Skeleton />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default ListeAdminsSkeleton;
