import React from 'react'
import Skeleton from 'react-loading-skeleton'

function DashboardSkeleton() {
  return (
    <div className="col-md-12 as-col-table-container">
    <table className="table table-striped">
        <thead>
            <tr>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>N°</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Prénom & Nom</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Téléphone</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Adresse mail</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Montant</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
        </tbody>
    </table>
</div>
  )
}

export default DashboardSkeleton