import React, { useEffect, useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useCategory_productQuery } from "../../../../utils/api/categorie/categorie.api";
import { useProduitFromLocation } from "../../../../utils/api/produit/produit.api";
import { useSellersQuery } from "../../../../utils/api/user/user.api";
import { getImage } from "../../../../utils/utils";
import GoBackBtn from "../../../shared/GoBackBtn";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import { HtmlInput } from "./AjoutProduitAdmin";
import UseEditProductForm from "./requestProduct/UseEditProductForm";

function ModifProduitAdmin() {
  const { register, setValue, errors, onSubmit, setError, isLoading } =
    UseEditProductForm();
  const [item] = useProduitFromLocation();
  const [description, setDescription] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [price, setPrice] = useState<number>();
  const [point, setPoint] = useState<number>();
  const [discount, setDiscount] = useState<number>();
  const [category, setCategory] = useState<any>({});
  const [commerce, setCommerce] = useState<any>({});
  const [file, setFile] = useState<File | any>();
  const [optionsCategorie, setOptionsCategorie] = React.useState<any>([]);
  const [optionsCommerce, setOptionsCommerce] = React.useState<any>([]);
  const { data: categories, isSuccess } = useCategory_productQuery();
  const { data: commerces } = useSellersQuery();
  const [newImage, setNewImage] = useState<any>(null);
  let navigate = useNavigate();

  const iconStyle = {
    fontSize: "18",
    color: "#5a5a5a",
    opacity: 0.3,
  };

  React.useEffect(() => {
    if (isSuccess) {
      setOptionsCategorie(
        categories?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        })
      );
    }
  }, [categories, isSuccess]);

  React.useEffect(() => {
    if (isSuccess) {
      setOptionsCommerce(
        commerces?.map((item) => {
          return {
            label: item?.first_name + " " + item?.last_name,
            value: item?.id,
          };
        })
      );
    }
  }, [commerces, isSuccess]);

  useEffect(() => {
    if (item?.id) {
      let data: any = item;
      setCategory({
        label: item?.category?.name,
        value: item?.category?.id,
      });
      setCommerce({
        label: item?.commercant?.first_name + " " + item?.commercant?.last_name,
        value: item?.commercant?.id,
      });
      setFile(item?.avatar);
      setDescription(item?.description);
      setName(item?.name);
      setPoint(item?.point);
      setPrice(item?.prix);
      setDiscount(item?.discount);
      for (let key of Object.keys(data)) {
        const val = data[key];
        if (key !== "likes" && key !== "avatar" && key !== "background_image") {
          if (
            (key === "commercant" && val?.id) ||
            (key === "category" && val?.id)
          ) {
            setValue(key, val?.id);
          } else {
            setValue(key, val);
          }
        }
      }
    }
  }, [item]);

  console.log("description", description);

  const handleChange = (e) => {
    setValue("category", e.target.value);

    let categoryItem = optionsCategorie?.find(
      (item) => parseInt(item?.value) === parseInt(e.target.value)
    );
    setCategory(categoryItem);
  };
  const handleChangeCommerce = (e) => {
    setValue("commercant", e.target.value);
    let commerceItem = optionsCommerce?.find(
      (item) => parseInt(item?.value) === parseInt(e.target.value)
    );

    setCommerce(commerceItem);
  };

  return (
    <div className="parametre-page">
      <TopBar index={3} />
      <SideBarAdmin index={3} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="p4 p-xxl-3">
            <GoBackBtn
              className="mad-donnateur-infos-perso-boutton-back"
              label="Retour"
            />

            <div className="row maajjal-table-card  px-3 py-4 ml-2 mr-3">
              <div className="col-xl-8 offset-xl-2 add-update-form-container">
                <form className="add-update-form" onSubmit={onSubmit}>
                  <div className="row add-update-form-row">
                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="product-name"
                          className="majjal-label mb-2"
                        >
                          Nom du produit <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control-majjal form-control form-control-lg"
                          id="product-name"
                          {...register("name")}
                          placeholder="Nom du produit"
                        />
                        {errors?.name && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {errors?.name?.message?.toString()?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="desc-product"
                          className="majjal-label mb-2"
                        >
                          Description produit
                          <span className="text-danger">*</span>
                        </label>
                        <HtmlInput
                          data={item?.description || ""}
                          name="description"
                          onChange={(data) => {
                            setValue("description", data);
                            setDescription(data);
                          }}
                        />
                        {errors?.description && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {errors?.description?.message
                              ?.toString()
                              ?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="commercant"
                          className="majjal-label mb-2"
                        >
                          Commerçant<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <select
                            id="commercant"
                            className="form-control-majjal form-select-majjal"
                            value={commerce?.value || ""}
                            onChange={(e) => handleChangeCommerce(e)}
                            // {...register('commercant')}
                          >
                            <option value="">Commerçant</option>
                            {optionsCommerce
                              ?.sort(function (a, b) {
                                return a?.label?.localeCompare(b?.label);
                              })
                              .map((optionsCommerce) => (
                                <option
                                  value={optionsCommerce.value}
                                  key={optionsCommerce.value}
                                >
                                  {optionsCommerce.label}
                                </option>
                              ))}
                          </select>
                        </div>
                        {errors?.commercant && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {errors?.commercant?.message
                              ?.toString()
                              ?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label htmlFor="category" className="majjal-label mb-2">
                          Categorie<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <select
                            id="category"
                            className="form-control-majjal form-select-majjal"
                            value={category?.value || ""}
                            onChange={(e) => handleChange(e)}
                            // {...register("category")}
                          >
                            <option value="">Catégorie</option>
                            {optionsCategorie
                              ?.sort(function (a, b) {
                                return a?.label?.localeCompare(b?.label);
                              })
                              .map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        </div>
                        {errors?.category && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {errors?.category?.message?.toString()?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form-input-col">
                      <label
                        htmlFor="photo-identite-recto"
                        className=" ajt-produit-img-label"
                      >
                        Image produit <span className="text-danger">*</span>
                      </label>
                      <label
                        htmlFor="photo-identite-recto"
                        className="auth-identite-file flex-sb"
                      >
                        <span>Photo</span>
                        <GrAttachment
                          className="auth-file-input-icon"
                          style={iconStyle}
                        />
                      </label>
                      <input
                        type="file"
                        className="op-0 h-0 imgInp"
                        placeholder="Image produit"
                        id="photo-identite-recto"
                        // onChange={handleChange}
                        onChange={(e) => {
                          if (e.target.files) {
                            setValue("avatar", e.target.files[0]);
                            setNewImage(e.target.files[0]);
                          }
                        }}
                        accept="image/*"
                        name="recto_cni"
                      />
                      {errors?.avatar && (
                        <div className="alert alert-danger sia-alert-danger closer mt-2">
                          {errors?.avatar?.message?.toString()?.toString()}
                        </div>
                      )}
                      {file && (
                        <div className="col-md-6 form-group mb-4">
                          <div className="product-img-card">
                            <img
                              src={
                                !newImage
                                  ? getImage(file)
                                  : URL.createObjectURL(newImage)
                              }
                              alt="product-img"
                              width={100}
                              height={100}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label htmlFor="price" className="majjal-label mb-2">
                          Prix <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control-majjal form-control form-control-lg"
                          id="price"
                          {...register("prix")}
                          placeholder="Prix du produit"
                        />
                        {errors?.prix && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {errors?.prix?.message?.toString()?.toString()}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label htmlFor="point" className="majjal-label mb-2">
                          Point ( 10f = 1 point )
                        </label>
                        <input
                          type="number"
                          min={0}
                          className="form-control-majjal form-control form-control-lg bg-slate"
                          id="point"
                          {...register("point")}
                          placeholder="Point du produit"
                        />
                        {errors?.point && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {errors?.point?.message?.toString()?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="discount=percentage"
                          className="majjal-label mb-2"
                        >
                          Pourcentage discount
                        </label>
                        <input
                          type="number"
                          min={0}
                          className="form-control-majjal form-control form-control-lg bg-slate"
                          id="discount=percentage"
                          {...register("discount")}
                          placeholder="pourcentage"
                        />
                        {errors?.discount && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {errors?.discount?.message?.toString()?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 submit-input-col">
                      <div className="submit-btn-container">
                        {isLoading && (
                          <button disabled className="btn-submit">
                            En cours&nbsp;{" "}
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                        {!isLoading && (
                          <button className="btn-submit" type="submit">
                            Modifier
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifProduitAdmin;
