import { useGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { onSetUserToken } from "../../../../redux/slices/user.slice";
import { useRegisterSocialMutation } from "../../../../utils/api/auth/auth.api";
import { RegisterSocialFormData } from "../../../../utils/api/auth/auth.type";
import { FACEBOOK_APP_ID } from "../../../../utils/constants";
import { getRoutePath } from "../../../routes/routes";
import { GoogleIcon, InstagramIcon, TwitterIcon } from "./AuthIcons";

const AuthSocialLogin = () => {
  const [sendData, { isSuccess, error, isError, isLoading, data }] =
    useRegisterSocialMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submit = (data: RegisterSocialFormData) => {
    sendData(data);
  };
  // useEffect(() => {
  //   submit({
  //     email: "ibrahima.k.mbaye@gmail.com",
  //     first_name: "test",
  //     last_name: "test",
  //   });
  // }, []);
  const responseGoogle = async (response) => {
    if (response?.email) {
      const { email, family_name, given_name } = response;
      submit({
        email,
        first_name: given_name,
        last_name: family_name,
      });
    }
  };
  const getName = (fullName: string) => {
    var first_name = fullName.split(" ").slice(0, -1).join(" ");
    var last_name = fullName.split(" ").slice(-1).join(" ");
    return {
      first_name,
      last_name,
    };
  };

  const responseFacebook = (response) => {
    console.log(response);
    if (response?.email) {
      const { email, name } = response;
      const { first_name, last_name } = getName(name);
      submit({
        email,
        first_name,
        last_name,
      });
    }
  };

  const login = useGoogleLogin({
    onSuccess: (response) => {
      fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${response?.access_token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          responseGoogle(json);
        });
    },
  });

  useEffect(() => {
    if (error) {
      const err = error as any;
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {};
      Swal.fire({
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [error]);

  useEffect(() => {
    if (data?.token) {
      dispatch(onSetUserToken({ token: data.token, user: data.data }));
      setTimeout(() => {
        if (data.data.user_type === "admin") {
          navigate(getRoutePath("DashboardAdmin"));
        } else {
          if (data.data.is_double_type) {
            navigate(getRoutePath("MerchantDashboard"));
          } else {
            navigate(getRoutePath("ListeProjets"));
          }
        }
      }, 400);
    }
  }, [data, isSuccess, isError]);
  return (
    <div className="auth-social-container">
      <div className=" d-flex justify-content-between my-3 gap-1 social-auth">
        <button
          onClick={() => login()}
          className="auth-social-icon-maajjal-cardd border-google-color "
        >
          <GoogleIcon />
        </button>
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          callback={(data) => responseFacebook(data)}
          cssClass="auth-social-icon-maajjal-cardd border-fa-color w-100"
          icon="fa-facebook"
          textButton=""
        />
        {false && (
          <button className="auth-social-icon-maajjal-cardd border-twit-color">
            <TwitterIcon />
          </button>
        )}
        {false && (
          <button className="auth-social-icon-maajjal-cardd border-inst-color">
            <InstagramIcon />
          </button>
        )}
      </div>
      <div className="auth-other-container position-relative">
        <p className="auth-other-line">Ou</p>
      </div>
    </div>
  );
};

export default () => {
  if (process.env.JEST_WORKER_ID !== undefined) return null;
  return <AuthSocialLogin />;
};
