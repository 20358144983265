import {
  BsFillChatDotsFill,
  BsFillFileEarmarkTextFill,
  BsGridFill,
  BsTagFill,
} from "react-icons/bs";
import { FaUserPlus } from "react-icons/fa";
import { IoBag, IoMail, IoSettingsSharp } from "react-icons/io5";
import { MdInsertChart } from "react-icons/md";
import { PERMISSIONS } from "../../../Modals/Administrateur/ModifierAdministrateurModal";
export const Routes: {
  label?: any;
  pathname?: any;
  icon?: any;
  permission?: keyof typeof PERMISSIONS;
}[] = [
  {
    label: "Dashboard",
    pathname: "/admin/tableau-de-bord",
    icon: <MdInsertChart />,
    // permission: "dashboard",
  },
  {
    label: "Projets",
    pathname: "/admin/projets",
    icon: <BsFillFileEarmarkTextFill />,
    permission: "project",
  },
  {
    label: "Utilisateurs",
    pathname: "/admin/utilisateurs",
    icon: <FaUserPlus />,
    permission: "users",
  },
  {
    label: "Produits",
    pathname: "/admin/produits",
    icon: <BsGridFill />,
    permission: "products",
  },
  {
    label: "Catégorie produit",
    pathname: "/admin/categorie-produit",
    permission: "categoryproduct",
    icon: <BsTagFill />,
  },
  {
    label: "Catégorie projet",
    pathname: "/admin/categorie-projet",
    permission: "categoryprojet",
    icon: <BsTagFill />,
  },
  {
    label: "Commandes",
    pathname: "/admin/commandes",
    permission: "commande",
    icon: <IoBag />,
  },
  {
    label: "FAQ",
    pathname: "/admin/faq",
    permission: "faq",
    icon: <BsFillChatDotsFill />,
  },
  {
    label: "Messages",
    pathname: "/admin/messages",
    permission: "message",
    icon: <IoMail />,
  },
  {
    label: "Paramètres",
    pathname: "/admin/parametres",
    icon: <IoSettingsSharp />,
  },
  {
    label: "Avis",
    pathname: "/admin/avis",
    permission: "avis",
    icon: <BsFillChatDotsFill />,
  },
];
