import { Produit } from "../../../../../utils/api/produit/produit.type";
import MarketPlaceSkeleton from "./MarketPlaceSkeleton";
import ProductMarketCard from "./ProductMarketCard";

const ProductsMarketList = ({ produits, isLoading }: { produits: Produit[], isLoading: boolean }) => {
  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
      {isLoading && 
          [...Array(6)].map((item, index) => { 
            return(
              <div className="row">
                <MarketPlaceSkeleton key={index} />
              </div>
            )
        })
      }
      {!isLoading && (
        produits.map((produit) => (
          <ProductMarketCard key={produit.id} produit={produit} />
        ))
      )}
    </div>
  );
};

export default ProductsMarketList;
