import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import MesDonsTable from "../../Tables/mescommandes/mesdons/MesDonsTable";
import Sidebar from "../Sidebar/Sidebar";
import "./MesDons.css";

function MesDons() {
  return (
    <div className="campagne-page-container">
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={1} />
          <div className="col-md-8 col-lg-9 col-xl-10 dashboard-page-content">
            <div className="mt-tableau-bord py-5">
              <div className="col-md-12">
                <h3 className="tb-mes-projet-titre mb-4">Mes dons</h3>
              </div>
              <div className="col-md-12 maajjal-table-card px-3 py-4">
                <MesDonsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default MesDons;
