import React, { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useGetCategorieQuery } from "../../../../utils/api/categorie/categorie.api";
import { Categorie } from "../../../../utils/api/categorie/categorie.type";
import { useProjetsOnlineQuery } from "../../../../utils/api/launchCampagn/projet.api";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import Pagination from "../../../shared/Pagination";
import { SEO } from "../../../shared/SEO";
import ProjectsList from "../../home/ProjectsList";
import CampainsSkeleton from "./CampainsSkeleton";
import "./CompainsListe.css";

export function SearchInput({ value, onChange }) {
  return (
    <div className="col-md-12">
      <div className="campagne-search-bar-container">
        <i
          className="fa fa-search icon custom-icon-search-mission"
          aria-hidden="true"
        ></i>
        <input
          type="search"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="form-control campagne-search-bar"
          placeholder="Rechercher..."
          id="campagneSearch"
          aria-describedby="campagneSearchHelp"
        />
      </div>
    </div>
  );
}
const CompainsListe = () => {
  const [search, setSearch] = React.useState("");
  const { data: categories = [] } = useGetCategorieQuery();
  const [category, setCategory] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const { data = { results: [] }, isLoading } = useProjetsOnlineQuery({
    page,
    category_id: category !== 0 ? category : undefined,
    name__icontains: search,
    statut: "online",
  });
  useEffect(() => {
    setPage(1);
  }, [category, search]);

  return (
    <>
      <SEO title="Campagnes" />
      <div className="campagne-page-container">
        <div>
          <FrontHeader />
        </div>
        <div className="campagne-page-content container-maajjal">
          <div className="row">
            <SearchInput value={search} onChange={setSearch} />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="campagne-categorie-label mt-3">Categories</div>
              <div className="campagne-filter-boutton-container mb-5">
                <CategorieItem
                  active={category === 0}
                  onCLick={(active) => {
                    setCategory(0);
                  }}
                  item={{
                    name: "Toutes",
                    id: 0,
                    slug: "",
                    created_at: "",
                    description: "",
                  }}
                />
                {categories.map((item) => (
                  <CategorieItem
                    active={item.id === category}
                    onCLick={(active) => {
                      if (!active) {
                        setCategory(item.id);
                      } else {
                        setCategory(0);
                      }
                    }}
                    key={item.id}
                    item={item}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            {isLoading &&
              [...Array(6)].map((item, index) => {
                return <CampainsSkeleton key={index} />;
              })}
          </div>
          {!isLoading && (
            <ProjectsList projects={data.results} isLoading={isLoading} />
          )}
          <Pagination page={page} onPageChange={setPage} total={data.count} />
        </div>
        <FrontFooter />
      </div>
    </>
  );
};
export default CompainsListe;

function CategorieItem({
  item,
  active,
  onCLick,
}: {
  item: Categorie;
  active: boolean;
  onCLick: (active: boolean) => any;
}) {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onCLick(active);
      }}
      className={`btn campagne-filter-boutton ${
        active && "campagne-filter-boutton-selected"
      }`}
    >
      {item.name}
    </button>
  );
}
