import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UserState } from "../../../redux/slices/user.slice";
import { ApiUrl } from "../../constants";
import { AppStorage } from "../../storage";
import { LoginResult } from "../auth/auth.type";
import { PaginationResults, QueryUrl } from "../common.api";
import { UserAdmin, UserAdminFormData } from "./userAdmin.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: LoginResult }).user.token ??
    AppStorage.getItem<UserState>("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};
export const UserAdminApi = createApi({
  tagTypes: [
    "user",
    "sellers",
    "users",
    "user_filter",
    "shared",
    "state",
    "usersAdmin",
  ],
  reducerPath: "userAdminApi",

  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addUserAdmin: builder.mutation<UserAdmin[], UserAdminFormData>({
      query: (data) => ({
        url: `/adminuser/`,
        method: "POST",
        body: data,
      }),
      transformResponse: ({ data }) => data,
      invalidatesTags: ["usersAdmin"],
    }),

    userAdminEdit: builder.mutation<UserAdmin, UserAdmin | any>({
      query: ({ slug, ...data }) => ({
        url: `/adminuser/${slug}/`,
        body: data,
        method: "PUT",
      }),
      invalidatesTags: ["usersAdmin"],
    }),
    getUsersAdmin: builder.query<
      PaginationResults<UserAdmin>,
      { page: number }
    >({
      query: (query) => QueryUrl("/adminuser/", query),
      providesTags: ["usersAdmin"],
    }),
    deleteUserAdmin: builder.mutation<UserAdmin, string | number>({
      query: (slug) => ({
        url: `/adminuser/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["usersAdmin"],
    }),
  }),
});

export const {
  useUserAdminEditMutation,
  useGetUsersAdminQuery,
  useDeleteUserAdminMutation,
  useAddUserAdminMutation,
} = UserAdminApi;
