import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UserState } from "../../../redux/slices/user.slice";
import { ApiUrl } from "../../constants";
import { AppStorage } from "../../storage";
import { prepareHeaders } from "../user/user.api";
import { Notification, NotificationFormData } from "./notification.type";

const NotificationApi = createApi({
  reducerPath: "notification",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["notification"],
  endpoints: (build) => ({
    get: build.query<Notification[], void>({
      query: () => {
        const id = AppStorage.getItem<UserState>("user", {}).user?.id;
        return `/notificationbyrecever/${id}/`;
      },
      providesTags: ["notification"],
    }),
    update: build.mutation<Notification, NotificationFormData>({
      query: ({ id, ...data }) => ({
        url: `/notification/${id}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["notification"],
    }),
  }),
});

export default NotificationApi;
