import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useRegisterMutation } from "../../../../utils/api/auth/auth.api";
import { RegisterFormData } from "../../../../utils/api/auth/auth.type";
import { validatePhone } from "../../../shared/PhoneInput";

export function validatePassword(Yup, message) {
  return Yup.string()
    .required(message)
    .min(8)
    .matches(
      /[a-z]+/,
      "Le mot de passe doit contenir au moins une lettre minuscule."
    )
    .matches(
      /[A-Z]+/,
      "Le mot de passe doit contenir au moins une lettre majuscule."
    )
    .matches(
      /[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
      "Le mot de passe doit contenir au moins un caractère spécial."
    )
    .matches(/\d+/, "Le mot de passe doit contenir au moins un chiffre.");
}

function UseRegisterForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required("L'email est requis"),
        phone: validatePhone(yup),
        adress: yup.string(),
        first_name: yup.string().required("Le prénom est requis"),
        last_name: yup.string().required("Le nom est requis"),
        cni: yup.mixed(),
        verso_cni: yup.mixed(),
        recto_cni: yup.mixed(),
        numero_cni: yup.string(),
        // .required("Le numéro de pièce d'identité est requis"),
        password: validatePassword(yup, "Le mot de passe est requis."),
        confirmPassword: yup
          .string()
          .oneOf(
            [yup.ref("password"), null],
            "veuillez entrer des mots de passe identiques."
          )
          .required("La confirmation du mot de passe est requis."),
      }),
    []
  );

  const [sendData, { isSuccess, isError, isLoading, error }] =
    useRegisterMutation();

  const { register, handleSubmit, formState, clearErrors, setValue, setError } =
    useForm<RegisterFormData>({
      resolver: yupResolver(validationSchema),
    });

  const onSubmit = async (data: RegisterFormData) => {
    console.log("donnée form", data);
    let fd: any = new FormData();

    // fd.append("adress", 'adress')

    Object.keys(data).map((key) => {
      fd.append(key, data[key]);
    });

    await sendData(fd);
  };

  useEffect(() => {
    if (isError) {
      console.log(error);
    }
  }, [isError]);

  return {
    register,
    formState,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    isSuccess,
    isLoading,
    error,
    isError,
    setValue,
    setError,
    // isSuccessful,
    // isFailed,
    // loading,
    // fail,
  };
}

export default UseRegisterForm;
