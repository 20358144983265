import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavLink } from "react-router-dom";
import { Produit } from "../../../utils/api/produit/produit.type";
import { formatAmout, getImage } from "../../../utils/utils";
import { getRoutePath } from "../../routes/routes";
import BtnAjouterAuPanier from "../shop/MarketPlace/Common/BtnAjouterAuPanier";
import NotificationModal from "../shop/MarketPlace/Common/NotificationModal";
import ProductItemSkeleton from "./ProductItemSkeleton";

const ProduitMarketplaceItem = ({
  products,
  isLoading,
}: {
  products: Produit[];
  isLoading: boolean;
}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="carousel-produit-marketplace">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={false}
        customTransition="transform 1000ms ease-in-out"
        transitionDuration={2000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {isLoading &&
          [...Array(3)].map((item, index) => {
            return (
              <div className="row">
                <ProductItemSkeleton key={index} />
              </div>
            );
          })}
        {!isLoading && products.map((p) => <ProductItem key={p.id} item={p} />)}
      </Carousel>
      <NotificationModal />
    </div>
  );
};

export default ProduitMarketplaceItem;

function ProductItem({ item }: { item: Produit }) {
  return (
    <div>
      <div className="produit-marketplace-item-component">
        <div>
          <div className="card position-relative">
            <NavLink
              state={item}
              to={getRoutePath("ProductMarketDetail", { slug: item.slug })}
            >
              <div className="product-img-container">
                <img
                  src={getImage(item.avatar)}
                  className="card-img-top product-img"
                  alt={item.name}
                />
              </div>
              <div className="texte-reduction-container text-end">
                {!!item.discount && (
                  <p className="texte-reduction-top">
                    <strong
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      -{item.discount}%{" "}
                    </strong>
                    ({item.point}pts)
                  </p>
                )}
              </div>
            </NavLink>
            <div className="card-body">
              <div className="entete-produit-item">
                <h5 className="card-title titre-produit">{item.name}</h5>
                <p className="texte-reduction">
                  <strong
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    {formatAmout(
                      item.discount ? item.price_discount : item.prix
                    )}
                  </strong>
                </p>
              </div>
              <BtnAjouterAuPanier produit={item} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
