import React from "react";
import { NavLink } from "react-router-dom";
import { Config } from "../../../utils/api/config/config.type";
import { createMarkup, getImage } from "../../../utils/utils";
import ImgBanniere from "../../assets/appImage/banniere.png";
import Dots from "../../assets/appImage/dots.png";

const Banniere = ({ config }: { config?: Config | null }) => {
  return (
    <div className="banniere-component">
      <div className="banniere-container">
        <div className="row">
          <div className="col-md-7 col-item">
            <div className="col-item-left">
              <div className="w-50 mb-5">
                <h1
                  className="titre-banniere"
                  dangerouslySetInnerHTML={createMarkup(config?.slogan)}
                />
              </div>
              <div className="row bloc-btn-banniere">
                <div className="col-xl-4 col-lg-4 col-md-12 pb-3">
                  <NavLink
                    to="app/lancer-une-campagne"
                    className="btn btn-theme-blue trans-0-2 w-100"
                  >
                    Lancer votre campagne
                  </NavLink>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 pb-3">
                  <NavLink
                    to="app/campagnes"
                    className="btn btn-thme-green trans-0-2 w-100"
                  >
                    Contribuer à un projet
                  </NavLink>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 pb-3">
                  <NavLink
                    to="/app/ajout-offre"
                    className="btn btn-thme-blue-outline trans-0-2 w-100"
                  >
                    Créer une offre
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-item">
            <div className="col-item-left position-relative">
              <img
                src={getImage(config?.image_rounded)}
                alt="Banniere"
                className="img-banner-principal"
              />
              <div className="bloc-img-dots">
                <img
                  src={Dots}
                  alt="Banniere"
                  className="img-banniere-principal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banniere;
