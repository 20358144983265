import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Produit } from "../../utils/api/produit/produit.type";
import { AppStorage } from "../../utils/storage";

export type ProduitState = Produit[];
// const initialState: ProduitState = []
const initialState: ProduitState = AppStorage.getItem<Produit[]>("produit", []);

export const produitSlice = createSlice({
  name: "produit",
  initialState,
  reducers: {
    addProduit: (state, action: PayloadAction<Produit>) => {
      const newState = [...state];
      const index = newState.findIndex(
        (produit) => produit.id === action.payload.id
      );
      const p = { ...action.payload, quantity: action.payload.quantity || 1 };
      if (index == -1) {
        newState.push(p);
      } else {
        newState[index] = p;
      }
      state = newState;
      AppStorage.setItem("produit", state);
      return state;
    },
    deleteProduit: (state, action: PayloadAction<Produit>) => {
      state = state.filter((produit) => produit.id !== action.payload.id);
      AppStorage.setItem("produit", state);
      return state;
    },
  },
});

export const { addProduit, deleteProduit } = produitSlice.actions;

export default produitSlice.reducer;
