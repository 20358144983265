import React from 'react'
import Skeleton from 'react-loading-skeleton'

function MesCommandesSkeleton() {
  return (
    <div className="col-md-12 as-col-table-container">
    <table className="table table-striped">
        <thead>
            <tr>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>N° Commande</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Date</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Nom du produit</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Client</th>
                <th scope="col" style={{ color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Montant total</th>
                <th scope="col" style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Statut de la commande</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <th scope="row"><Skeleton /></th>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
        </tbody>
    </table>
</div>
  )
}

export default MesCommandesSkeleton