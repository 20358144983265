import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Swal from "sweetalert2";
import { useAppSelector } from "../../../redux/store";
import { useAddRetraitMutation } from "../../../utils/api/launchCampagn/projet.api";
import {
  Projet,
  RetraitFormData,
} from "../../../utils/api/launchCampagn/projet.type";
import { COLORS } from "../../../utils/constants";

function useAddDebitForm(project: Projet, onHide: () => any) {
  let validateFunds = yup
    .number()
    .nullable()
    .required()
    .label("Montant")
    .min(10)
    .max(project.montant_total_restant);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        montant: validateFunds,
      }),
    []
  );

  const [sendData, { data, isLoading }] = useAddRetraitMutation();

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    getValues,
    reset,
  } = useForm<RetraitFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      projet: project?.id,
    },
  });

  const user = useAppSelector((s) => s.user.user);

  // console.log('user', user)

  const onSubmit = async (data: RetraitFormData) => {
    await sendData(data);
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (project) {
      register("projet");
      setValue("projet", project.id);
    }
  }, [project, user?.id]);

  useEffect(() => {
    if (data) {
      Swal.fire({
        icon: "success",
        title: "Débit ajouté avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        setValue("montant", "");
        onHide();
      });
    }
  }, [data]);

  return {
    register,
    errors: formState.errors,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    setValue,
    validate: handleSubmit,
    isLoading,
    getValues,
  };
}

export default useAddDebitForm;
