import ConditionApi from "../../../utils/api/condition/condition.api";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import { SEO } from "../../shared/SEO";
import "./Apropos.css";
export function CGU() {
  const { data = { text: "<h4>Chargement...<h4/>" } } =
    ConditionApi.useCondition_generale_utilisationQuery();
  return (
    <>
      <SEO title="Conditions Et Termes" />
      <div className="campagne-page-container">
        <div>
          <FrontHeader />
        </div>

        <div className="quisommesnous-page-couverture-container">
          <div className="container-cayore investir-intro-container">
            <h2 className="quisommesnous-page-couverture-title">
              Conditions Et Termes
            </h2>
          </div>
        </div>
        <section className="quisommesnous-page-content container-maajjal">
          <div className="quisommesnous-page-section-maajjal">
            <p
              dangerouslySetInnerHTML={{
                __html: data?.text ? data.text : "<h4>Chargement...<h4/>",
              }}
            />
          </div>
        </section>
        <FrontFooter />
      </div>
    </>
  );
}

export function PC() {
  const { data = { text: "<h4>Chargement...<h4/>" } } =
    ConditionApi.useCondition_generale_venteQuery();
  return (
    <>
      <SEO title="Mentions Legales" />
      <div className="campagne-page-container">
        <div>
          <FrontHeader />
        </div>
        <div className="quisommesnous-page-couverture-container">
          <div className="container-cayore investir-intro-container">
            <h2 className="quisommesnous-page-couverture-title">
              Mentions Legales
            </h2>
          </div>
        </div>
        <section className="quisommesnous-page-content container-maajjal">
          <p
            dangerouslySetInnerHTML={{
              __html: data?.text ? data.text : "<h4>Chargement...<h4/>",
            }}
          />
        </section>
        <FrontFooter />
      </div>
    </>
  );
}
