import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useEditProductMutation } from '../../../../../utils/api/produit/produit.api'
import { ProduitFormData } from '../../../../../utils/api/produit/produit.type'
import { COLORS } from '../../../../../utils/constants'
import { cleannerError } from '../../../../../utils/utils'

function UseEditProductForm() {
    const navigate = useNavigate();
	const validationSchema = yup.object().shape({
		name: yup.string().required().label('Le nom du produit').nullable(),

        category: yup
        .string()
        .required()
        .label('La catégorie du produit')
        .nullable(),

        commercant: yup.string().required().label('Le commerçant').nullable(),
        description: yup
        .string()
        .required()
        .label('La description du produit')
        .nullable(),
        prix: yup.string().required().label('Le prix du produit').nullable(),

        avatar: yup.mixed().label("L'image").nullable(),

        point: yup.string().nullable().nullable(),

        discount: yup.string().label('Le modèle').nullable(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<ProduitFormData | any>({
		resolver: yupResolver(validationSchema),
	});

    const [sendData, { isLoading, isSuccess, isError, error, data }] = useEditProductMutation();

    useEffect(() => {
		if (errors) {
			for (let key of Object.keys(errors)) {
				if (key) {
					window.scrollTo(10, 10);
				}
			}
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			console.log("success", data);
			Swal.fire({
				icon: "success",
				title: "Produit modifié avec succès!",
				iconColor: COLORS.green,
				showConfirmButton: false,
				timer: 2000, 
			}).then(() => {
				navigate("/admin/produits", { replace: false });
			});
		}
	}, [isSuccess]);
	const onSubmit = async (formValues) => {
		console.log(formValues);

		let data = formValues;
		const fd = new FormData();

		for (let key of Object.keys(data)) {
			const val = data[key];
			if (key === "avatar") {
                fd.append(key, val);
			} 
            else {
				fd.append(key, val);
			}
		}

		await sendData({ slug: formValues?.slug, data: fd });
	};

    return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading,
		setError,
	};
}

export default UseEditProductForm