import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteProduit } from "../../../../redux/slices/produit.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useAddAchatMutation } from "../../../../utils/api/achat/achat.api";
import { AchatFormData } from "../../../../utils/api/achat/achat.type";
import { Produit } from "../../../../utils/api/produit/produit.type";
import { useModalActions } from "../../../../utils/utils";
import { getRoutePath } from "../../../routes/routes";
import { CustomSwal } from "../common/CustomSwal";
import { ButtonBack, ButtonNext } from "../common/StepperButton";
import { calculatePrices } from "./ShoppingCartDetail";

interface PropsType {
  navigation: any;
  item: Produit;
  modalId: string;
}

const InformationForDelevry = ({ navigation, item, modalId }: PropsType) => {
  const ma = useModalActions(modalId);
  const user = useAppSelector((s) => s.user.user);
  const dispatch = useAppDispatch();
  const [addAchat] = useAddAchatMutation();
  const [adress, setAdress] = React.useState(user?.adress ?? "");
  const [phone, setPhone] = React.useState(
    user?.phone && user.phone !== "null" ? user.phone : ""
  );
  const [error, setError] = React.useState<string>("");
  const [error1, setError1] = React.useState<string>("");
  const navigate = useNavigate();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (adress?.trim().length === 0) {
      setError("Ce champ est obligatoire");
      return;
    } else {
      setError("");
    }
    if (phone?.trim().length === 0) {
      setError1("Ce champ est obligatoire");
      return;
    } else {
      setError1("");
    }
    e.preventDefault();
    CustomSwal.fire({
      width: "22rem",
      icon: "success",
      iconColor: "#0FBF56",
      html: "Souhaitez-vous confirmer cette commande",
      confirmButtonText: "Oui je confirme",
      confirmButtonColor: "#0FBF56",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const { total, qte, qte_discount, total_point } = calculatePrices(item);
        const achat: AchatFormData = {
          adresse_livraison: adress,
          phone: phone,
          discount: qte_discount > 0,
          env: window.location.origin,
          page: getRoutePath("ProductMarketDetail", { slug: item.slug }),
          mode_payment: "en_ligne",
          montant_total: total,
          produit: item.id,
          point: total_point,
          quantity: qte,
          user_id: user?.id as number,
          status: "en_attente",
        };
        const res: any = await addAchat(achat);
        if (res?.data) {
          dispatch(deleteProduit(item));
          CustomSwal.fire({
            width: "22rem",
            icon: "success",
            iconColor: "#0FBF56",
            html: "<h2 class='swal-message-style'>Vous avez passé votre <strong>commande</strong> avec succès<br/>  Merci de patienter pour la validation</h2>",
            confirmButtonText: "OK",
            confirmButtonColor: "#0FBF56",
          }).then(() => {
            ma.hide();
            navigate(
              getRoutePath(
                !user?.is_double_type ? "MesCommandes" : "CommandeList"
              )
            );
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Une erreur est survenue lors de l'ajout de la commande",
            confirmButtonText: "OK",
            confirmButtonColor: "#0FBF56",
          });
        }
      },
      showCancelButton: true,
      cancelButtonText: "Annuler",
      cancelButtonColor: "#D90000",
      reverseButtons: true,
      allowOutsideClick: () => !CustomSwal.isLoading(),
    }).then(() => {});
  };

  return (
    <div className="px-3 py-2 mb-3">
      <h1 className="info-delevry-title">Information de livraison</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="adresse" className="majjal-label mb-2">
            Adresse de Livraison
          </label>
          <input
            type="text"
            className="form-control-majjal form-control form-control-lg"
            id="adresse"
            name="adresse"
            value={adress}
            onChange={(e) => setAdress(e.target.value)}
            placeholder="Adresse"
          />
          {!!error && (
            <div className="alert alert-danger sia-alert-danger closer mt-2">
              {error}
            </div>
          )}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="tel" className="majjal-label mb-2">
            Téléphone
          </label>
          <input
            type="tel"
            className="form-control-majjal form-control form-control-lg"
            id="tel"
            name="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Téléphone"
          />
          {!!error1 && (
            <div className="alert alert-danger sia-alert-danger closer mt-2">
              {error1}
            </div>
          )}
        </div>
        <div className="modal-btn-container mt-5 flex-r gap-5">
          <ButtonBack modalId={modalId} navigation={navigation} />
          <ButtonNext />
        </div>
      </form>
    </div>
  );
};

export default InformationForDelevry;
