import { useStep } from "react-hooks-helper";
import useStepType from "../../../..";
import { Produit } from "../../../utils/api/produit/produit.type";
import "./ProcessPaiement.css";
import InformationForDelevry from "./Steps/InformationForDelevry";
import ShoppingCartDetail from "./Steps/ShoppingCartDetail";

const ProcessPaiement = ({
  item,
  modalId,
}: {
  item: Produit;
  modalId: string;
}) => {
  const steps = [
    { id: "shopping-cart-detail", Component: ShoppingCartDetail },
    { id: "info-delevry", Component: InformationForDelevry },
    // { id: "mode-paiement", Component: ModePaiement },
    // { id: "success-paiement", Component: DetailSuccessPaiement },
  ];
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const props = {
    navigation,
    item,
    modalId,
  };
  return (
    <div className="modal fade shopping-cart-modal" id={modalId}>
      <div className="modal-dialog modal-dialog-centered">
        <div className={`modal-content ${index === 3 ? "px-3" : "px-2"} py-4`}>
          <div style={{ display: index === 0 ? "block" : "none" }}>
            <ShoppingCartDetail {...props} />
          </div>
          <div style={{ display: index === 1 ? "block" : "none" }}>
            <InformationForDelevry {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessPaiement;
