import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Swal from "sweetalert2";
import { useAppSelector } from "../../../redux/store";
import InvestApi from "../../../utils/api/invest/invest.api";
import { InvestFormData } from "../../../utils/api/invest/invest.type";
import { Projet } from "../../../utils/api/launchCampagn/projet.type";
import { COLORS } from "../../../utils/constants";
import { openPopup } from "../../../utils/utils";
import { validatePhone } from "../../shared/PhoneInput";

function useAddInvestsForm(
  project: Projet,
  onHide: () => any,
  admin?: boolean
) {
  let validateFunds = yup.number().nullable().required().label("Montant");
  if (project.min_amount) {
    validateFunds = validateFunds.min(parseFloat(project.min_amount));
  }
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        funds: validateFunds,
        phone: validatePhone(yup),
        prenom: yup.string().required().label("Prénom"),
        nom: yup.string().required().label("Nom"),
        email: yup.string().email().label("Email"),
      }),
    []
  );

  const [sendData, { data, isLoading }] = InvestApi.useAddMutation();

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    getValues,
    reset,
  } = useForm<InvestFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      anonyme: false,
      env: window.location.origin,
      projet_id: project?.id,
      page: `app/campagnes/${project.slug}/`,
    },
  });

  const user = useAppSelector((s) => s.user.user);

  // console.log('user', user)

  const onSubmit = async (data: InvestFormData) => {
    await sendData({ admin, data });
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (user?.id && !admin) {
      register("user_id");
      register("prenom");
      register("nom");
      register("phone");
      register("email");

      setValue("user_id", user.id);
      setValue("prenom", user.first_name);
      setValue("nom", user.last_name);
      setValue("phone", user.phone);
      setValue("email", user.email);
    }

    if (project) {
      register("projet_id");
      setValue("projet_id", project.id);
    }
    register("anonyme");
    register("env");
    register("page");
    setValue("env", window.location.origin);
    setValue("anonyme", false);
    setValue("page", `app/campagnes/${project.slug}/`);
  }, [project, user?.id, admin]);

  useEffect(() => {
    if (data) {
      if (!admin) {
        if (data.message) {
          Swal.fire({
            title: data.message,
            icon: "error",
            iconColor: COLORS.blue,
            confirmButtonColor: COLORS.blue,
          });
        } else {
          if (data.response_text) {
            openPopup(data.response_text);
          }
        }
      } else {
        Swal.fire({
          icon: "success",
          title: "Don ajouté avec succès!",
          iconColor: COLORS.green,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          reset();
          onHide();
        });
      }
    }
  }, [data, admin]);

  return {
    register,
    errors: formState.errors,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    setValue,
    validate: handleSubmit,
    isLoading,
    getValues,
  };
}

export default useAddInvestsForm;
