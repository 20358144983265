import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import InvestApi from "../../../../../utils/api/invest/invest.api";
import { Invest } from "../../../../../utils/api/invest/invest.type";
import { COLORS } from "../../../../../utils/constants";
import { useModalActions } from "../../../../../utils/utils";
import BtnCloseModal from "../../../../shared/BtnCloseModal";
import BtnSubmit from "../../../../shared/BtnSubmit";

const UpdateInvestModal = ({
  modalId,
  item,
}: {
  modalId: string;
  item: Invest;
}) => {
  const ma = useModalActions(modalId);
  const [commentaire, setCommentaire] = useState(item.commentaire || "");
  const [update, { isLoading, isSuccess }] = InvestApi.useUpdateMutation();
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Données modifiées avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      });
      ma.hide();
    }
  }, [isSuccess]);
  return (
    <div className="modal fade" id={modalId}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content px-4 py-3">
          <div className="flex-csb mb-4">
            <h1 className="admin-modal-title m-0">Modification</h1>
            <BtnCloseModal />
          </div>
          <div className="reponse-form-container">
            {/* <h3 className="admin-modal-sous-title">Réponse</h3> */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                update({ commentaire, id: item.id });
              }}
            >
              <div className="form-group">
                <label htmlFor="question" className="admin-form-label">
                  Commentaire
                </label>
                <textarea
                  id="reponse"
                  placeholder="Ajouter une réponse"
                  value={commentaire}
                  onChange={(e) => setCommentaire(e.target.value)}
                  className="form-control form-control-lg form-admin-textarea"
                />
              </div>
              <div className="btn-container flex-r mt-5">
                {isLoading && (
                  <button disabled className="admin-submit-btn">
                    En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isLoading && <BtnSubmit label="Modifier" />}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateInvestModal;
