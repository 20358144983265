import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useResetPasswordMutation } from "../../../../utils/api/auth/auth.api";
import {
  ForgetPasswordFormData,
  ResetPasswordFormData,
} from "../../../../utils/api/auth/auth.type";
import { COLORS } from "../../../../utils/constants";
import { validatePassword } from "./UseRegisterForm";

function UseResetPasswordForm() {
  const [resetPassword, { error, isSuccess, isLoading }] =
    useResetPasswordMutation();

  const state = useLocation().state as ForgetPasswordFormData;

  const navigate = useNavigate();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email()
          .required("L'email est requis")
          .label("Email"),
        code: yup
          .number()
          .required()
          .typeError("Vous devez renseigner le code")
          .min(0)
          .label("Code"),
        new_password: validatePassword(
          yup,
          "Le nouveau mot de passe est requis."
        ),
        new_password_confirm: yup
          .string()
          .oneOf(
            [yup.ref("new_password"), null],
            "veuillez entrer des mots de passe identiques."
          ),
      }),
    []
  );

  const { register, handleSubmit, formState, setError, clearErrors, setValue } =
    useForm<ResetPasswordFormData>({
      resolver: yupResolver(validationSchema),
    });

  useEffect(() => {
    if (state?.email) {
      register("email");
      setValue("email", state.email);
    }
  }, [state?.email]);

  useEffect(() => {
    if (error) {
      const err = error as any;
      const { message = "Une erreur inconnue s'est produite" } = err.data || {};
      setError("email", { message: message });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: COLORS.green,
        confirmButtonColor: COLORS.green,
        title: "Mot de passe récupéré avec succès",
      });
      navigate("/se-connecter");
    }
  }, [isSuccess]);

  return {
    register,
    errors: formState.errors,
    onSubmit: handleSubmit(resetPassword),
    clearErrors,
    isLoading,
    setError,
  };
}

export default UseResetPasswordForm;
