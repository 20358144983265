import React from 'react'
import { FiPlus } from 'react-icons/fi'
import AddMontantModal from '../../../Modals/MontantModal/AddMontantModal'
import MontantsTable from '../../../Tables/MontantsTable/MontantsTable'

function GestionMontants() {
  return (
    <div>
      <div className="flex-r">
        <button
          className="open-modal-add-admin"
          data-bs-toggle="modal"
          data-bs-target="#ajoutMontantModal"
        >
          <FiPlus
            style={{
              color: '#021849',
              marginRight: 10,
            }}
          />
          <span>Ajouter un montant</span>
        </button>
      </div>
      <MontantsTable />
      <AddMontantModal modalId="ajoutMontantModal" />
    </div>
  )
}

export default GestionMontants
