import { useProjetFromLocation } from "../../../../../utils/api/launchCampagn/projet.api";
import { UpdateCampainComponent } from "../../../LaunchACampaingn/UpdateCampain";
import { DetailsProjetComponent } from "../../../TableauDeBord/mesprojets/DetailsProjet/DetailsProjet";
import SideBarAdmin from "../../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../../Layouts/TopBar/TopBar";
import "./DetailsProjetAdmin.css";

function DetailsProjetAdmin() {
  return (
    <div className="parametre-page ">
      <TopBar index={1} />
      <SideBarAdmin index={1} />

      <div className="parametre-component m-tableau-bord flex-r">
        <div className="admin-page-container">
          <DetailsProjetComponent />
        </div>
      </div>
    </div>
  );
}

export default DetailsProjetAdmin;

export function UpdateProject() {
  const [item] = useProjetFromLocation();
  if (!item) return null;

  return (
    <div className="parametre-page ">
      <TopBar index={1} />
      <SideBarAdmin index={1} />

      <div className="parametre-component m-tableau-bord flex-r">
        <div className="admin-page-container">
          <UpdateCampainComponent projet={item} />
        </div>
      </div>
    </div>
  );
}

export function AddProjectAdmin() {
  return (
    <div className="parametre-page ">
      <TopBar index={1} />
      <SideBarAdmin index={1} />

      <div className="parametre-component m-tableau-bord flex-r">
        <div className="admin-page-container">
          <UpdateCampainComponent projet={undefined} />
        </div>
      </div>
    </div>
  );
}
