import { Projet } from "../../utils/api/launchCampagn/projet.type";
import { useModalActions } from "../../utils/utils";
import { ConfirmDebitModal } from "./ConfirmationInvestModal";
import "./InvestissementModal.css";
import useAddDebitForm from "./stepsComponents/useAddDebit";
function DebitModal({ modalId, projet }: { modalId: string; projet: Projet }) {
  const ma = useModalActions(modalId);
  const confirmModalId = `${modalId}_confirm`;
  const cma = useModalActions(confirmModalId);
  const { errors, isLoading, onSubmit, register, getValues, validate } =
    useAddDebitForm(projet, cma.hide);

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        data-bs-backdrop="static"
        data-backdrop="false"
        data-bs-keyboard="false"
        aria-labelledby={`label${modalId}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content custom-modal-investissement">
            <div className="modal-header modal-header-investissement">
              <h5 className="modal-title" id={`label${modalId}`}>
                Ajouter un débit
              </h5>
            </div>
            <div className="modal-body modal-invessetissement-body">
              <div className="modal-contribution-steps">
                <div className="modal-investissement-montants-input">
                  <input
                    type="number"
                    className="form-control-contribution-steps form-control form-control-lg"
                    {...register("montant")}
                    placeholder={`Montant à débiter max: ${projet.montant_total_restant}`}
                  />
                  {errors.montant && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.montant?.message?.toString()}
                    </div>
                  )}
                </div>

                <div className="modal-investissement-montants-input-bouton">
                  <div className="modal-investissement-montants-button-container">
                    <button
                      type="button"
                      className="btn  modal-investissement-montants-btn-annuler"
                      onClick={() => {
                        ma.hide();
                      }}
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      className="btn modal-investissement-montants-btn-continuer"
                      onClick={() => {
                        validate((data) => {
                          console.error("validated", data);
                          ma.hide();
                          setTimeout(() => {
                            cma.show();
                          }, 1000);
                        })();
                      }}
                    >
                      Continuer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDebitModal
        projectName={projet.name}
        funds={getValues().montant}
        onHide={() => cma.hide()}
        loading={isLoading}
        onConfirm={() => {
          onSubmit();
        }}
        modalId={confirmModalId}
      />
    </>
  );
}

export default DebitModal;
