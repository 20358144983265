import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiUrl } from "../../constants";
import { prepareHeaders } from "../user/user.api";
import { Team, TeamFormData } from "./team.type";

const TeamApi = createApi({
  reducerPath: "team",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["team"],
  endpoints: (build) => ({
    addTeam: build.mutation<
      Team,
      { slug?: string; data: FormData | TeamFormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `/team/${slug}/` : `/team/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["team"],
    }),
    deleteTeam: build.mutation<Team, string>({
      query: (slug) => ({
        url: `/team/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["team"],
    }),
    getTeam: build.query<Team[], void>({
      query: () => `/teambyvisitor/`,
      providesTags: ["team"],
    }),
  }),
});

export default TeamApi;
