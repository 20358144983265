import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import ProjetsAdminTable from "../../Tables/admin/projets/ProjetsAdminTable";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import "./ListeDesProjetsAdmin.css";

function ListeDesProjetsAdmin() {
  const [word1, setWord1] = useState("");
  const [word2, setWord2] = useState("");
  return (
    <div className="parametre-page">
      <TopBar index={1} />
      <SideBarAdmin index={1} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="p4 p-xxl-3">
            <div className="row">
              <div className="col-md-12 maajjal-table-card px-3 py-4 ml-2 mr-3">
                <div className="col-md-12">
                  <h3 className="tb-mes-projet-titre mb-4">
                    Liste des projets
                  </h3>
                </div>
                <div className="admin-projets-btn-ajout-container">
                  <NavLink
                    to={"/admin/ajouter-projets"}
                    type="button"
                    className="btn admin-projets-btn-ajout"
                  >
                    <AiOutlinePlus className="admin-projets-btn-ajout-icon" />{" "}
                    Ajouter un projet
                  </NavLink>
                </div>
                <div className="dashboard-commandes-filtre-container ">
                  <form className="row g-3 dashboard-commandes-filtre-form">
                    <div className="col-auto">
                      <label
                        htmlFor="staticEmail2"
                        className="dashboard-commandes-filtre-label"
                      >
                        Filtrer
                      </label>
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        value={word1}
                        onChange={(e) => setWord1(e.target.value)}
                        className="form-control dashboard-commandes-filtre-input"
                        id="inputPassword3"
                        placeholder="Projet"
                      />
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        value={word2}
                        onChange={(e) => setWord2(e.target.value)}
                        className="form-control dashboard-commandes-filtre-input"
                        id="inputPassword3"
                        placeholder="Commercant"
                      />
                    </div>
                  </form>
                </div>

                <ProjetsAdminTable word1={word1} word2={word2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListeDesProjetsAdmin;
