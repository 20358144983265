import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface PropsType {
	className?: string;
	label: string;
}
const GoBackBtn: React.FC<PropsType> = ({ className, label }) => {
	const navigate = useNavigate();

	return (
		<button className={className} onClick={() => navigate(-1)}>
			<FiArrowLeft
				style={{
					color: "#021849",
				}}
			/>
			{label}
		</button>
	);
};

export default GoBackBtn;
