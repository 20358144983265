import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../../redux/store";
import { useUserEditMutation } from "../../../../../utils/api/user/user.api";
import { UserFormData } from "../../../../../utils/api/user/user.type";
import { COLORS } from "../../../../../utils/constants";
import { getAvatar } from "../../../../../utils/utils";
import BtnSubmit from "../../../../shared/BtnSubmit";

const InfoPersonnelles = () => {
  const user = useAppSelector((s) => s.user.user);
  const [file, setFile] = React.useState<string | null>(null);
  const [sendData, { isSuccess, isLoading, data }] = useUserEditMutation();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        first_name: yup.string().required("Le prénom est requis"),
        last_name: yup.string().required("Le nom est requis"),
        email: yup.string().required("L'adress email est requis").nullable(),
        phone: yup.string().nullable(),
        adresse: yup.string().nullable(),
      }),
    []
  );
  const { register, setValue, formState, clearErrors, handleSubmit } =
    useForm<UserFormData>({
      resolver: yupResolver(validationSchema),
    });

  useEffect(() => {
    if (user?.id) {
      setValue("id", user?.id);
      setValue("slug", user?.slug);
      setValue("first_name", user?.first_name);
      setValue("last_name", user?.last_name);
      setValue("phone", user?.phone);
      setValue("email", user?.email);
      setValue("adress", user?.adress);
      setFile(getAvatar(user.avatar));
    }
  }, [user]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          console.log(key);
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (data && isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Modification réussie!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        setTimeout(() => {}, 1050);
      });
    }
  }, [data, isSuccess]);

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];
    register("avatar");
    setValue("avatar", file);
    setFile(URL.createObjectURL(file));
  };

  const onSubmit = async (data) => {
    let fd: any = new FormData();

    Object.keys(data).map((key) => {
      fd.append(key, data[key]);
    });
    await sendData(fd);
  };

  return (
    <form className="px-xl-5 px-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column mt-0">
        <div className="d-flex flex-column align-items-center avatar-container mt-2">
          <label htmlFor="photo-profil">
            {!!file && (
              <img
                src={file}
                alt="avatar-user"
                className="rounded-circle"
                style={{ width: "150px", height: "150px" }}
              />
            )}
          </label>
          <input
            type="file"
            id="photo-profil"
            className="op-0"
            title=""
            onChange={handleChangeAvatar}
          />
        </div>
        <div className="d-flex flex-column align-items-center">
          <h1 className="user-name">
            {user?.first_name + " " + user?.last_name}
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 form-group mb-3">
          <label htmlFor="prenom" className="admin-form-label">
            Prénom
          </label>
          <input
            type="text"
            id="prenom"
            className="form-control admin-form-control"
            placeholder="Prénom"
            {...register("first_name")}
          />
          {formState?.errors.first_name && (
            <div className="alert alert-danger sia-alert-danger closer">
              {formState?.errors.first_name?.message?.toString()}
            </div>
          )}
        </div>
        <div className="col-md-6 form-group mb-3">
          <label htmlFor="prenom" className="admin-form-label">
            Nom
          </label>
          <input
            type="text"
            id="nom"
            className="form-control admin-form-control"
            placeholder="Nom"
            {...register("last_name")}
          />
          {formState?.errors.last_name && (
            <div className="alert alert-danger sia-alert-danger closer">
              {formState?.errors.last_name?.message?.toString()}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 form-group mb-3">
          <label htmlFor="prentelom" className="admin-form-label">
            Téléphone
          </label>
          <input
            type="tel"
            id="tel"
            className="form-control admin-form-control"
            placeholder="Téléphone"
            {...register("phone")}
          />
          {formState?.errors.phone && (
            <div className="alert alert-danger sia-alert-danger closer">
              {formState?.errors.phone?.message?.toString()}
            </div>
          )}
        </div>
        <div className="col-md-6 form-group mb-3">
          <label htmlFor="email" className="admin-form-label">
            Adresse mail
          </label>
          <input
            type="email"
            id="email"
            className="form-control admin-form-control"
            placeholder="mail@gmail.com"
            {...register("email")}
          />
          {formState?.errors.email && (
            <div className="alert alert-danger sia-alert-danger closer">
              {formState?.errors.email?.message?.toString()}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 form-group mb-3">
          <label htmlFor="adresse" className="admin-form-label">
            Adresse
          </label>
          <input
            type="text"
            id="adresse"
            className="form-control admin-form-control"
            placeholder="Mermoz"
            {...register("adress")}
          />
          {formState?.errors.adress && (
            <div className="alert alert-danger sia-alert-danger closer">
              {formState?.errors.adress?.message?.toString()}
            </div>
          )}
        </div>
      </div>
      <div className="btn-container flex-r mt-5">
        {isLoading && (
          <button disabled className="admin-submit-btn">
            Modification&nbsp; <i className="fas fa-spin fa-spinner"></i>
          </button>
        )}
        {!isLoading && <BtnSubmit label="Modifier" />}
      </div>
    </form>
  );
};

export default InfoPersonnelles;
