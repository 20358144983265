import { useEffect } from "react";
import Swal from "sweetalert2";
import {
  useAchatPay,
  useUpdateAchatMutation,
} from "../../../../utils/api/achat/achat.api";
import { Achat } from "../../../../utils/api/achat/achat.type";
import { COLORS } from "../../../../utils/constants";
import { formatMontant, getImage } from "../../../../utils/utils";

export default function MesAchatsDetails({ order }: { order: Achat }) {
  const [onPay, paying] = useAchatPay();
  const produit = order.produit || {};
  const [updateAchat, { isLoading, data }] = useUpdateAchatMutation();
  useEffect(() => {
    if (data) {
      Swal.fire({
        icon: "success",
        title:
          data.status === "annuler"
            ? "Commande annulée avec succès!"
            : "Commande reçu avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [data]);
  return (
    <div className="dashboard-commande-detail-container">
      <div className="dashboard-commande-detail-produit-div">
        <div className="dashboard-commande-detail-produi-title">Produit</div>
        <div className="row g-3">
          <div className="col-auto">
            <img
              src={getImage(produit.avatar)}
              className="dashboard-commande-detail-produit-img"
              alt="..."
            />
          </div>
          <div className="col-auto">
            <div className="dashboard-commande-detail-produit-quantite-container">
              <div className="dashboard-commande-detail-produit-quantite">
                1 x {produit.name}
              </div>
              <div className="dashboard-commande-detail-produit-quantite">
                {formatMontant(order.montant_total)}
              </div>
            </div>
            <div className="dashboard-commande-detail-produit-quantite-container2">
              <div className="dashboard-commande-detail-produit-quantite2">
                Total
              </div>
              <div className="dashboard-commande-detail-produit-quantite2">
                {formatMontant(order.montant_total)}
              </div>
            </div>
          </div>
          <div className="col-auto"></div>
        </div>
      </div>
      <div className="dashboard-commande-detail-produit-div">
        <div className="dashboard-commande-detail-produi-title">
          Informations
        </div>
        <div className="row">
          <div className="col-6">
            <div className="d-flex justify-content-between mb-3">
              <div className="dashboard-commande-detail-produit-information-nom1">
                Nom du commerçant :
              </div>
              <div className="dashboard-commande-detail-produit-information-nom2">
                {produit?.commercant?.first_name}{" "}
                {produit?.commercant?.last_name}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <div className="dashboard-commande-detail-produit-information-nom1">
                Téléphone:
              </div>
              <div className="dashboard-commande-detail-produit-information-nom2">
                {produit?.commercant?.phone}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="dashboard-commande-detail-produit-information-nom1">
                Adresse:
              </div>
              <div className="dashboard-commande-detail-produit-information-nom2">
                {produit?.commercant?.adress}
              </div>
            </div>
          </div>
          <div className="col-6"></div>
        </div>
      </div>
      <div className="dashboard-commande-detail-btn-div">
        {order.status === "payer" && (
          <>
            <div className="dashboard-commande-detail-btn-label">
              Cliquez ici si vous avez recu la commande
            </div>
            {isLoading && (
              <button
                type="button"
                className="btn dashboard-commande-detail-btn"
              >
                Chargement...
              </button>
            )}
            {!isLoading && (
              <button
                onClick={() =>
                  updateAchat({ id: order.id, data: { status: "livrer" } })
                }
                type="button"
                className="btn dashboard-commande-detail-btn"
              >
                reçu
              </button>
            )}
          </>
        )}
        {["en_attente", "en_cours", "valide"].includes(order.status) && (
          <>
            {!isLoading && (
              <button
                type="button"
                onClick={() => {
                  Swal.fire({
                    width: "22rem",
                    icon: "success",
                    iconColor: "#0FBF56",
                    html: "Souhaitez-vous annuler cette commande",
                    confirmButtonText: "Confirmer",
                    showCancelButton: true,
                    cancelButtonText: "Annuler",
                    cancelButtonColor: "#c0392b",
                    confirmButtonColor: "#0FBF56",
                  }).then((res) => {
                    if (res.isConfirmed) {
                      updateAchat({
                        id: order.id,
                        data: { status: "annuler" },
                      });
                    }
                  });
                }}
                className="btn dashboard-commande-detail-btn-cancel"
              >
                Annuler la commande
              </button>
            )}
            {isLoading && (
              <button
                type="button"
                className="btn dashboard-commande-detail-btn-cancel"
              >
                En cours...
              </button>
            )}
          </>
        )}
        {order.status === "valide" && (
          <>
            {!paying && (
              <button
                type="button"
                onClick={() => {
                  onPay(order);
                }}
                className="btn dashboard-commande-detail-btn-valide"
              >
                Payer la commande
              </button>
            )}
            {paying && (
              <button
                type="button"
                className="btn dashboard-commande-detail-btn-valide"
              >
                En cours...
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
