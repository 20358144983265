import _ from "lodash";
import ConfigApi from "../../../utils/api/config/config.api";
import TeamApi from "../../../utils/api/team/team.api";
import { getAvatar } from "../../../utils/utils";
import qsnImage1 from "../../assets/appImage/qsnImage1.png";
import qsnImage2 from "../../assets/appImage/qsnImage2.png";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import { SEO } from "../../shared/SEO";
import "./Apropos.css";
function Apropos() {
  const { data = [] } = TeamApi.useGetTeamQuery();
  const { data: config } = ConfigApi.useGetConfigQuery();
  const teams = _.shuffle([...data]);
  return (
    <>
      <SEO title="Qui-sommes-nous ?" />
      <div className="campagne-page-container">
        <div>
          <FrontHeader />
        </div>

        <div className="quisommesnous-page-couverture-container">
          <div className="container-cayore investir-intro-container">
            <h2 className="quisommesnous-page-couverture-title">
              Qui sommes-nous?
            </h2>
          </div>
        </div>
        <section className="quisommesnous-page-content container-maajjal">
          <div className="quisommesnous-page-section-maajjal">
            <h3 className="quisommesnous-page-title">Maajjal</h3>
            <p
              className="quisommesnous-page-para"
              dangerouslySetInnerHTML={{ __html: config?.description || "" }}
            />
          </div>
          <div className="row quisommesnous-page-section-nosvaleur">
            <div className="col-lg-6 quisommesnous-nosvaleur-text-container">
              <div>
                <h3 className="quisommesnous-page-title">Nos valeurs</h3>
                <p
                  className="quisommesnous-page-para"
                  dangerouslySetInnerHTML={{
                    __html: config?.value || "",
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 quisommesnous-nosvaleur-img-container">
              <div className="p-3">
                <img src={qsnImage2} className="img-quisommenous" alt="..." />
              </div>
            </div>
          </div>
          <div className="row quisommesnous-page-section-notre-mission">
            <div className="col-lg-6 quisommesnous-nosvaleur-img-container2">
              <div className="p-3">
                <img src={qsnImage1} className="img-quisommenous" alt="..." />
              </div>
            </div>
            <div className="col-lg-6 quisommesnous-nosvaleur-text-container">
              <div>
                <h3 className="quisommesnous-page-title">Notre mission</h3>
                <p
                  className="quisommesnous-page-para"
                  dangerouslySetInnerHTML={{
                    __html: config?.mission || "",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="quisommesnous-page-section-notre-equipe">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <h3 className="quisommesnous-page-title quisommesnous-page-title-notreequipe">
                    Notre équipe
                  </h3>
                </div>
                <p
                  className="quisommesnous-page-para"
                  dangerouslySetInnerHTML={{
                    __html: config?.team || "",
                  }}
                />
              </div>
            </div>
            <div className="row quisommesnous-card-container">
              {teams.map((item) => (
                <div key={item.id} className="col-md-4">
                  <div className="card card-body quisommesnous-card-item mb-3">
                    <div className="img-card-container">
                      <img
                        src={getAvatar(item.avatar)}
                        style={{ width: 140, height: 140, borderRadius: "50%" }}
                        className="img-card-quisommenous"
                        alt={item.fullname}
                      />
                    </div>
                    <div className="username-card-container">
                      <div className="username-card-title">{item.fullname}</div>
                      <div className="username-card-para">{item.fonction}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <FrontFooter />
      </div>
    </>
  );
}

export default Apropos;
