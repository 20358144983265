import { Modal } from 'bootstrap'
import 'intl'
import 'intl/locale-data/jsonp/fr'
import $ from 'jquery'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Avatar from '../components/assets/appImage/avatar.png'
import Image from '../components/assets/appImage/ImageSimple.jpeg'
import Carte from '../components/assets/appImage/infocardidentiteImg.png'
import { useAppSelector } from '../redux/store'
import { ApiUrl } from './constants'

export const getImage = (img: string | any) => {
  if (
    img &&
    !img?.includes('default.png') &&
    !img?.includes('/mediafiles/mediafiles/')
  ) {
    if (img.indexOf('http') === 0) return img
    return ApiUrl + img
  }
  return Image
}
export const getImageCarte = (img: string | any) => {
  if (
    img &&
    !img?.includes('default.png') &&
    !img?.includes('/mediafiles/mediafiles/')
  ) {
    if (img.indexOf('http') === 0) return img
    return ApiUrl + img
  }
  return Carte
}

export const getAvatar = (avatar: string | any) => {
  if (avatar && !avatar?.includes('default.png')) {
    if (avatar.indexOf('http') === 0) return avatar
    return ApiUrl + avatar || Avatar
  }
  return Avatar
}

export function useLocationState<T>(
  defaultValue: T | any,
  redirectToWhenNull: string | null = null,
): T {
  const state = useLocation().state as T
  const navigate = useNavigate()
  useEffect(() => {
    if (!state && redirectToWhenNull) {
      navigate(redirectToWhenNull)
    }
  }, [state])
  return state || defaultValue
}

export const formatMontant = (num?: number | string) => {
  return formatAmout(num)
}

export function formatAmout(amount?: number | string, replace = 'F CFA') {
  if (amount || amount === 0)
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'CFA',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    })
      .format(parseFloat(String(amount)) || 0)
      .replace('CFA', replace)

  return '-'
}

export function openPopup(link: string) {
  return window.open(link, '_self', 'popup=yes')
}

export function useModalActions(modalId) {
  const [modal, setModal] = useState<any>(null)
  useEffect(() => {
    var myModal = new Modal(document.getElementById(modalId), {})
    if (document.readyState === 'complete') {
      setModal(myModal)
    }
    document.onreadystatechange = function () {
      setModal(myModal)
    }
  }, [])

  const show = () => {
    $('.modal-backdrop').css({ display: 'block' })
    modal?.show()
  }
  const hide = () => {
    modal?.hide()
    setTimeout(() => {
      $('.modal-backdrop').css({ display: 'none' })
      $('body').css({ overflow: 'scroll' })
    }, 500)
  }

  return { show, hide }
}

export function useUserId() {
  return useAppSelector((s) => s.user.user?.id as number)
}

export function formattedDate(date: string | Date) {
  return moment(date).format('DD/MM/YYYY')
}

export function cleannerError(
  errors: any,
  cleanner: any,
  timeOut: number = 3000,
) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => cleanner(key)),
      timeOut,
    )
  }
}

export function videoIdFromUrl(url?: string) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  var match = url?.match(regExp)
  return match && match[7].length == 11 ? match[7] : ''
}

export const createMarkup = (text) => {
  return { __html: text }
}

export const onHide = (modalId) => {
  document.getElementById(modalId)?.classList.remove('show')
  $('.modal-backdrop').remove()
  $(`#${modalId}`).hide()
  $(`#${modalId}`).click()
  $(`body`).css({ overflow: 'scroll' })
}
