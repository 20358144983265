import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UserState } from "../../../redux/slices/user.slice";
import { ApiUrl } from "../../constants";
import { AppStorage } from "../../storage";
import { LoginResult } from "../auth/auth.type";
import { PaginationResults, QueryUrl } from "../common.api";
import { PasswordType, SharePoint, User } from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
  const token =
    (getState() as { user: LoginResult })?.user?.token ??
    AppStorage.getItem<UserState>("user", {})?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

function getMeUrl() {
  const user = AppStorage.getItem<UserState>("user", {})?.user;
  if (user) {
    if (user.user_type === "admin" && !user.is_superuser) {
      return `/adminuser/${user?.slug}/`;
    }
    return `/user/${user?.id}/`;
  }
  return "/user/0/";
}

function getUpdateUserRoute() {
  return getMeUrl();
}

type DashboardState = {
  nb_produit: number;
  nb_commandes: number;
  nb_commandes_livrees: number;
  data: { day: string; achat_count: number }[];
};

type DashboardAdminState = {
  nb_produit: number;
  nb_commandes: number;
  nb_user: number;
  nb_projet: number;
  amount_invest: number;
  data: { user: User; montant: number }[];
  amount_achat: number;
};

type DashboardAdminFilter = {
  date_debut?: string;
  date_fin?: string;
};

export const UserApi = createApi({
  tagTypes: ["user", "sellers", "users", "user_filter", "shared", "state"],
  reducerPath: "userApi",

  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    me: builder.query<User, void>({
      providesTags: ["user"],
      query: getMeUrl,
    }),
    dashboardByCommercant: builder.query<DashboardState, void>({
      providesTags: ["state"],
      query: () => `/dashboardbycommercant/`,
    }),
    dashboardByAdmin: builder.query<DashboardAdminState, DashboardAdminFilter>({
      providesTags: ["state"],
      query: (query) => QueryUrl(`/dashboardbyadmin/`, query),
    }),
    sellers: builder.query<User[], void>({
      providesTags: ["sellers"],
      query: () => `/commercant/`,
    }),
    userByFilter: builder.query<User[], { word: string }>({
      providesTags: ["user_filter"],
      query: (query) => QueryUrl(`/investandsellerbyfilter/`, query),
    }),
    partageByFilter: builder.query<
      SharePoint[],
      { sender?: number; receiver?: number }
    >({
      providesTags: ["shared"],
      transformResponse: ({ results }) => results,
      query: (query) => QueryUrl(`/partagebyfilter/`, query),
    }),
    userEdit: builder.mutation<User, User | any>({
      invalidatesTags: ["user"],
      query: (data) => {
        return {
          url: getUpdateUserRoute(),
          method: "PUT",
          body: data,
        };
      },
    }),
    updateUser: builder.mutation<
      User,
      { id: number; data: Pick<User, "point"> }
    >({
      invalidatesTags: ["user", "sellers", "users", "user_filter"],
      query: ({ id, data }) => {
        return {
          url: `/user/${id}/`,
          method: "PUT",
          body: data,
        };
      },
    }),
    passwordEdit: builder.mutation<any, PasswordType | any>({
      invalidatesTags: ["user"],
      query: (data) => ({
        url: `/changepassword/`,
        method: "PUT",
        body: data,
      }),
    }),

    getUsers: builder.query<
      PaginationResults<User>,
      { page: number; word: string }
    >({
      query: (query) => QueryUrl("/userbyfilteradmin/", query),
      providesTags: ["users"],
    }),
    sharePoint: builder.mutation<SharePoint, SharePoint>({
      query: (data) => ({
        url: `/partage/`,
        method: "POST",
        body: data,
      }),

      invalidatesTags: ["users", "user", "shared"],
    }),
    deleteUser: builder.mutation<User, string | number>({
      query: (slug) => ({
        url: `/user/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["users"],
    }),
  }),
});

export const {
  useUserEditMutation,
  usePasswordEditMutation,
  useSellersQuery,
  useMeQuery,
  useSharePointMutation,
  useDeleteUserMutation,
  useUserByFilterQuery,
  usePartageByFilterQuery,
  useDashboardByCommercantQuery,
  useDashboardByAdminQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
} = UserApi;
