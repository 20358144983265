import { UserState } from "../../../../redux/slices/user.slice";
import { Produit } from "../../../../utils/api/produit/produit.type";
import { AppStorage } from "../../../../utils/storage";
import { formatMontant, getImage } from "../../../../utils/utils";
interface PropsType {
  navigation: any;
  item: Produit;
}

export function calculatePrices(item: Produit) {
  const user = AppStorage.getItem<UserState>("user", { user: {} }).user;
  const poi = parseFloat(String(item.point || 0)) || 0;
  const point = parseFloat(String(user?.point || 0)) || 0;
  const qte = item.quantity || 1;
  const qte_discount = item.discount
    ? parseFloat(
        String(poi * qte <= point ? qte : parseInt(String(point / poi)))
      ) || 0
    : 0;
  const total_point = qte_discount * item.point;
  const normalPrice = parseFloat(String(item.prix || 0));
  const price_discount = item.discount
    ? parseFloat(String(item.price_discount || 0)) || 0
    : 0;
  const discount_total =
    qte_discount > 0 ? (normalPrice - price_discount) * qte_discount : 0;
  const sub_total = normalPrice * qte;
  const total = sub_total - discount_total;
  return {
    total,
    sub_total,
    discount_total,
    normalPrice,
    total_point,
    qte,
    qte_discount,
  };
}
const ShoppingCartDetail = ({ navigation, item }: PropsType) => {
  const { total, sub_total, discount_total, normalPrice, total_point, qte } =
    calculatePrices(item);

  return (
    <div className="px-2 py-4">
      <div className="maajjal-card p-2 mb-3  d-flex flex-column gap-3 gap-sm-5">
        <div className="modal-shop-img-container flex-c">
          <img
            src={getImage(item?.avatar)}
            alt="product"
            style={{ width: "100%", maxHeight: "320px", height: "auto" }}
            className="modal-shop-img"
          />
        </div>
        <div className="d-flex justify-content-between flex-fill">
          <div className="shop-cart-desc">
            <h5>Produit</h5>
            <h6>{item?.name}</h6>
          </div>
          <div className="shop-cart-desc">
            <h5>Prix</h5>
            <h6>{formatMontant(normalPrice)}</h6>
          </div>
          <div className="shop-cart-desc">
            <h5>Quantité</h5>
            <h6>{qte}</h6>
          </div>
        </div>
      </div>
      <div className="maajjal-card mb-3 flex-r">
        <div className="px-2 py-3 w-100 w-sm-70">
          <div className="semi-total-shop">
            <h5 className="">Sous-total</h5>
            <h6 className="">{sub_total}</h6>
          </div>
          {!!item.discount && (
            <div className="semi-total-shop">
              <h5 className="">
                Discount -({`${item?.discount}%`}) ({total_point} pts)
              </h5>
              <h6 className="">{formatMontant(discount_total)}</h6>
            </div>
          )}
        </div>
      </div>
      <div className="maajjal-card flex-r py-2 px-2">
        <div className="flex-rc gap-5 total-price-shop">
          <h5 className="">Total</h5>
          <h6 className="">{formatMontant(total)}</h6>
        </div>
      </div>
      <div className="modal-btn-container mt-5 flex-r gap-5">
        <button
          type="button"
          className="btn-shop btn-shop-cancel"
          data-bs-dismiss="modal"
        >
          Annuler
        </button>
        <button
          type="button"
          className="btn-shop-next btn-shop"
          onClick={navigation.next}
        >
          Confirmer
        </button>
      </div>
    </div>
  );
};

export default ShoppingCartDetail;
