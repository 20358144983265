import { useDashboardByAdminQuery } from "../../../../utils/api/user/user.api";
import DashboardTable from "../../Tables/admin/dashboard/DashboardTable";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import "./ListeDonnateursAdmin.css";

function ListeDonnateurAdmin() {
  const {
    data = {
      data: [],
    },
    isLoading,
  } = useDashboardByAdminQuery({});
  return (
    <div className="parametre-page">
      <TopBar index={7} />
      <SideBarAdmin index={0} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="maajjal-card maajjal-admin-card p-4 p-xxl-3">
            <div className="col-md-12">
              <h3 className="tb-mes-projet-titre mb-4">Liste des donnateurs</h3>
            </div>
            <DashboardTable data={data.data} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListeDonnateurAdmin;
