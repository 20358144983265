import { useEffect, useRef, useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { useCategory_productQuery } from "../../../../utils/api/categorie/categorie.api";
import { useSellersQuery } from "../../../../utils/api/user/user.api";
import GoBackBtn from "../../../shared/GoBackBtn";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import UseAddProductForm from "./requestProduct/UseAddProductForm";

function AjoutProduitAdmin() {
  const { register, setValue, formState, onSubmit, isLoading } =
    UseAddProductForm();
  const { data: categories = [] } = useCategory_productQuery();
  const { data: commercants = [] } = useSellersQuery();

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const iconStyle = {
    fontSize: "18",
    color: "#5a5a5a",
    opacity: 0.3,
  };

  useEffect(() => {
    if (commercants) {
      console.log(commercants);
    }
  }, [commercants]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl: any = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
    console.log("avatar", e.target.files);
    register("avatar");
    setValue("avatar", e.target.files[0]);
  };

  return (
    <div className="parametre-page">
      <TopBar index={3} />
      <SideBarAdmin index={3} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="p4 p-xxl-3">
            <GoBackBtn
              className="mad-donnateur-infos-perso-boutton-back"
              label="Retour"
            />

            <div className="row maajjal-table-card  px-3 py-4 ml-2 mr-3">
              <div className="col-xl-8 offset-xl-2 add-update-form-container">
                <form className="add-update-form" onSubmit={onSubmit}>
                  <div className="row add-update-form-row">
                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="product-name"
                          className="majjal-label mb-2"
                        >
                          Nom du produit <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control-majjal form-control form-control-lg"
                          id="product-name"
                          {...register("name")}
                          placeholder="Nom du produit"
                        />
                        {formState?.errors?.name && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {formState?.errors?.name?.message
                              ?.toString()
                              ?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="desc-product"
                          className="majjal-label mb-2"
                        >
                          Description produit
                          <span className="text-danger">*</span>
                        </label>
                        <HtmlInput
                          data=""
                          name="description"
                          onChange={(data) => {
                            setValue("description", data);
                          }}
                        />

                        {formState?.errors?.description && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {formState?.errors?.description?.message
                              ?.toString()
                              ?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="commercant"
                          className="majjal-label mb-2"
                        >
                          Commerçant<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <select
                            {...register("commercant")}
                            id="commercant"
                            className="form-control-majjal form-select-majjal"
                          >
                            <option value="">Commerçant</option>
                            {commercants?.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.first_name + " " + item?.last_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {formState?.errors?.commercant && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {formState?.errors?.commercant?.message
                              ?.toString()
                              ?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label htmlFor="category" className="majjal-label mb-2">
                          Categorie<span className="text-danger">*</span>
                        </label>
                        <div className="position-relative">
                          <select
                            {...register("category")}
                            id="category"
                            className="form-control-majjal form-select-majjal"
                          >
                            <option value="">Catégorie</option>
                            {categories?.map((category) => (
                              <option value={category?.id} key={category?.id}>
                                {category?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {formState?.errors?.category && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {formState?.errors?.category?.message
                              ?.toString()
                              ?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form-input-col">
                      <label
                        htmlFor="photo-identite-recto"
                        className=" ajt-produit-img-label"
                      >
                        Image produit <span className="text-danger">*</span>
                      </label>
                      <label
                        htmlFor="photo-identite-recto"
                        className="auth-identite-file flex-sb"
                      >
                        <span>Photo</span>
                        <GrAttachment
                          className="auth-file-input-icon"
                          style={iconStyle}
                        />
                      </label>
                      <input
                        type="file"
                        className="op-0 h-0 imgInp"
                        placeholder="Image produit"
                        id="photo-identite-recto"
                        onChange={handleChange}
                        accept="image/*"
                        name="recto_cni"
                      />
                      {formState?.errors?.avatar && (
                        <div className="alert alert-danger sia-alert-danger closer mt-2">
                          {formState?.errors?.avatar?.message
                            ?.toString()
                            ?.toString()}
                        </div>
                      )}
                      {selectedFile && (
                        <div className="img-preview-container">
                          <img src={preview} className="cni-img-preview" />
                        </div>
                      )}
                    </div>

                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label htmlFor="price" className="majjal-label mb-2">
                          Prix <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control-majjal form-control form-control-lg"
                          id="price"
                          {...register("prix")}
                          placeholder="Prix du produit"
                        />
                        {formState?.errors?.prix && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {formState?.errors?.prix?.message
                              ?.toString()
                              ?.toString()}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label htmlFor="point" className="majjal-label mb-2">
                          Point ( 10f = 1 point )
                        </label>
                        <input
                          type="number"
                          min={0}
                          className="form-control-majjal form-control form-control-lg bg-slate"
                          id="point"
                          {...register("point")}
                          placeholder="Point du produit"
                        />
                        {formState?.errors?.point && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {formState?.errors?.point?.message
                              ?.toString()
                              ?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 form-input-col">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="discount=percentage"
                          className="majjal-label mb-2"
                        >
                          Pourcentage discount
                        </label>
                        <input
                          type="number"
                          min={0}
                          className="form-control-majjal form-control form-control-lg bg-slate"
                          id="discount=percentage"
                          {...register("discount")}
                          placeholder="pourcentage"
                        />
                        {formState?.errors?.discount && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {formState?.errors?.discount?.message
                              ?.toString()
                              ?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 submit-input-col">
                      <div className="submit-btn-container">
                        {isLoading && (
                          <button disabled className="btn-submit">
                            En cours&nbsp;{" "}
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                        {!isLoading && (
                          <button className="btn-submit" type="submit">
                            Ajouter
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AjoutProduitAdmin;

export function HtmlInput({
  onChange,
  data = "",
  name = "description",
}: {
  onChange: any;
  name: string;
  data: any;
}) {
  const editor = useRef();
  useEffect(() => {
    // @ts-ignore
    if (!editor.current && window.ClassicEditor?.create) {
      // @ts-ignore
      editor.current = { setData: () => null, getData: () => null };
      // @ts-ignore
      window.ClassicEditor.create(document.querySelector(`#${name}`), {
        initialData: data,
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "imageUpload",
            "blockQuote",
            "mediaEmbed",
            "undo",
            "redo",
          ],
        },
        language: "en",
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
          ],
        },
        licenseKey: "",
      })
        .then((ed) => {
          const modelDocument = ed.model.document;
          modelDocument.on("change:data", (event) => {
            const data = ed.getData();
            onChange(data);
          });
          editor.current = ed;
        })
        .catch((err) => {
          console.error(err.stack);
        });
    }
  }, [editor.current]);

  useEffect(() => {
    if (editor.current && data) {
      // @ts-ignore
      const ed = editor.current.getData();
      if (!ed && data) {
        // @ts-ignore
        editor.current.setData(data);
      }
    }
  }, [editor.current, data]);

  return <div id={name} />;
}
