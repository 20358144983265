import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiUrl } from "../../constants";
import { PaginationResults, QueryUrl } from "../common.api";
import { prepareHeaders } from "../user/user.api";
import { Faq, FaqFormData } from "./faq.type";

const FaqApi = createApi({
  reducerPath: "faq",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["faq"],
  endpoints: (build) => ({
    getFaq: build.query<PaginationResults<Faq>, { page: number }>({
      query: (query) => QueryUrl("/faqbyvisitor/", query),
      providesTags: ["faq"],
    }),
    faqByProjet: build.query<PaginationResults<Faq>, string>({
      query: (slug) => `/faqbyprojet_slug/${slug}/`,
      providesTags: (r) => ["faq"],
    }),
    faqIncrement: build.query<Faq, number>({
      query: (id) => `/faqincrement/${id}/`,
    }),
    responseByFaq: build.mutation<Faq, FaqFormData | any>({
      query: (data) => ({
        url: `/response/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["faq"],
    }),
    addQuestion: build.mutation<Faq, FaqFormData | any>({
      query: (data) => ({
        url: `/faq/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["faq"],
    }),
    deleteFaq: build.mutation<Faq, number>({
      query: (id) => ({
        url: `/faq/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["faq"],
    }),
  }),
});

export const {
  useGetFaqQuery,
  useAddQuestionMutation,
  useFaqByProjetQuery,
  useLazyFaqByProjetQuery,
  useResponseByFaqMutation,
  useDeleteFaqMutation,
  useLazyFaqIncrementQuery,
} = FaqApi;

export default FaqApi;
