import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { GrAttachment } from "react-icons/gr";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../redux/store";
import { useUserEditMutation } from "../../../../utils/api/user/user.api";
import { UserFormData } from "../../../../utils/api/user/user.type";
import { COLORS } from "../../../../utils/constants";
import { getAvatar, getImage } from "../../../../utils/utils";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import { IconEdit } from "../../../shared/Icons";
import Sidebar from "../Sidebar/Sidebar";
import EditPassword from "./EditPassword";
import "./MonCompte.css";

const MonCompte = () => {
  return (
    <div className="campagne-page-container">
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={4} />
          <div className="col-md-8 col-lg-9 col-xl-10">
            <div className="maajjal-card  m-tableau-bord py-5">
              <AccountContainer />
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default MonCompte;

export function AccountContainer() {
  const [index, setIndex] = useState(0);
  const go = (index: number) => setIndex(index);
  return (
    <>
      <div className="dashboard-commandes-tab-container">
        <button
          onClick={() => go(0)}
          className={`stepper-commandes-tab ${
            index === 0 && "stepper-active-commandes-tab"
          }`}
        >
          Information personnelles
        </button>
        <button
          onClick={() => go(1)}
          className={`stepper-commandes-tab ${
            index === 1 && "stepper-active-commandes-tab"
          }`}
        >
          Mot de passe
        </button>
      </div>
      {index === 0 && <Account />}
      {index === 1 && <PasswordSection />}
    </>
  );
}

function Account() {
  const user = useAppSelector((s) => s.user);
  const [file, setFile] = useState(getAvatar(user.user?.avatar));

  const [sendData, { isSuccess, isLoading, data }] = useUserEditMutation();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        first_name: yup.string().required("Le prénom est requis"),
        last_name: yup.string().required("Le nom est requis"),
        date_naissance: yup.string().required("La Date est requise"),
        phone: yup.string().nullable(),
        adresse: yup.string().nullable(),
        civilite: yup.string().nullable(),
      }),
    []
  );
  const { register, setValue, formState, clearErrors, handleSubmit } =
    useForm<UserFormData>({
      resolver: yupResolver(validationSchema),
    });

  const [selectedFileRecto, setSelectedFileRecto] = useState<string>();
  const [selectedFileVerso, setSelectedFileVerso] = useState<string>();

  const iconStyle = {
    fontSize: "18",
    color: "#5a5a5a",
    opacity: 0.3,
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    setSelectedFileRecto(URL.createObjectURL(file));
    register("recto_cni");
    setValue("recto_cni", file);
  };
  const handleChangeVersoCni = (e) => {
    const file = e.target.files[0];
    setSelectedFileVerso(URL.createObjectURL(file));
    register("verso_cni");
    setValue("verso_cni", file);
  };
  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];
    register("avatar");
    setValue("avatar", file);
    setFile(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (user?.user?.id) {
      setValue("id", user?.user?.id);
      setValue("slug", user?.user?.slug);
      setValue("first_name", user?.user?.first_name || "");
      setValue("last_name", user?.user?.last_name || "");
      setValue("phone", user?.user?.phone || "");
      setValue("date_naissance", user?.user?.date_naissance || "");
      setValue("civilite", user?.user?.civilite || "");
      setValue("numero_cni", user?.user?.numero_cni || "");

      if (user?.user?.recto_cni) {
        setSelectedFileRecto(getImage(user?.user?.recto_cni));
      }
      if (user?.user?.verso_cni) {
        setSelectedFileVerso(getImage(user?.user?.verso_cni));
      }
    }
  }, [user]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          console.log(key);
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (data && isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Modification réussie!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        setTimeout(() => {}, 1050);
      });
    }
  }, [data, isSuccess]);

  const onSubmit = async (data) => {
    let fd: any = new FormData();
    Object.keys(data).map((key) => {
      fd.append(key, data[key]);
    });
    await sendData(fd);
  };

  return (
    <form className="" onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column">
        <div className="d-flex flex-column align-items-center avatar-container">
          <label htmlFor="photo-profil">
            <img
              src={file}
              alt="avatar-user"
              className="rounded-circle"
              style={{ width: "150px", height: "150px" }}
            />
          </label>
          <input
            type="file"
            id="photo-profil"
            className="op-0"
            title=""
            onChange={handleChangeAvatar}
          />
        </div>
        <div className="d-flex flex-column align-items-center">
          <h1 className="user-name">
            {user?.user?.first_name + " " + user?.user?.last_name}
          </h1>
          {/* <h6 className="register-date">
            {`Inscrite ${moment(user?.user?.date_joined).fromNow()}`}
          </h6> */}
        </div>
      </div>
      <div className="mon-compte-container mt-5 profile-form profil-admin">
        <div className="profil-admin-form-row row">
          <div className="col-md-8 offset-md-2 profil-admin-form-col">
            <div className="form-row row">
              <div className="col-md-12 account-form-col">
                <h3 className="title-change-password mb-3">
                  Informations personnelles
                </h3>
                <div className="form-group">
                  <label htmlFor="civilite">Civilité</label>
                  <div className="position-relative">
                    <select
                      id="civilite"
                      className="form-profile-select form-control"
                      {...register("civilite")}
                    >
                      <option value="">--Choisir--</option>
                      <option value="femme">Madame</option>
                      <option value="homme">Monsieur</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row row">
              <div className="col-md-12 account-form-col">
                <div className="form-group">
                  <label htmlFor="nom">Nom</label>
                  <div className="form-profile-input-group">
                    <input
                      type="text"
                      className="form-profile-control form-control"
                      placeholder="Ndiaye"
                      id="nom"
                      {...register("last_name")}
                    />
                    <div className="icon-edit-container">
                      <IconEdit />
                    </div>
                    {formState?.errors.last_name && (
                      <div className="alert alert-danger sia-alert-danger closer">
                        {formState?.errors.last_name?.message?.toString()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 account-form-col">
                <div className="form-group">
                  <label htmlFor="prenom">Prénom</label>
                  <div className="form-profile-input-group">
                    <input
                      type="text"
                      className="form-profile-control form-control"
                      placeholder="prenom"
                      id="Mary"
                      {...register("first_name")}
                    />
                    <div className="icon-edit-container">
                      <IconEdit />
                    </div>
                    {formState?.errors.first_name && (
                      <div className="alert alert-danger sia-alert-danger closer">
                        {formState?.errors.first_name?.message?.toString()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row row">
              <div className="col-md-12 account-form-col">
                <div className="form-group">
                  <label htmlFor="tel">Numéro de téléphone</label>
                  <div className="form-profile-input-group">
                    <input
                      type="tel"
                      className="form-profile-control form-control"
                      placeholder="77 000 00 00"
                      id="tel"
                      {...register("phone")}
                    />
                    <div className="icon-edit-container">
                      <IconEdit />
                    </div>
                    {formState?.errors.phone && (
                      <div className="alert alert-danger sia-alert-danger closer">
                        {formState?.errors.phone?.message?.toString()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row row">
              <div className="col-md-12 account-form-col">
                <div className="form-group">
                  <label htmlFor="tel">Date de naissance</label>
                  <div className="form-profile-input-group">
                    <input
                      type="date"
                      className="form-profile-control form-control"
                      placeholder="Date de naissance"
                      id="date_naissance"
                      {...register("date_naissance")}
                      data-testid="date_naissanceId"
                    />
                    {formState?.errors.date_naissance && (
                      <div className="alert alert-danger sia-alert-danger closer">
                        {formState?.errors.date_naissance?.message?.toString()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row row">
              <div className="col-md-12 account-form-col">
                <div className="form-group">
                  <label htmlFor="tel">Pièce d'identité</label>
                  <div className="form-profile-input-group">
                    <input
                      type="text"
                      className="form-profile-control form-control"
                      placeholder="Numéro d’identité (CNI et Passeport)"
                      id="numero_cni"
                      {...register("numero_cni")}
                      data-testid="numero_cniId"
                    />
                    <div className="icon-edit-container">
                      <IconEdit />
                    </div>
                    {formState?.errors.numero_cni && (
                      <div className="alert alert-danger sia-alert-danger closer">
                        {formState?.errors.numero_cni?.message?.toString()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label
                  htmlFor="photo-identite-recto"
                  className="auth-identite-file flex-sb"
                >
                  <span>Photo d’identité recto</span>
                  <GrAttachment
                    className="auth-file-input-icon"
                    style={iconStyle}
                  />
                </label>
                <input
                  type="file"
                  className="op-0 h-0 imgInp"
                  placeholder="Photo d’identité recto"
                  id="photo-identite-recto"
                  onChange={handleChange}
                  accept="image/*"
                  name="recto_cni"
                />
                {!!selectedFileRecto && (
                  <div className="img-preview-container">
                    <img src={selectedFileRecto} className="cni-img-preview" />
                  </div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label
                  htmlFor="photo-identite-verso"
                  className="auth-identite-file flex-sb"
                >
                  <span>Photo d’identité recto</span>
                  <GrAttachment
                    className="auth-file-input-icon"
                    style={iconStyle}
                  />
                </label>
                <input
                  type="file"
                  className="op-0 h-0 imgInp"
                  placeholder="Photo d’identité verso"
                  id="photo-identite-verso"
                  // onChange={handleChange}
                  onChange={handleChangeVersoCni}
                  accept="image/*"
                  name="verso_cni"
                />
                {!!selectedFileVerso && (
                  <div className="img-preview-container">
                    <img src={selectedFileVerso} className="cni-img-preview" />
                  </div>
                )}
              </div>
            </div>
            <div className="btn-profile-container mt-5">
              {isLoading && (
                <button disabled className="btn btn-profile-submit">
                  Modification&nbsp; <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
              {!isLoading && (
                <button type="submit" className="btn-profile-submit">
                  Enregistrer
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

function PasswordSection() {
  return (
    <div className="mon-compte-container mt-5 profile-form profil-admin">
      <div className="profil-admin-form-row row">
        <div className="col-md-8 offset-md-2 profil-admin-form-col">
          <EditPassword />
        </div>
      </div>
    </div>
  );
}
