import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRoutePath } from "../../../components/routes/routes";
import { ApiUrl } from "../../constants";
import { useLocationState } from "../../utils";
import { PaginationResults, QueryUrl } from "../common.api";
import { prepareHeaders } from "../user/user.api";
import { Commentaire, CommentaireFormData } from "./commentaire.type";

type CommentByProjetQuery = {
  slug: string;
  page: number;
};
const CommentaireApi = createApi({
  reducerPath: "listCommentaire",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["listComment"],

  endpoints: (build) => ({
    commentsByprojet: build.query<
      PaginationResults<Commentaire>,
      CommentByProjetQuery
    >({
      query: ({ slug, ...rest }) =>
        QueryUrl(`/commentbyprojet_slug/${slug}`, rest),
      providesTags: ["listComment"],
    }),
    commentBySlug: build.query<Commentaire, string>({
      query: (slug) => `/comment_slug/${slug}/`,
      providesTags: ["listComment"],
    }),
    addComment: build.mutation<Commentaire, CommentaireFormData | any>({
      query: (data) => ({
        url: `/comment/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["listComment"],
    }),
    updateComment: build.mutation<Commentaire, CommentaireFormData>({
      query: ({ id, slug, ...data }) => ({
        url: `/comment_slug/${id}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["listComment"],
    }),
  }),
});

export const { useAddCommentMutation, useCommentsByprojetQuery, useUpdateCommentMutation, useCommentBySlugQuery, useLazyCommentBySlugQuery } =
  CommentaireApi;

export default CommentaireApi;

export function useCommentaireFromLocation(): [
  Commentaire,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<Commentaire | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] = useLazyCommentBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!itemState) {
      findBySlug(slug as any);
    }
  }, [itemState]);
  useEffect(() => {
    if (isError) {
      navigate(getRoutePath("home"));
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return [item as Commentaire, isLoading, slug as string, findBySlug as any];
}


