import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAddAvisMutation } from "../../../../utils/api/avis/avis.api";
import { AvisFormData } from "../../../../utils/api/avis/avis.type";
import { COLORS } from "../../../../utils/constants";
const exts = ["png", "jpeg", "gif"];
function useAvisForm(onHide: () => any, slug?: string) {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        fullname: yup.string().required("Prénom et nom est requis"),
        fonction: yup.string().required("Fonction est requis"),
        avis: yup.string().required("Avis est requis"),
        avatar: slug
          ? yup.mixed()
          : yup
              .mixed()
              .required("L'image est  requise.")
              .label("Image")
              .test(
                "file-type",
                `Format de fichier accepté: (${exts.join(", ")})`,
                (value: FileList | any) => {
                  if (value) {
                    const file: File =
                      value instanceof FileList ? value[0] : value;
                    if (!file) return false;
                    const name = typeof file === "string" ? file : file.name;
                    const parts = name.split(".");
                    const ext = parts[parts.length - 1].toLowerCase();
                    return exts.includes(ext);
                  }
                  return true;
                }
              ),
      }),
    []
  );

  const [sendData, { isSuccess, isError, isLoading, error }] =
    useAddAvisMutation();

  const { register, handleSubmit, formState, clearErrors, setValue, setError } =
    useForm<AvisFormData>({
      resolver: yupResolver(validationSchema),
    });

  const onSubmit = async (data: AvisFormData) => {
    const fd = new FormData();
    Object.keys(data).map((key) => fd.append(key, data[key]));
    //@ts-ignore
    await sendData({ slug, data: fd });
  };

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Données non ajoutées!",
        iconColor: COLORS.red,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: slug
          ? "Données modifiées avec succès!"
          : "Données ajoutées avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      });
      onHide();
    }
  }, [isSuccess]);

  return {
    register,
    formState,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    isSuccess,
    isLoading,
    error,
    isError,
    setValue,
    setError,
  };
}

export default useAvisForm;
