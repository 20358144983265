import {
  addProduit,
  deleteProduit,
} from "../../../../../redux/slices/produit.slice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { Produit } from "../../../../../utils/api/produit/produit.type";
import { CartIcon } from "../../../../shared/Icons";

function BtnAjouterAuPanier({ produit }) {
  const [inCart, _, handleSelectProduit] = useCartAction(produit);
  return (
    <button
      className={`card-market-btn flex-cc gap-2 ${
        inCart && "card-market-btn-out"
      }`}
      onClick={() => handleSelectProduit()}
      {...(inCart
        ? {}
        : { "data-bs-toggle": "modal", "data-bs-target": "#notifmodal" })}
    >
      <CartIcon {...(inCart ? { color: "white", opacity: 1 } : {})} />
      {inCart ? "Retirer au panier" : "Ajouter au panier"}
    </button>
  );
}

export default BtnAjouterAuPanier;
export function useCartAction(
  produit
): [
  boolean,
  Produit | undefined,
  (maj?: boolean, inc?: boolean, dec?: boolean) => any
] {
  const inCart = useAppSelector((s) =>
    s.produit.find((s) => s.id === produit?.id)
  );
  const dispatch = useAppDispatch();
  const handleSelectProduit = (maj = true, inc = false, dec = false) => {
    if (produit) {
      const newP = inCart ? { ...inCart } : undefined;
      if (maj) {
        if (newP) {
          dispatch(deleteProduit(produit));
        } else {
          dispatch(addProduit(produit));
        }
      } else {
        if (newP) {
          let quantity = newP.quantity || 0;
          if (inc) {
            quantity++;
          } else if (dec) {
            quantity--;
          }
          if (quantity <= 0) {
            dispatch(deleteProduit(newP));
          } else {
            newP.quantity = quantity;
            dispatch(addProduit(newP));
          }
        } else {
          if (inc) {
            dispatch(addProduit({ ...produit, quantity: 2 }));
          }
        }
      }
    }
  };
  return [!!inCart, inCart, handleSelectProduit];
}
