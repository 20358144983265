import { useAppSelector } from "../../../redux/store";
import { EmptyMessage } from "../../../utils/emptyMessage";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import "./MyShoppingCart.css";
import ShoppingCartItem from "./ShoppingCartItem";

const MyShoppingCart = () => {
  const produitCart = useAppSelector((p) => p.produit);
  return (
    <div className="my-cart-component">
      <FrontHeader />
      <div className="margin-container custom-container">
        <div className="maajjal-card custom-container py-4 px-3 ">
          <h1 className="my-cart-title">Mon panier</h1>
          <hr className="divide-maajjal" />
          <div className="shopping-cart-container pt-3">
            {produitCart?.length > 0
              ? produitCart.map((item, index) => {
                  return <ShoppingCartItem key={index} item={item} />;
                })
              : EmptyMessage("Le panier est vide")}
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default MyShoppingCart;
