import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useAddProduitMutation } from '../../../../../utils/api/produit/produit.api'
import { ProduitFormData } from '../../../../../utils/api/produit/produit.type'
import { COLORS } from '../../../../../utils/constants'

function UseAddProductForm() {
  const navigate = useNavigate()
  const validationSchema: any = useMemo(() => (
      yup.object().shape({
        name: yup.string().required('Ce champ est requis').label('Le nom du produit').nullable(),
        category: yup
          .string()
          .required()
          .label('La catégorie du produit')
          .nullable(),

        commercant: yup.string().required().label('Le commerçant').nullable(),
        description: yup
          .string()
          .required()
          .label('La description du produit')
          .nullable(),
        prix: yup.string().required().label('Le prix du produit').nullable(),

        avatar: yup.mixed().required().label("L'image").nullable(),

        point: yup.string().nullable().nullable(),

        discount: yup.string().label('Le modèle').nullable(),
      })
  ), [])
  

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    clearErrors,
    setError,
  } = useForm<ProduitFormData | any>({
    resolver: yupResolver(validationSchema),
  })
  const [
    sendData,
    { isLoading, isSuccess, isError, error, data },
  ] = useAddProduitMutation()
  useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        Object.entries(formState?.errors).map(([key]: any) => {
          window.scrollTo(10, 10)
          clearErrors(key)
        })
      }, 5000)
    }
  }, [formState])

  useEffect(() => {
    if (isError) {
      console.log('err', error)
      const err = error as any
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {}
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      console.log('success', data)
      Swal.fire({
        icon: 'success',
        title: 'Produit ajouté avec succès!',
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate('/admin/produits', { replace: false })
      })
    }
  }, [isSuccess])
  const onSubmit = async (formValues) => {
    console.log(formValues)

    let data = formValues
    const fd = new FormData()

    for (let key of Object.keys(data)) {
      const val = data[key]
      fd.append(key, val)
    }

    await sendData(fd)
  }
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    formState,
    setValue,
    isLoading,
    setError,
  }
}

export default UseAddProductForm
