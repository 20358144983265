import { IoMdCheckmarkCircle } from "react-icons/io";

const NotificationModal = () => {
  return (
    <div className="modal fade shopping-cart-modal" id="notifmodal">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content d-flex align-items-center justify-content-center py-1">
          <IoMdCheckmarkCircle
            style={{
              color: "#0FBF56",
              fontSize: 30,
            }}
          />
          <h6 className="notif-modal-text mt-1">
            Produit bien ajouté au panier
          </h6>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
