import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { COLORS } from "../../../../utils/constants";
import { useLocationState } from "../../../../utils/utils";
import PhoneInput from "../../../shared/PhoneInput";
import "../common/Auth.css";
import AuthPanel from "../common/AuthPanel";
import AuthSocialLogin from "../common/AuthSocialLogin";
import UseRegisterForm from "../UseAuthForm/UseRegisterForm";

export function usePhoneHandler(
  register,
  setValue,
  defaultValue,
  name = "phone"
) {
  const [phone, setPhone] = React.useState(defaultValue);
  useEffect(() => {
    register(name);
  }, []);

  useEffect(() => {
    setValue(name, phone);
  }, [phone]);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return [phone, setPhone];
}

const Register: React.FC = () => {
  const {
    register,
    onSubmit,
    clearErrors,
    formState,
    isSuccess,
    isLoading,
    error,
    isError,
    setValue,
    setError,
    // fail,
    // isFailed
  } = UseRegisterForm();
  const initState = useLocationState<{ phone: string }>(null);
  const [phone, setPhone] = usePhoneHandler(
    register,
    setValue,
    initState?.phone || ""
  );

  const navigate = useNavigate();

  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);

  const countUppercase = (str) => {
    if (str.replace(/[^A-Z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str) => {
    if (str.replace(/[^a-z]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str) => {
    if (str.replace(/[^0-9]/g, "").length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str) => {
    if (str.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str) => {
    const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e) => {
    var password = e.target.value;
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("password", e.target.value);
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    // console.log("response", isSuccess, isLoading, isError, error);
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Inscription réussie!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/se-connecter");
      });
    }

    if (isError) {
      const err = error as any;
      if (
        err?.data?.error?.email?.length &&
        err?.data?.error?.email[0] === "user with this email already exists."
      ) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déja associé à un compte.",
          showConfirmButton: true,
          //   timer: 2000,
        });
      }
    }
  }, [isSuccess, isError, error]);

  const [selectedFileRecto, setSelectedFileRecto] = useState();
  const [selectedFileVerso, setSelectedFileVerso] = useState();
  const [previewRecto, setPreviewRecto] = useState();
  const [previewVerso, setPreviewVerso] = useState();

  useEffect(() => {
    if (!selectedFileRecto) {
      setPreviewRecto(undefined);
      return;
    }

    const objectUrlRecto: any = URL.createObjectURL(selectedFileRecto);
    setPreviewRecto(objectUrlRecto);

    return () => URL.revokeObjectURL(objectUrlRecto);
  }, [selectedFileRecto]);

  useEffect(() => {
    if (!selectedFileVerso) {
      setPreviewVerso(undefined);
      return;
    }

    const objectUrlVerso: any = URL.createObjectURL(selectedFileVerso);
    setPreviewVerso(objectUrlVerso);

    return () => URL.revokeObjectURL(objectUrlVerso);
  }, [selectedFileVerso]);

  const handleChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFileRecto(undefined);
      return;
    }

    setSelectedFileRecto(e.target.files[0]);
    console.log("recto_cni", e.target.files);
    register("recto_cni");
    setValue("recto_cni", e.target.files[0]);
  };
  const handleChangeVersoCni = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFileVerso(undefined);
      return;
    }

    setSelectedFileVerso(e.target.files[0]);
    console.log("verso_cni", e.target.files);
    register("verso_cni");
    setValue("verso_cni", e.target.files[0]);
  };

  return (
    <div className="auth-component">
      <div className="no-view-mobile">
        <div className="row empty-header"></div>
      </div>
      <div className="auth-container">
        <div className="auth-majjal-container">
          <div className="row">
            <AuthPanel />
            <div className="col-md-8 auth-panel-right px-4 py-2 auth-majjal-panel-right">
              <h1 className="auth-title">Inscription</h1>
              <AuthSocialLogin />
              <div className="auth-form-container mt-2">
                <div className="avertissement-champs-obligatoire text-danger">
                  (*) Les champs avec un astérix sont obligatoire
                </div>
                <form onSubmit={onSubmit}>
                  <div className="form-group mb-3">
                    <div className="row">
                      <div className="col-md-6 mb-3 mb-md-0 warning-asterix-content">
                        <span className="warning-asterix">*</span>
                        <input
                          type="text"
                          className="majjal-form-control form-control form-control-lg"
                          placeholder="Prénom"
                          {...register("first_name")}
                          data-testid="first_nameeId"
                        />
                        {formState.errors.first_name && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {formState.errors.first_name?.message?.toString()}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 warning-asterix-content">
                        <span className="warning-asterix">*</span>
                        <input
                          type="text"
                          className="majjal-form-control form-control form-control-lg"
                          placeholder="Nom"
                          {...register("last_name")}
                          data-testid="last_nameId"
                        />
                        {formState.errors.last_name && (
                          <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {formState.errors.last_name?.message?.toString()}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 warning-asterix-content">
                    <span className="warning-asterix">*</span>
                    <input
                      type="email"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Adresse mail"
                      {...register("email")}
                      data-testid="emailId"
                    />
                    {formState.errors.email && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {formState.errors.email?.message?.toString()}
                      </div>
                    )}
                  </div>
                  <PhoneInput
                    placeholder="Téléphone"
                    error={formState.errors.phone?.message?.toString()}
                    value={phone}
                    onChnage={setPhone}
                    name="phone"
                  />

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Adresse"
                      {...register("adress")}
                      data-testid="adressId"
                    />
                    {formState.errors.adress && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {formState.errors.adress?.message?.toString()}
                      </div>
                    )}
                  </div>
                  {/* <div className="form-group mb-3">
										<input
											type="text"
											className="majjal-form-control form-control form-control-lg"
											placeholder="Numéro d’identité (CNI et Passeport)"
											{...register("numero_cni")}
											data-testid="numero_cniId"
										/>
										{formState.errors.numero_cni &&
											<div className="alert alert-danger sia-alert-danger closer mt-2">
												{formState.errors.numero_cni?.message?.toString()}
											</div>
										}
									</div> */}

                  <div className="form-group mb-3 warning-asterix-content">
                    <span className="warning-asterix">*</span>
                    <input
                      type="password"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Mot de passe"
                      // {...register('password')}
                      data-testid="passwordId"
                      onChange={(e) => passwordHandleChange(e)}
                    />
                    {formState.errors.password && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {formState.errors.password?.message?.toString()}
                      </div>
                    )}
                  </div>

                  <div className="input-group my-2 password-validation-terms-row">
                    <div className="password-validation-terms-container">
                      <h4 className="password-validation-terms-title">
                        Votre mot de passe doit contenir :{" "}
                      </h4>
                      <ul className="password-validation-terms-ul">
                        <li
                          className={
                            "min-character-count pvt-li " +
                            (haveMinCharacter && "min-character-count-ok")
                          }
                        >
                          Au minimum 8 caractères
                        </li>
                        <li
                          className={
                            "min-character-uppercase pvt-li " +
                            (haveMinUppercase && "min-character-uppercase-ok")
                          }
                        >
                          Au minimum 1 caractère en majuscule
                        </li>
                        <li
                          className={
                            "min-character-lowercase pvt-li " +
                            (haveMinLowercase && "min-character-lowercase-ok")
                          }
                        >
                          Au minimum 1 caractère en minuscule
                        </li>
                        <li
                          className={
                            "min-character-number pvt-li " +
                            (haveMinNumber && "min-character-number-ok")
                          }
                        >
                          Au minimum 1 nombre
                        </li>
                        {/* <li
												className={
												"min-character-special pvt-li " +
												(haveMinSpecialCharacter &&
													"min-character-special-ok")
												}
											>
												Au minimum 1 caractère spéciale
											</li> */}
                      </ul>
                    </div>
                  </div>

                  <div className="form-group mb-3 warning-asterix-content">
                    <span className="warning-asterix">*</span>
                    <input
                      type="password"
                      className="majjal-form-control form-control form-control-lg"
                      placeholder="Confirmer mot de passe"
                      {...register("confirmPassword")}
                      data-testid="confirmPasswordId"
                    />
                    {formState.errors.confirmPassword && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {formState.errors.confirmPassword?.message?.toString()}
                      </div>
                    )}
                  </div>
                  <div className="btn-submit-form-container mb-4">
                    {!isLoading && (
                      <button
                        type="submit"
                        className="btn-theme-blue w-100"
                        data-testid="submitBtnId"
                      >
                        S'inscrire
                      </button>
                    )}
                    {isLoading && (
                      <button
                        type="button"
                        disabled
                        className="btn-theme-blue w-100"
                      >
                        Chargement...
                      </button>
                    )}
                  </div>
                  <div className="flex-r gap-2">
                    <p className="auth-link-message">
                      Vous avez deja un compte?
                    </p>
                    <Link to="/se-connecter" className="register-link">
                      Se connecter
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
