import { Projet } from "../../../../../../utils/api/launchCampagn/projet.type";
import DetailProjetTable from "../../../../Tables/mesProjets/DetailProjetTable";
import { ProjectInfos } from "./DetailProjet";

function Progression({ item }: { item: Projet }) {
  return (
    <>
      <ProjectInfos item={item} />
      <section className="details-projet-infos-table-container">
        <DetailProjetTable projet={item} />
      </section>
    </>
  );
}

export default Progression;
