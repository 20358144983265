import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRoutePath } from "../../../components/routes/routes";
import { ApiUrl } from "../../constants";
import { useLocationState } from "../../utils";
import { PaginationResults, QueryUrl } from "../common.api";
import { prepareHeaders } from "../user/user.api";
import { Projet, ProjetFormData, RetraitFormData } from "./projet.type";

type ProjectOnlineQuery = {
  page?: number;
  name__icontains?: string;
  category_id?: number;
  statut?: "offline" | "termine" | "online";
};

type ProjectByUserQuery = {
  page?: number;
  id: number;
};
const ProjetApi = createApi({
  reducerPath: "listProjet",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["listProjet", "projet", "projet_user"],
  endpoints: (build) => ({
    projetsOnline: build.query<PaginationResults<Projet>, ProjectOnlineQuery>({
      query: (query) => QueryUrl(`/projetbyfilter/`, query),
      providesTags: ["listProjet"],
    }),
    projets: build.query<
      PaginationResults<Projet>,
      { page: number; wordprojet: string; wordporteur: string }
    >({
      query: (query) => QueryUrl(`/projetbyfilteradmin/`, query),
      providesTags: ["listProjet"],
    }),
    projetsByUser: build.query<PaginationResults<Projet>, ProjectByUserQuery>({
      query: ({ id, ...query }) => QueryUrl(`/projetbyuser/${id}/`, query),
      providesTags: ["projet_user"],
    }),
    projetBySlug: build.query<Projet, string>({
      query: (slug) => `/projetbyvisitor_slug/${slug}/`,
      providesTags: (r) => ["projet"],
    }),
    addProjet: build.mutation<Projet, { id?: number; data: ProjetFormData }>({
      query: ({ id, data }) => ({
        url: id ? `/projet/${id}/` : `/projet/`,
        method: id ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["listProjet", "projet_user"],
    }),
    deleteProjet: build.mutation<Projet, number>({
      query: (id) => ({
        url: `/projet/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["listProjet", "projet_user"],
    }),
    addRetrait: build.mutation<Projet, RetraitFormData>({
      query: (data) => ({
        url: `/retrait/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["listProjet", "projet_user"],
    }),
  }),
});

export const {
  useAddProjetMutation,
  useProjetBySlugQuery,
  useLazyProjetBySlugQuery,
  useProjetsOnlineQuery,
  useProjetsByUserQuery,
  useProjetsQuery,
  useDeleteProjetMutation,
  useAddRetraitMutation,
} = ProjetApi;

export default ProjetApi;

export function useProjetFromLocation(): [
  Projet,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<Projet | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] = useLazyProjetBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!itemState) {
      findBySlug(slug as any);
    }
  }, [itemState]);
  useEffect(() => {
    if (isError) {
      navigate(getRoutePath("home"));
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return [item as Projet, isLoading, slug as string, findBySlug as any];
}
