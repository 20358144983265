import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import AchatApi from '../utils/api/achat/achat.api'
import { AuthApi } from '../utils/api/auth/auth.api'
import AvisApi from '../utils/api/avis/avis.api'
import CategorieApi from '../utils/api/categorie/categorie.api'
import CommentaireApi from '../utils/api/commentaire/commentaire.api'
import ConditionApi from '../utils/api/condition/condition.api'
import ConfigApi from '../utils/api/config/config.api'
import ContactApi from '../utils/api/contact/contact.api'
import FaqApi from '../utils/api/faq/faq.api'
import InvestApi from '../utils/api/invest/invest.api'
import ProjetApi from '../utils/api/launchCampagn/projet.api'
import LikeApi from '../utils/api/like/like.api'
import MontantConfigApi from '../utils/api/montantConfig/montantConfig.api'
import NotificationApi from '../utils/api/notification/notification.api'
import ProduitApi from '../utils/api/produit/produit.api'
import TeamApi from '../utils/api/team/team.api'
import { UserApi } from '../utils/api/user/user.api'
import { UserAdminApi } from '../utils/api/userAdmin/userAdmin.api'
import { produitSlice } from './slices/produit.slice'
import { userSlice } from './slices/user.slice'

export const store = configureStore({
  reducer: {
    [userSlice.name]: userSlice.reducer,
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [ProjetApi.reducerPath]: ProjetApi.reducer,
    [CategorieApi.reducerPath]: CategorieApi.reducer,
    [CommentaireApi.reducerPath]: CommentaireApi.reducer,
    [FaqApi.reducerPath]: FaqApi.reducer,
    [ProduitApi.reducerPath]: ProduitApi.reducer,
    [AvisApi.reducerPath]: AvisApi.reducer,
    [InvestApi.reducerPath]: InvestApi.reducer,
    [LikeApi.reducerPath]: LikeApi.reducer,
    [produitSlice.name]: produitSlice.reducer,
    [AchatApi.reducerPath]: AchatApi.reducer,
    [NotificationApi.reducerPath]: NotificationApi.reducer,
    [ConditionApi.reducerPath]: ConditionApi.reducer,
    [ContactApi.reducerPath]: ContactApi.reducer,
    [UserAdminApi.reducerPath]: UserAdminApi.reducer,
    [ConfigApi.reducerPath]: ConfigApi.reducer,
    [TeamApi.reducerPath]: TeamApi.reducer,
    [MontantConfigApi.reducerPath]: MontantConfigApi.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    AuthApi.middleware,
    UserApi.middleware,
    ProjetApi.middleware,
    CategorieApi.middleware,
    CommentaireApi.middleware,
    FaqApi.middleware,
    ProduitApi.middleware,
    AvisApi.middleware,
    InvestApi.middleware,
    LikeApi.middleware,
    AchatApi.middleware,
    NotificationApi.middleware,
    ConditionApi.middleware,
    UserAdminApi.middleware,
    ConfigApi.middleware,
    TeamApi.middleware,
    ContactApi.middleware,
    MontantConfigApi.middleware,
  ],
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export function useAppSelector<T>(cb: (s: RootState) => T) {
  return useSelector(cb)
}
