export const CartIcon = ({ color = "black", opacity = 0.5 }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.21356 20.1328C8.63562 20.1328 8.97866 20.4758 8.97866 20.8978C8.97866 21.3198 8.63562 21.6618 8.21356 21.6618C7.79151 21.6618 7.44946 21.3198 7.44946 20.8978C7.44946 20.4758 7.79151 20.1328 8.21356 20.1328Z"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="1.94734"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4675 20.1328C19.8895 20.1328 20.2326 20.4758 20.2326 20.8978C20.2326 21.3198 19.8895 21.6618 19.4675 21.6618C19.0454 21.6618 18.7024 21.3198 18.7024 20.8978C18.7024 20.4758 19.0454 20.1328 19.4675 20.1328Z"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="1.94734"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.54077 3.5625L5.62104 3.9225L6.58417 15.3955C6.66218 16.3305 7.44328 17.0485 8.3814 17.0485H19.2948C20.1909 17.0485 20.951 16.3905 21.0801 15.5025L22.0292 8.9445C22.1462 8.1355 21.5191 7.4115 20.702 7.4115H5.95509"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="1.94734"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9175 11.1055H17.6908"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="1.94734"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconEdit = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6992 16.898H17.0762"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85471 1.95561C10.5917 1.01661 11.7827 1.06561 12.7227 1.80261L14.1127 2.89261C15.0527 3.62961 15.3857 4.77261 14.6487 5.71361L6.35972 16.2886C6.08272 16.6426 5.65972 16.8516 5.20972 16.8566L2.01272 16.8976L1.28872 13.7826C1.18672 13.3456 1.28872 12.8856 1.56572 12.5306L9.85471 1.95561Z"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.30225 3.93604L13.0962 7.69404"
        stroke="#AEAEAE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconChat = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3738 2.32033C9.98767 2.31942 8.61984 2.63675 7.37571 3.24786C6.13157 3.85898 5.04434 4.74756 4.19779 5.84513C3.35124 6.94271 2.76797 8.21999 2.49291 9.57854C2.21786 10.9371 2.25838 12.3407 2.61133 13.6811C2.96429 15.0215 3.62026 16.263 4.52871 17.31C5.43717 18.3569 6.57384 19.1813 7.85115 19.7196C9.12846 20.2579 10.5123 20.4958 11.8961 20.415C13.2798 20.3341 14.6266 19.9367 15.8325 19.2532L19.2372 20.3878C19.3297 20.4171 19.4263 20.4314 19.5233 20.4304C19.6667 20.4303 19.808 20.3961 19.9357 20.3307C20.0633 20.2653 20.1735 20.1705 20.2574 20.0542C20.3412 19.9378 20.3962 19.8032 20.4178 19.6614C20.4394 19.5197 20.4271 19.3748 20.3817 19.2388L19.2517 15.8341C20.0344 14.4572 20.4418 12.899 20.4331 11.3153C20.4245 9.73154 20.0002 8.17784 19.2026 6.8096C18.4049 5.44136 17.262 4.30656 15.8881 3.51873C14.5142 2.73089 12.9575 2.31764 11.3738 2.32033ZM17.3981 16.0124L18.0917 18.0951L16.0091 17.4015C15.882 17.3582 15.7468 17.3438 15.6135 17.3595C15.4802 17.3751 15.352 17.4204 15.2385 17.492C13.7282 18.4478 11.9186 18.8131 10.1558 18.518C8.39309 18.2229 6.80107 17.2882 5.68446 15.8926C4.56785 14.4971 4.00508 12.7388 4.10384 10.9542C4.20261 9.16969 4.95598 7.48424 6.21978 6.22045C7.48357 4.95666 9.16902 4.20328 10.9536 4.10452C12.7381 4.00575 14.4964 4.56852 15.8919 5.68513C17.2875 6.80174 18.2223 8.39376 18.5174 10.1565C18.8125 11.9192 18.4472 13.7289 17.4914 15.2391C17.4193 15.353 17.3735 15.4815 17.3573 15.6153C17.3412 15.749 17.3551 15.8847 17.3981 16.0124Z"
        fill="#8E8EA1"
      />
      <path
        d="M14.9958 8.20312H7.75182C7.51166 8.20312 7.28134 8.29853 7.11153 8.46834C6.94171 8.63816 6.84631 8.86847 6.84631 9.10863C6.84631 9.34878 6.94171 9.5791 7.11153 9.74892C7.28134 9.91873 7.51166 10.0141 7.75182 10.0141H14.9958C15.236 10.0141 15.4663 9.91873 15.6361 9.74892C15.8059 9.5791 15.9014 9.34878 15.9014 9.10863C15.9014 8.86847 15.8059 8.63816 15.6361 8.46834C15.4663 8.29853 15.236 8.20312 14.9958 8.20312ZM11.3738 12.7306H7.75182C7.51166 12.7306 7.28134 12.826 7.11153 12.9959C6.94171 13.1657 6.84631 13.396 6.84631 13.6361C6.84631 13.8763 6.94171 14.1066 7.11153 14.2764C7.28134 14.4462 7.51166 14.5417 7.75182 14.5417H11.3738C11.614 14.5417 11.8443 14.4462 12.0141 14.2764C12.1839 14.1066 12.2793 13.8763 12.2793 13.6361C12.2793 13.396 12.1839 13.1657 12.0141 12.9959C11.8443 12.826 11.614 12.7306 11.3738 12.7306Z"
        fill="#8E8EA1"
      />
    </svg>
  );
};

export const IconNotif = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7227 17.8278H10.6854C10.21 17.8278 9.8025 18.2353 9.8025 18.7107C9.8025 19.1861 10.21 19.5935 10.6854 19.5935H12.7227C13.1981 19.5935 13.6056 19.1861 13.6056 18.7107C13.6056 18.2353 13.1981 17.8278 12.7227 17.8278ZM17.7483 15.7225H17.205V10.4253C17.205 7.64089 15.1676 5.39977 12.519 4.99229V4.10943C12.519 3.63404 12.1794 3.22656 11.7041 3.22656C11.2287 3.22656 10.8212 3.63404 10.8212 4.10943V4.99229C8.17259 5.39977 6.13521 7.70881 6.13521 10.4253V15.7225H5.72773C5.25234 15.7225 4.91278 16.13 4.91278 16.6054C4.91278 17.0808 5.32026 17.4882 5.79565 17.4882H7.08599H16.458H17.8162C18.2916 17.4882 18.6991 17.0808 18.6991 16.6054C18.6991 16.13 18.2237 15.7225 17.7483 15.7225ZM7.83303 15.7225V10.4253C7.83303 8.32002 9.53085 6.6222 11.6361 6.6222C13.7414 6.6222 15.4393 8.32002 15.4393 10.4253V15.7225H7.83303Z"
        fill="#8E8EA1"
      />
    </svg>
  );
};

export const IconDelete = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2871 3.24297C17.6761 3.24297 18 3.56596 18 3.97696V4.35696C18 4.75795 17.6761 5.09095 17.2871 5.09095H0.713853C0.32386 5.09095 0 4.75795 0 4.35696V3.97696C0 3.56596 0.32386 3.24297 0.713853 3.24297H3.62957C4.22185 3.24297 4.7373 2.82197 4.87054 2.22798L5.02323 1.54598C5.26054 0.616994 6.0415 0 6.93527 0H11.0647C11.9488 0 12.7385 0.616994 12.967 1.49699L13.1304 2.22698C13.2627 2.82197 13.7781 3.24297 14.3714 3.24297H17.2871ZM15.8058 17.134C16.1102 14.2971 16.6432 7.55712 16.6432 7.48913C16.6626 7.28313 16.5955 7.08813 16.4623 6.93113C16.3193 6.78413 16.1384 6.69713 15.9391 6.69713H2.06852C1.86818 6.69713 1.67756 6.78413 1.54529 6.93113C1.41108 7.08813 1.34494 7.28313 1.35467 7.48913C1.35646 7.50162 1.37558 7.73903 1.40755 8.13594C1.54958 9.89917 1.94517 14.8102 2.20079 17.134C2.38168 18.846 3.50498 19.922 5.13206 19.961C6.38763 19.99 7.68112 20 9.00379 20C10.2496 20 11.5149 19.99 12.8094 19.961C14.4929 19.932 15.6152 18.875 15.8058 17.134Z"
        fill="#ACABAB"
      />
    </svg>
  );
};

export const IconTabEdit = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6653 2.01034C18.1038 1.92043 19.5224 2.41991 20.5913 3.3989C21.5703 4.46779 22.0697 5.88633 21.9898 7.33483V16.6652C22.0797 18.1137 21.5703 19.5322 20.6013 20.6011C19.5323 21.5801 18.1038 22.0796 16.6653 21.9897H7.33487C5.88636 22.0796 4.46781 21.5801 3.39891 20.6011C2.41991 19.5322 1.92043 18.1137 2.01034 16.6652V7.33483C1.92043 5.88633 2.41991 4.46779 3.39891 3.3989C4.46781 2.41991 5.88636 1.92043 7.33487 2.01034H16.6653ZM10.9811 16.845L17.7042 10.102C18.3136 9.4826 18.3136 8.48364 17.7042 7.87427L16.4056 6.57561C15.7862 5.95625 14.7872 5.95625 14.1679 6.57561L13.4985 7.25491C13.3986 7.35481 13.3986 7.52463 13.4985 7.62453C13.4985 7.62453 15.0869 9.20289 15.1169 9.24285C15.2268 9.36273 15.2967 9.52256 15.2967 9.70238C15.2967 10.062 15.007 10.3617 14.6374 10.3617C14.4675 10.3617 14.3077 10.2918 14.1978 10.1819L12.5295 8.5236C12.4496 8.44368 12.3098 8.44368 12.2298 8.5236L7.46474 13.2887C7.13507 13.6183 6.94527 14.0579 6.93528 14.5274L6.87534 16.8949C6.87534 17.0248 6.9153 17.1447 7.00521 17.2346C7.09512 17.3245 7.21499 17.3744 7.34486 17.3744H9.69245C10.172 17.3744 10.6315 17.1846 10.9811 16.845Z"
        fill="#ACABAB"
      />
    </svg>
  );
};
