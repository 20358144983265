import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { onSetUser } from "../../../../redux/slices/user.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useUserEditMutation } from "../../../../utils/api/user/user.api";
import { getRoutePath } from "../../../routes/routes";
import "./Sidebar.css";

interface PropsType {
  index: number;
}

export const useSwitch = (bool: boolean) => {
  const user = useAppSelector((s) => s.user.user);
  const [update] = useUserEditMutation();
  const dispatch = useAppDispatch();

  const switchTo = useCallback((profil?: boolean) => {
    if (user) {
      const ok = profil === undefined ? bool : profil;
      dispatch(onSetUser({ ...user, is_double_type: ok }));
      update({ is_double_type: ok });
    }
  }, []);

  return switchTo;
};

const Sidebar = ({ index }: PropsType) => {
  const user = useAppSelector((s) => s.user.user);
  const switchTo = useSwitch(true);
  const [active, setActive] = useState(index);
  let links: any = [];
  if (!user?.is_double_type) {
    links = [
      {
        label: "Mes projets",
        pathname: getRoutePath("ListeProjets"),
      },
      {
        label: "Mes dons",
        pathname: getRoutePath("MesDons"),
      },
      {
        label: "Mes commandes",
        pathname: getRoutePath("MesCommandes"),
      },
      {
        label: "Mes points",
        pathname: getRoutePath("MesPoints"),
      },
      {
        label: "Mon Compte ",
        pathname: getRoutePath("MonCompte"),
        onClick: () => null,
      },
    ];
  } else {
    links = [
      {
        label: "Dashboard",
        pathname: getRoutePath("MerchantDashboard"),
      },
      {
        label: "Mes offres",
        pathname: getRoutePath("ProductList"),
      },
      {
        label: "Mes commandes",
        pathname: getRoutePath("CommandeList"),
      },
      {
        label: "Mes points",
        pathname: getRoutePath("MesPointsCommercant"),
      },
      {
        label: "Mon Compte",
        pathname: getRoutePath("ParametreProfil"),
        onClick: () => null,
      },
    ];
  }

  if (user?.is_commercant) {
    links.push({
      label: !user?.is_double_type
        ? "Profil commerçant"
        : "Profil investisseur",
      pathname: "#",
      onClick: () => {
        switchTo(!user?.is_double_type);
        return null;
      },
    });
  }

  return (
    <div
      id="scrollbar-nav"
      className="col-xl-2 col-lg-3 col-md-4 tableau-de-bord-side-bar padding-responsive maajjal-card position-relative"
    >
      <div className="content-nav">
        <ul>
          {links.map((link, index) => (
            <li
              className={`sidebar-item  ${active === index && "active-item"}`}
              key={index}
              onClick={() => {
                setActive(index);
                if (link.onClick) {
                  link.onClick();
                }
              }}
            >
              <Link
                to={link.pathname}
                className={`sidebar-link ${active === index && "active-link"}`}
              >
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
