export type PaginationResults<T> = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: T[];
};

export type AnyQuery<T> = { [key: string]: T };

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];

      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = val.map((v) => `${key}=${v}`).join("&");
          if (baseUrl.includes("?")) {
            baseUrl += string;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}
