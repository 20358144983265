import { useState } from "react";
import CommandesAdminTable from "../../Tables/admin/commandes/CommandesAdminTable";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import "./CommandesAdmin.css";

function CommandesAdmin() {
  const [word1, setWord1] = useState("");
  const [word2, setWord2] = useState("");
  const [word3, setWord3] = useState("");
  return (
    <div className="parametre-page">
      <TopBar index={6} />
      <SideBarAdmin index={6} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="p4 p-xxl-3">
            <div className="row">
              <div className="col-md-12 maajjal-table-card px-3 py-4  ml-2 mr-3">
                <div className="col-md-12">
                  <h3 className="tb-mes-projet-titre mb-4">
                    Toutes les commandes
                  </h3>
                </div>
                <div className="dashboard-commandes-filtre-container ">
                  <form className="row g-3 dashboard-commandes-filtre-form">
                    <div className="col-auto">
                      <label
                        htmlFor="staticEmail2"
                        className="dashboard-commandes-filtre-label"
                      >
                        Filtrer
                      </label>
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control dashboard-commandes-filtre-input"
                        id="inputPassword2"
                        value={word1}
                        onChange={(e) => setWord1(e.target.value)}
                        placeholder="Produit"
                      />
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        value={word2}
                        onChange={(e) => setWord2(e.target.value)}
                        className="form-control dashboard-commandes-filtre-input"
                        id="inputPassword2"
                        placeholder="Commerçant"
                      />
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        value={word3}
                        onChange={(e) => setWord3(e.target.value)}
                        className="form-control dashboard-commandes-filtre-input"
                        id="inputPassword2"
                        placeholder="Client"
                      />
                    </div>
                  </form>
                </div>

                <CommandesAdminTable {...{ word1, word2, word3 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommandesAdmin;
