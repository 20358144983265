import React from "react";
import { useNavigate } from "react-router-dom";

import { useAddProduitMutation } from "../../../../utils/api/produit/produit.api";
import { Produit } from "../../../../utils/api/produit/produit.type";
import { getRoutePath } from "../../../routes/routes";

export function DeleteProductModal({
  modalId,
  onHide,
  produit,
}: {
  modalId: string;
  onHide: () => any;
  produit: Produit;
}) {
  const navigate = useNavigate();
  const [deleteProject, { isLoading, isSuccess, isError }] =
    useAddProduitMutation();

  const onHide2 = () => {
    onHide();
    if (isSuccess) {
      navigate(getRoutePath("ProductList"));
    }
  };
  React.useEffect(() => {
    return () => onHide();
  }, []);
  return (
    <div
      className="modal fade"
      id={modalId}
      data-bs-backdrop="static"
      data-backdrop="false"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdrop2Label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content custom-modal-investissement">
          <div className="modal-header modal-header-investissement-confirm">
            <button
              type="button"
              className="btn-close"
              onClick={onHide2}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modal-invessetissement-body">
            <div className="modal-investissement-description-content2">
              {isSuccess && (
                <>
                  <div className="modal-investissement-description-confirm">
                    succès
                  </div>
                  <div className="modal-investissement-confirm-para-container">
                    <div className="modal-investissement-confirm-para">
                      Produit supprimé avec succès
                    </div>
                  </div>
                </>
              )}
              {isError && (
                <>
                  <div className="modal-investissement-description-confirm">
                    Erreur
                  </div>
                  <div className="modal-investissement-confirm-para-container">
                    <div className="modal-investissement-confirm-para">
                      Produit non supprimé
                    </div>
                  </div>
                </>
              )}
              {!isSuccess && !isError && (
                <>
                  <div className="modal-investissement-description-confirm">
                    Confirmation de suppression
                  </div>
                  <div className="modal-investissement-confirm-para-container">
                    <div className="modal-investissement-confirm-para">
                      êtes-vous sûr de vouloir supprimer le produit{" "}
                      {produit.name}
                    </div>
                  </div>
                  <div className="modal-investissement-montants-button-container">
                    <button
                      type="button"
                      disabled={isLoading}
                      className="btn modal-investissement-confirm-btn-annuler"
                      onClick={onHide2}
                    >
                      Annuler
                    </button>
                    {isLoading && (
                      <button
                        type="button"
                        className="btn modal-investissement-montants-btn-continuer"
                      >
                        Chargement...
                      </button>
                    )}
                    {!isLoading && (
                      <button
                        type="button"
                        onClick={() =>
                          deleteProject({
                            id: produit.id,
                            data: { archiver: true },
                          })
                        }
                        className="btn modal-investissement-montants-btn-continuer"
                      >
                        Confirmer
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
