import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useAddMontantConfigMutation } from '../../../../../../utils/api/montantConfig/montantConfig.api'
import { MontantConfigFormData } from '../../../../../../utils/api/montantConfig/montantConfig.type'
import { COLORS } from '../../../../../../utils/constants'
import { onHide, useModalActions } from '../../../../../../utils/utils'

function UseAddMontantConfig() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        montant: yup.string().required('Le montant est requis'),
      }),
    [],
  )

  const [
    sendData,
    { isSuccess, isError, isLoading, error },
  ] = useAddMontantConfigMutation()
  const [idModal, setIdModal] = useState<string>('ajoutMontantModal')
  const [hasEdit, setHasEdit] = useState(false)

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    setError,
    reset,
  } = useForm<MontantConfigFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      montant: '',
      slug: '',
    },
  })

  useEffect(() => {
    if (isSuccess) {
      onHide(idModal)
      Swal.fire({
        icon: 'success',
        title: `${
          hasEdit ? 'Montant modifié avec succès!' : 'Admin ajouté avec succès!'
        }`,
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        if (!hasEdit) {
          reset()
        }
      })
    }

    if (isError) {
      const err = error as any
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {}
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 5000,
      })
    }
  }, [isSuccess, isError, error])

  const onSubmit = async (data: MontantConfigFormData) => {
    if (data?.slug) {
      setHasEdit(true)
    } else {
      console.log('donnée form', data)
      setHasEdit(false)
    }
    await sendData(data)
  }

  useEffect(() => {
    if (isError) {
      console.log(error)
    }
  }, [isError])

  return {
    register,
    formState,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    isSuccess,
    isLoading,
    error,
    isError,
    setValue,
    setError,
    reset,
    setIdModal,
  }
}

export default UseAddMontantConfig
