import { Produit } from "../../../utils/api/produit/produit.type";
import { formatMontant, getImage } from "../../../utils/utils";
import { useCartAction } from "../shop/MarketPlace/Common/BtnAjouterAuPanier";
import { PaimentButton } from "../shop/MarketPlace/ProductMarketDetail";

const ShoppingCartItem = ({ item }: { item: Produit }) => {
  const [inCart, productCart, handleCart] = useCartAction(item);

  return (
    <div className="shopping-cart-item border-b pb-4 mb-4">
      <div className="row">
        <div className="shopping-img-container col-md-4">
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={getImage(item?.avatar)}
              alt="item-img"
              className="shopping-item-img"
            />
          </div>
        </div>
        <div className="shopping-item-desc col-md-8">
          <div className="shopping-text gap-3">
            <h4 className="fw-400 text-15">{item?.name}</h4>

            <div className="item-c gap-1">
              {!!item.discount && (
                <>
                  <h2 className="text-15 fw-600">
                    {formatMontant(item.price_discount)}
                  </h2>
                  <h6 className="fw-400 text-15">
                    {` (avec ${item?.point} points)`}
                  </h6>
                  <h6 className="text-12 fw-400 line-throught">
                    {`${formatMontant(item?.prix)}`}
                  </h6>
                </>
              )}
              {!item.discount && (
                <>
                  <h2 className="text-15 fw-600">
                    {`${formatMontant(item?.prix)}`}
                  </h2>
                </>
              )}
            </div>
          </div>
          <div className="sohpping-item-desc">
            <h6 className="title-desc leading-21 fw-500 text-14">
              Description
            </h6>
            <p
              className="leading-16 fw-300 text-10"
              dangerouslySetInnerHTML={{ __html: item?.description }}
            />
          </div>
          <div className="btn-inc-dec-container">
            <button
              className="btn-inc-dec"
              onClick={() => handleCart(false, false, true)}
            >
              -
            </button>
            <span className="number-item mx-3">
              {productCart?.quantity || 0}
            </span>
            <button
              className="btn-inc-dec"
              onClick={() => handleCart(false, true, false)}
            >
              +
            </button>
          </div>
          <div className="btn-shopping-container mt-4 gap-3 d-flex flex-column flex-sm-row flex-md-column flex-lg-row">
            <PaimentButton cart produit={productCart || item} />
            <button
              className="cart-btn cart-btn-shop-delete"
              onClick={() => handleCart(true)}
            >
              Supprimer du panier
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartItem;
