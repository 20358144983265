import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useProductsByUserQuery } from "../../../../utils/api/produit/produit.api";
import { Produit } from "../../../../utils/api/produit/produit.type";
import {
  formatAmout,
  getImage,
  useModalActions,
  useUserId,
} from "../../../../utils/utils";
import { getRoutePath } from "../../../routes/routes";
import { DeleteProductModal } from "../../TableauDeBord/mesprojets/DeleteProductModal";
import MesOffresSkeleton from "./MesOffresSkeleton";

export function DeleteProduitButton({
  item,
  className,
  iconClassName,
  modalId,
}: {
  item: Produit;
  className?: string;
  iconClassName?: string;
  modalId?: string;
}) {
  modalId = modalId || "DeleteProduitModal";
  const ma = useModalActions(modalId);
  return (
    <>
      <DeleteProductModal
        modalId={modalId}
        onHide={() => ma.hide()}
        produit={item}
      />
      <button onClick={() => ma.show()} className={className}>
        <FaTrash className={iconClassName} />
      </button>
    </>
  );
}

const MesOffresTable = () => {
  const id = useUserId();
  const { data = [], isLoading } = useProductsByUserQuery(id);

  const imageFormatter = (cell: any, row: any) => {
    return (
      <img
        src={getImage(cell)}
        style={{ width: 40, height: 40 }}
        alt="produit"
        className="img-table"
      />
    );
  };

  const discountFormatter = (cell: any, row: any) => {
    return `${cell}%`;
  };

  const actionFormatter: any = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <NavLink
          className="action-btn"
          title="Voir"
          state={row}
          to={getRoutePath("DetailOffre", { slug: row.slug })}
        >
          <AiOutlineEye className="voir-btn-offre" />
        </NavLink>
        <NavLink
          to={getRoutePath("UpdateOffre", { slug: row.slug })}
          state={row}
          className="action-btn mx-2"
        >
          <AiOutlineEdit className="edit-btn" />
        </NavLink>
        <DeleteProduitButton
          item={row}
          className="bg-transparent border-none"
          iconClassName="supprimer-btn"
          modalId={`DeleteProduit${row.id}`}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "avatar",
      text: "Image",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => imageFormatter(cell, row),
      headerStyle: {
        textAlign: "center",
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.7)",
      },
    },
    {
      dataField: "name",
      text: "Produit",
      style: { textAlign: "center", verticalAlign: "middle" },
      filter: textFilter({
        placeholder: "Entrer un produit",
        className: "my-custom-filter",
      }),
      headerStyle: {
        textAlign: "center",
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.7)",
      },
    },
    {
      dataField: "prix",
      text: "Prix",
      style: { textAlign: "center", verticalAlign: "middle" },
      headerStyle: {
        textAlign: "center",
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.7)",
      },
      formatter: (cell: any) => formatAmout(cell),
    },
    {
      dataField: "discount",
      text: "Discount",
      style: { textAlign: "center", verticalAlign: "middle" },
      formatter: (cell: any, row: any) => discountFormatter(cell, row),
      headerStyle: {
        textAlign: "center",
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.7)",
      },
    },
    {
      dataField: "point",
      text: "Points",
      style: { textAlign: "center", verticalAlign: "middle" },
      headerStyle: {
        textAlign: "center",
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.7)",
      },
    },
    {
      dataField: "created_ay",
      formatter: (cell) => moment(cell).format("DD/MM/YYYY"),
      text: "Date Création",
      style: { textAlign: "center", verticalAlign: "middle" },
      headerStyle: {
        textAlign: "center",
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.7)",
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      style: { textAlign: "center", verticalAlign: "middle" },
      headerStyle: {
        textAlign: "center",
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.7)",
      },
    },
  ];

  return (
    <>
      {isLoading && <MesOffresSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          bordered={false}
          condensed={false}
          responsive={true}
          wrapperClasses="table-responsive"
          rowStyle={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "21px",
            color: "rgba(0, 0, 0, 0.7)",
          }}
          headerClasses="table-header-style"
          filter={filterFactory()}
          bootstrap4
        />
      )}
    </>
  );
};

export default MesOffresTable;
