import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";
import { User } from "../../../../utils/api/user/user.type";
import {
  useDeleteUserAdminMutation,
  useGetUsersAdminQuery,
} from "../../../../utils/api/userAdmin/userAdmin.api";
import { COLORS } from "../../../../utils/constants";
import { IconDelete, IconTabEdit } from "../../../shared/Icons";
import Pagination from "../../../shared/Pagination";
import ModifierAdministrateurModal from "../../Modals/Administrateur/ModifierAdministrateurModal";
import ListeAdminsSkeleton from "./ListeAdminsSkeleton";

const ListeAdminsTable = () => {
  const [page, setPage] = useState(1);
  const { data = { results: [] }, isLoading } = useGetUsersAdminQuery({ page });
  const [deleteUser, { isSuccess, isError, error }] =
    useDeleteUserAdminMutation();

  const onDeleteAdmin = (slug) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cet utilisateur",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn  mx-2",
      },
      preConfirm: () => {
        return deleteUser(slug);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
      }
    });
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Utilisateur supprimé avec succès",
        iconColor: COLORS.blue,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      console.log(err);

      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);

  const actionFormatter = (cell, row) => {
    return (
      <div>
        <button
          className="btn-delete bg-transparent border-none"
          onClick={() => onDeleteAdmin(row?.slug)}
        >
          <IconDelete />
        </button>
        <EditButton user={row} />
      </div>
    );
  };
  const columns = [
    {
      dataField: "first_name",
      text: "Prénom",
      style: { textAlign: "justify", verticalAlign: "middle" },
      headerStyle: {
        textAlign: "justify",
        fontWeight: 600,
        color: "#021849",
      },
    },
    {
      dataField: "last_name",
      text: "Nom",
      style: { textAlign: "justify", verticalAlign: "middle" },
      headerStyle: {
        textAlign: "justify",
        fontWeight: 600,
        color: "#021849",
      },
    },
    {
      dataField: "phone",
      text: "Téléphone",
      style: { textAlign: "justify", verticalAlign: "middle" },
      headerStyle: {
        textAlign: "justify",
        fontWeight: 600,
        color: "#021849",
      },
    },
    {
      dataField: "email",
      text: "Adresse Mail",
      style: { textAlign: "justify", verticalAlign: "middle" },
      headerStyle: {
        textAlign: "justify",
        fontWeight: 600,
        color: "#021849",
      },
    },
    {
      dataField: "",
      text: "Actions",
      style: { textAlign: "justify", verticalAlign: "middle" },
      headerStyle: {
        textAlign: "justify",
        fontWeight: 600,
        color: "#021849",
      },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <div className="liste-admins-table mt-5">
      {isLoading && <ListeAdminsSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="numero"
          data={data.results}
          columns={columns}
          bordered={false}
          condensed={false}
          responsive={true}
          striped={true}
          wrapperClasses="table-responsive"
          rowStyle={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "17px",
            color: "rgba(0, 0, 0, 0.7)",
          }}
          headerClasses="table-header-style"
        />
      )}
      <Pagination {...{ page, onPageChange: setPage, total: data.count }} />
    </div>
  );
};

export default ListeAdminsTable;

export function EditButton({ user }: { user: User }) {
  const modalId = `modifAdminModal${user?.id}`;
  return (
    <>
      <button
        className="btn-edit bg-transparent border-none"
        {...{
          "data-bs-toggle": "modal",
          "data-bs-target": `#${modalId}`,
        }}
      >
        <IconTabEdit />
      </button>
      <ModifierAdministrateurModal modalId={modalId} item={user} />
    </>
  );
}
