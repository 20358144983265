import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiOutlineEdit } from "react-icons/ai";
import { useAppSelector } from "../../../../../redux/store";
import InvestApi from "../../../../../utils/api/invest/invest.api";
import { formatAmout } from "../../../../../utils/utils";
import MesdonsSkeleton from "./MesdonsSkeleton";
import UpdateInvestModal from "./UpdateInvestModal";

function MesDonsTable() {
  const id = useAppSelector((s) => s.user.user?.id as number);
  const { data = [], isLoading } = InvestApi.useInvestsbyUserQuery(id);
  const actionFormatter: any = (cell, row) => {
    const modalId = `UpdateDOn${row.id}`;
    return (
      <>
        <div className="table-actions-btn-container-projet">
          <span
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
            className="action-btn cursor-pointer"
          >
            <AiOutlineEdit className="edit-btn" />
          </span>
        </div>
        <UpdateInvestModal modalId={modalId} item={row} />
      </>
    );
  };
  const columns = [
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell, row) => moment(cell).format("DD/MM/YYYY"),
      style: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "projet_id.name",
      text: "Nom du projet",
      style: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "funds",
      formatter: (cell, row) => formatAmout(cell),
      text: "Montant",
      style: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "point",
      text: "Points obtenus",
      style: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "id",
      text: "Action",
      style: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      formatter: actionFormatter,
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
  ];

  return (
    <>
      {isLoading && <MesdonsSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive"
        />
      )}
    </>
  );
}

export default MesDonsTable;
