import moment from "moment";
import { useStep } from "react-hooks-helper";
import { useCommentsByprojetQuery } from "../../../../../../utils/api/commentaire/commentaire.api";
import InvestApi from "../../../../../../utils/api/invest/invest.api";
import { Projet } from "../../../../../../utils/api/launchCampagn/projet.type";
import LikeApi from "../../../../../../utils/api/like/like.api";
import { formatAmout } from "../../../../../../utils/utils";
import Aime from "./Aime";
import Commentaires from "./Commentaires";
import ListeDonateurs from "./ListeDonateurs";

const steps: any = [
  { id: "liste-donateurs", Component: ListeDonateurs },
  { id: "commentaires", Component: Commentaires },
  { id: "aime", Component: Aime },
];

function DetailProjet({ item }: { item: Projet }) {
  const { data: { count: nb_comments } = { count: 0 } } =
    useCommentsByprojetQuery({
      slug: item?.slug,
      page: 1,
    });

  const { data: { count: nb_likes } = { count: 0 } } =
    LikeApi.useLikesbyProjetQuery({
      id: item?.id as number,
      page: 1,
    });
  const {
    navigation: { go },
    index,
  } = useStep({ initialStep: 0, steps });
  const { data = [] } = InvestApi.useInvestsbyProjetQuery(item.id);
  return (
    <>
      <ProjectInfos item={item} />
      <section className="mesprojets-detail-projet-info-sup p-2 mb-4">
        <div className="mesprojets-detail-projet-info-sup-tab-container">
          <button
            onClick={() => go?.(0)}
            className={`stepper-detail-projet ${
              index === 0 && "stepper-active-detail-mission"
            }`}
          >
            Liste des donateurs ({data.length})
          </button>
          <button
            onClick={() => go?.(1)}
            className={`stepper-detail-projet ${
              index === 1 && "stepper-active-detail-mission"
            }`}
          >
            Commentaires ({nb_comments})
          </button>
          <button
            onClick={() => go?.(2)}
            className={`stepper-detail-projet ${
              index === 2 && "stepper-active-detail-mission"
            }`}
          >
            J’aime ({nb_likes})
          </button>
        </div>
        {index === 0 && <ListeDonateurs projet={item} />}
        {index === 1 && <Commentaires projet={item} />}
        {index === 2 && <Aime projet={item} />}
      </section>
    </>
  );
}

export default DetailProjet;

export function ProjectInfos({ item }: { item: Projet }) {
  return (
    <section className="mesprojets-detail-projet-description p-2 mb-4">
      <div className="mesprojets-detail-projet-titre-container">
        <h3 className="mesprojets-detail-projet-titre">{item.name}</h3>
        <div
          className="mesprojets-detail-projet-titre-info"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </div>
      <hr></hr>
      <div className="row mesprojets-detail-projet-categorie-container">
        <div className="col-xl-5 pb-3">
          <div className="mesprojets-detail-projet-categorie">
            <div className="mesprojets-detail-projet-categorie1">
              Catégories :{" "}
            </div>
            <div className="mesprojets-detail-projet-categorie2">
              {item.category_id?.name}
            </div>
          </div>
          <div className="mesprojets-detail-projet-data-creation">
            <div className="mesprojets-detail-projet-categorie1">
              Date de création :{" "}
            </div>
            <div className="mesprojets-detail-projet-categorie2">
              {moment(item.created_at).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>
        <div className="col-xl-7">
          <div className="">
            <div className="mesprojets-detail-projet-resume">
              Résumé de la campagne
            </div>
            <div className="card-campagne-progresse-container mesprojets-detail-projet-progession-container ">
              <div className="card-campagne-progresse-price-container mb-2">
                <div className="card-detail-campagne-progresse-percentage card-detail-campagne-progresse-percentage-dashboard">
                  ({Math.ceil(item.pourcentage)} %)
                </div>
                <div className="card-detail-campagne-progresse-price card-detail-campagne-progresse-price-dashboard">
                  {formatAmout(item.funds)} (collectés)
                </div>
              </div>
              <div className="progress mb-3" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${item.pourcentage}%`,
                    backgroundColor: "#0FBF56",
                  }}
                  aria-valuenow={item.pourcentage}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <div className="card-detail-campagne-progresse-details mb-4">
                <p className="card-detail-campagne-progresse-details-para card-detail-campagne-progresse-details-para-dashboard">
                  sur un objectif de {formatAmout(item.objectif_levee)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
