import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import { useProduitFromLocation } from "../../../../utils/api/produit/produit.api";
import { Produit } from "../../../../utils/api/produit/produit.type";
import { formatMontant, getImage } from "../../../../utils/utils";
import { getRoutePath } from "../../../routes/routes";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import GoBackBtn from "../../../shared/GoBackBtn";
import { CartIcon } from "../../../shared/Icons";
import { SEO } from "../../../shared/SEO";
import ProcessPaiement from "../../ProcessPaiement/ProcessPaiement";
import { useCartAction } from "./Common/BtnAjouterAuPanier";
const ProductMarketDetail = () => {
  const [produit, isLoading] = useProduitFromLocation();
  const [inCart, productCart, handleCart] = useCartAction(produit);
  if (!produit) return null;

  return (
    <>
      <SEO title={produit.name} />
      <div className="marketplace-page">
        <FrontHeader />
        <div className="custom-container margin-container  px-md-4 px-3">
          <GoBackBtn className="product-market-go-baack-btn" label="Retour" />
          <div className="maajjal-card custom-container py-5  px-3">
            <div className="row produit-market-detail  ">
              <div className="col-md-3 produit-market-img-container">
                <img
                  src={getImage(produit?.avatar)}
                  alt="produit"
                  className="card-img custom-card-img-detail-produit"
                />
              </div>
              <div className=" col-md-9 flex-fill">
                <h2 className="produit-title">{produit?.name}</h2>
                <div className="price-container  flex-column">
                  <h6 className="price m-0 d-flex flex-sm-row">
                    {formatMontant(
                      produit.discount ? produit?.price_discount : produit.prix
                    )}
                    {!!produit.discount && (
                      <h6 className="normal-price line-through mb-0">
                        {formatMontant(produit?.prix)}
                      </h6>
                    )}
                  </h6>
                </div>
                {!!produit.discount && (
                  <h6 className="points-number fw-300">
                    avec {produit?.point} points
                  </h6>
                )}
                <h6 className="quantites fw-400 mt-2">Quantité</h6>
                <div className="increment-btn-container d-flex align-items-center justify-content-center">
                  <button
                    className="btn-inc-dec"
                    onClick={() => handleCart(false, false, true)}
                  >
                    -
                  </button>
                  <span className="number-item mx-3">
                    {inCart ? productCart?.quantity : 1}
                  </span>
                  <button
                    className="btn-inc-dec"
                    onClick={() => handleCart(false, true, false)}
                  >
                    +
                  </button>
                </div>
                <div className="btn-product-detail-container mt-4 gap-3 d-flex flex-column flex-md-row">
                  <PaimentButton
                    produit={productCart || { ...produit, quantity: 1 }}
                    modalId="PaimentModal"
                  />
                  <button
                    className={`card-product-market-btn flex-cc gap-2 ${
                      inCart && "out"
                    }`}
                    {...(inCart
                      ? {}
                      : {
                          "data-bs-toggle": "modal",
                          "data-bs-target": "#notifmodal",
                        })}
                    onClick={() => handleCart()}
                  >
                    <CartIcon
                      {...(inCart ? { color: "#e74141", opacity: 1 } : {})}
                    />
                    {inCart ? "Retirer au panier" : "Ajouter au panier"}
                  </button>
                </div>
              </div>
            </div>
            <div className="product-desc-container mt-5 px-sm-5 px-3 py-4">
              <h6 className="desc-product-title">Description</h6>
              <p
                className="desc-product-body"
                dangerouslySetInnerHTML={{
                  __html: produit.description,
                }}
              />
              <h6 className="desc-product-title">Nom commerçant</h6>
              <p className="desc-product-body">
                {produit?.commercant?.first_name +
                  " " +
                  produit?.commercant?.last_name}
              </p>
            </div>
          </div>
        </div>
        <FrontFooter />
      </div>
    </>
  );
};

export default ProductMarketDetail;

export function PaimentButton({
  cart,
  produit,
  modalId,
}: {
  cart?: boolean;
  produit: Produit;
  modalId?: string;
}) {
  const navigate = useNavigate();
  const userId = useAppSelector((s) => s.user?.user?.id);
  modalId = modalId || `processPaiement${produit.id}`;
  const buttonProps = userId
    ? {
        "data-bs-toggle": "modal",
        "data-bs-target": `#${modalId}`,
      }
    : {
        onClick: () => {
          navigate(getRoutePath("login"));
        },
      };
  return (
    <>
      {cart && (
        <button className="cart-btn cart-btn-shop-command" {...buttonProps}>
          Commander maintenant
        </button>
      )}
      {!cart && (
        <button className="product-btn-shop-command" {...buttonProps}>
          Acheter maintenant
        </button>
      )}
      <ProcessPaiement modalId={modalId} item={produit} />
    </>
  );
}
