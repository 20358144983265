import { useStep } from "react-hooks-helper";
import { useAppSelector } from "../../../../redux/store";
import { ConfirmationAchatModal } from "../../../modales/ConfirmationInvestModal";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import MesAchatsTable from "../../Tables/mescommandes/MesAchatsTable";
import MesCommandesTable from "../../Tables/mescommandes/MesCommandesTable";
import Sidebar from "../Sidebar/Sidebar";
import "./MesCommandes.css";

const steps: any = [
  { id: "commandes_recu", Component: MesCommandesTable },
  { id: "mes_achats", Component: MesAchatsTable },
];

function MesCommandesContainer({ children }) {
  const modalId = "AchatDone";
  return (
    <div className="campagne-page-container">
      <ConfirmationAchatModal modalId={modalId} />
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={2} />
          <div className="col-md-8 col-lg-9 col-xl-10">
            <div className="mt-tableau-bord py-5">{children}</div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

function MesCommandesCommercant() {
  const {
    navigation: { go },
    index,
  } = useStep({ initialStep: 0, steps });

  return (
    <MesCommandesContainer>
      <div className="row">
        <div className="col-md-12">
          <h3 className="tb-mes-projet-titre mb-4">Mes commandes</h3>
        </div>
        <div className="col-md-12">
          <div className="dashboard-commandes-tab-container">
            <button
              onClick={() => go?.(0)}
              className={`stepper-commandes-tab ${
                index === 0 && "stepper-active-commandes-tab"
              }`}
            >
              Commandes reçues
            </button>
            <button
              onClick={() => go?.(1)}
              className={`stepper-commandes-tab ${
                index === 1 && "stepper-active-commandes-tab"
              }`}
            >
              Mes achats
            </button>
          </div>
        </div>
        <div className="col-md-12 maajjal-table-card px-3 mx-2">
          {index === 0 && <MesCommandesTable />}
          {index === 1 && <MesAchatsTable />}
        </div>
      </div>
    </MesCommandesContainer>
  );
}

function MesCommandesVendeur() {
  return (
    <MesCommandesContainer>
      <div className="row">
        <div className="col-md-12">
          <h3 className="tb-mes-projet-titre mb-4">Mes commandes</h3>
        </div>
        <div className="col-md-12">
          <div className="dashboard-commandes-tab-container">
            <button
              className={`stepper-commandes-tab stepper-active-commandes-tab`}
            >
              Mes achats
            </button>
          </div>
        </div>
        <div className="col-md-12 maajjal-table-card px-3 mx-2">
          <MesAchatsTable />
        </div>
      </div>
    </MesCommandesContainer>
  );
}

export default () => {
  const user = useAppSelector((s) => s.user.user);
  if (user?.is_double_type) return <MesCommandesCommercant />;

  return <MesCommandesVendeur />;
};
