import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiUrl } from "../../constants";
import { PaginationResults, QueryUrl } from "../common.api";
import { prepareHeaders } from "../user/user.api";
import { Avis, AvisFormData } from "./avis.type";

type AvisQUery = {
  page?: number;
};

const AvisApi = createApi({
  reducerPath: "avis",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["avis"],
  endpoints: (build) => ({
    avisByVisitor: build.query<PaginationResults<Avis>, AvisQUery>({
      query: (query) => QueryUrl("/avisbyvisitor/", query),
      providesTags: ["avis"],
    }),

    addAvis: build.mutation<Avis, { slug: string; data: AvisFormData }>({
      query: ({ slug, data }) => {
        if (!slug)
          return {
            url: `/avis/`,
            method: "POST",
            body: data,
          };
        return {
          url: `/avis/${slug}/`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["avis"],
    }),
    deleteAvis: build.mutation<Avis, string>({
      query: (slug) => ({
        url: `/avis/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["avis"],
    }),
  }),
});

export const {
  useAvisByVisitorQuery,
  useAddAvisMutation,
  useDeleteAvisMutation,
} = AvisApi;

export default AvisApi;
