import React, { useEffect, useState } from "react";
import GoBackBtn from "../../../shared/GoBackBtn";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import "./UtilisateurAdmin.css";
import { getAvatar, getImageCarte, useLocationState } from "../../../../utils/utils";
import { User } from "../../../../utils/api/user/user.type";
import { useDeleteUserMutation } from "../../../../utils/api/user/user.api";

function DetailsUtilisateurAdmin() {
	const [user, setUser] = useState<User>();
	const itemState = useLocationState<any>(undefined);
	useEffect(() => {
		if (itemState?.user) {
			setUser(itemState.user);
		}
	}, [itemState]);
	return (
		<div className="parametre-page">
			<TopBar index={2} />
			<SideBarAdmin index={2} />
			<div className="parametre-component m-tableau-bord pt-3 flex-r">
				<div className="admin-page-container">
					<div className="p4 p-xxl-3">
						<GoBackBtn
							className="mad-donnateur-infos-perso-boutton-back"
							label="Retour"
						/>
						<div className="row">
							<div className="col-md-12 maajjal-table-card px-3 py-4 ml-2 mr-3">
								<div className="maj-admin-infos-utilisateur-img-container mb-4">
									<img
										src={getAvatar(user?.avatar)}
										alt="img user"
										className="maj-admin-infos-utilisateur-img"
									/>
									<p className="maj-admin-infos-utilisateur-label">
										{user?.first_name} {user?.last_name}
									</p>
								</div>

								<div className="mad-donnateur-infos-perso-nom-container mb-3">
									<div className="mad-donnateur-infos-perso-nom-label">
										Nom
									</div>
									<div className="mad-donnateur-infos-perso-nom">
										{user?.last_name}
									</div>
								</div>
								<div className="mad-donnateur-infos-perso-nom-container mb-3">
									<div className="mad-donnateur-infos-perso-nom-label">
										Prenom
									</div>
									<div className="mad-donnateur-infos-perso-nom">
										{user?.first_name}
									</div>
								</div>
								<div className="mad-donnateur-infos-perso-nom-container mb-3">
									<div className="mad-donnateur-infos-perso-nom-label">
										Téléphone
									</div>
									<div className="mad-donnateur-infos-perso-nom">
										{user?.phone}
									</div>
								</div>
								<div className="mad-donnateur-infos-perso-nom-container mb-3">
									<div className="mad-donnateur-infos-perso-nom-label">
										Email
									</div>
									<div className="mad-donnateur-infos-perso-nom">
										{user?.email}
									</div>
								</div>
								<div className="mad-donnateur-carte-identite-title mb-3 mt-4">
									Carte didentité
								</div>
								<div className="mad-donnateur-infos-perso-nom-container mb-3">
									<div className="mad-donnateur-infos-perso-nom-label">
										Numero CNI
									</div>
									<div className="mad-donnateur-infos-perso-nom">
										{user?.cni}
									</div>
								</div>

								<div className="maj-admin-infos-utilisateur-identite-img-container my-5">
									<img
										src={getImageCarte(user?.recto_cni)}
										alt="identite img"
										className="maj-admin-infos-utilisateur-identite-img"
										loading="lazy"
									/>
									<img
										src={getImageCarte(user?.verso_cni)}
										alt="identite img"
										className="maj-admin-infos-utilisateur-identite-img"
										loading="lazy"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DetailsUtilisateurAdmin;
