import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiUrl } from "../../constants";
import ProjetApi from "../launchCampagn/projet.api";
import { prepareHeaders } from "../user/user.api";
import { Invest, InvestCreatedData, InvestFormData } from "./invest.type";

const InvestApi = createApi({
  reducerPath: "invest",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["invest", "invest_projet"],
  endpoints: (build) => ({
    investsbyUser: build.query<Invest[], number>({
      query: (id) => `/investbyuser/${id}/`,
      providesTags: ["invest"],
    }),
    investsbyProjet: build.query<Invest[], number>({
      query: (id) => `/investbyprojet/${id}/`,
      providesTags: ["invest_projet"],
    }),
    add: build.mutation<
      InvestCreatedData,
      { admin?: boolean; data: InvestFormData }
    >({
      query: ({ admin, data }) => ({
        url: admin ? `/invest_admin/` : `/invest/`,
        method: "POST",
        body: { ...data, ...(admin ? { status: "valide" } : {}) },
      }),
      onCacheEntryAdded(arg, { dispatch }) {
        dispatch(
          ProjetApi.util.invalidateTags(["listProjet", "projet", "projet_user"])
        );
      },
      invalidatesTags: ["invest", "invest_projet"],
    }),
    update: build.mutation<
      InvestCreatedData,
      Pick<Invest, "commentaire" | "id">
    >({
      query: ({ id, commentaire }) => ({
        url: `/invest/${id}/`,
        method: "PUT",
        body: { commentaire },
      }),
      invalidatesTags: ["invest", "invest_projet"],
    }),
  }),
});

export default InvestApi;
