import { useStep } from "react-hooks-helper";
import { AiFillEdit } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/store";
import { useProjetFromLocation } from "../../../../../utils/api/launchCampagn/projet.api";
import { Projet } from "../../../../../utils/api/launchCampagn/projet.type";
import { useModalActions } from "../../../../../utils/utils";
import { getRoutePath } from "../../../../routes/routes";
import FrontFooter from "../../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../../shared/FrontHeader/FrontHeader";
import Sidebar from "../../Sidebar/Sidebar";
import { DeleteProjectModal } from "../DeleteProjectModal";
import "./DetailsProjet.css";
import DetailProjet from "./TabComponents/DetailProjet";
import Progression from "./TabComponents/Progression";

const steps: any = [
  { id: "detail-projet", Component: DetailProjet },
  { id: "progression", Component: Progression },
];

export function DeleteProjetButton({
  item,
  className,
  iconClassName,
  modalId,
}: {
  item: Projet;
  className?: string;
  iconClassName?: string;
  modalId?: string;
}) {
  modalId = modalId || "DeleteProjectModal";
  const ma = useModalActions(modalId);
  return (
    <>
      <DeleteProjectModal
        modalId={modalId}
        onHide={() => ma.hide()}
        projet={item}
      />
      <button onClick={() => ma.show()} className={className}>
        <FiTrash2 className={iconClassName} />
      </button>
    </>
  );
}

function DetailsProjet() {
  return (
    <div className="campagne-page-container">
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={0} />
          <div className="col-md-8 col-lg-9 col-xl-10">
            <DetailsProjetComponent />
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default DetailsProjet;

export function DetailsProjetComponent() {
  const [item, isLoading] = useProjetFromLocation();
  const isAdmin = useAppSelector((s) => s.user.user?.user_type === "admin");

  const {
    navigation: { go },
    index,
  } = useStep({ initialStep: 0, steps });
  if (!item) return null;
  return (
    <>
      <div className="details-projet-tab-container">
        <div className="details-projet-tab-div1">
          <button
            onClick={() => go?.(0)}
            className={`stepper-detail-projet1 ${
              index === 0 && "stepper-active-detail-projet1"
            }`}
          >
            Détail projet
          </button>
          <button
            onClick={() => go?.(1)}
            className={`stepper-detail-projet1 ${
              index === 1 && "stepper-active-detail-projet1"
            }`}
          >
            Progression
          </button>
        </div>
        <div className="details-projet-tab-div2">
          <NavLink
            to={getRoutePath(isAdmin ? "UpdateProject" : "UpdateCampain", {
              slug: item.slug,
            })}
            state={item}
            className={`page-detail-projet-tab-icon`}
          >
            <AiFillEdit />
          </NavLink>
          <DeleteProjetButton
            item={item}
            className={`page-detail-projet-tab-icon`}
          />
        </div>
      </div>

      <div className="p-md-5 p-3">
        <div className="row">
          <div className="col-md-12">
            {index === 0 && <DetailProjet item={item} />}
            {index === 1 && <Progression item={item} />}
          </div>
        </div>
      </div>
    </>
  );
}
