import { useEffect } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import Swal from "sweetalert2";
import CategorieApi from "../../../../utils/api/categorie/categorie.api";
import { Categorie } from "../../../../utils/api/categorie/categorie.type";
import { COLORS } from "../../../../utils/constants";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import "./Faq.css";
import TeamForm from "./TeamForm";

const Category = () => {
  const { data = [] } = CategorieApi.useGetCategorieQuery();

  const [deleteTeam, { isError, error, isSuccess }] =
    CategorieApi.useDeleteCategoryMutation();
  const onDeleteFaq = (slug: number) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cette catégorie",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn  mx-2",
      },
      preConfirm: () => {
        return deleteTeam(slug);
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Catégorie supprimée avec succès",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);

  return (
    <div className="parametre-page">
      <TopBar index={5} />
      <SideBarAdmin index={5} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="maajjal-card maajjal-admin-card px-2 py-2 p-xxl-3">
            <h1 className="admin-component-title">Catégorie projet</h1>
            <div className="messages-container">
              <div className="maajjal-card maajjal-admin-card px-2 py-2 p-xxl-3">
                <div className="faq-add-btn-container flex-cc">
                  <button
                    className="faq-add-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#AddCategory"
                  >
                    <BsPlusCircle style={{ fontSize: 20 }} />
                    Ajouter
                  </button>
                </div>
                <div className="faq-container mt-5">
                  {data.map((item) => (
                    <div key={item.id}>
                      <div className="faq-card-item px-3 py-3 mb-3">
                        <div className="info-title-container-container flex-fill">
                          <h3 className="faq-card-title">{item.name}</h3>
                        </div>
                        <div className="faq-info-action-container">
                          <div className="action-btn-container d-flex gap-2">
                            <ResponseButton item={item} />
                            <button
                              className="faq-action-btn border-red"
                              type="button"
                              onClick={() => onDeleteFaq(item.id)}
                            >
                              <FiTrash2 />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <TeamForm modalId="AddCategory" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;

export function ResponseButton({ item }: { item: Categorie }) {
  const modalId = `UpdateCategory${item.id}`;
  return (
    <>
      <button
        className="faq-action-btn border-blue-dark"
        {...{
          "data-bs-toggle": "modal",
          "data-bs-target": `#${modalId}`,
        }}
      >
        <MdModeEdit />
      </button>
      <TeamForm modalId={modalId} item={item} />
    </>
  );
}
