import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import "react-loading-skeleton/dist/skeleton.css";
import { Provider } from "react-redux";
import * as yup from "yup";
import { fr } from "yup-locales";
import App from "./App";
import "./fonts/Gilroy-Bold.woff";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { GOOGLE_CLIENT_ID } from "./utils/constants";
yup.setLocale(fr);
Sentry.init({
  dsn: "https://a36b2dda584f402b9acb10e4bd3efe2d@app-sentry.withvolkeno.com/4",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
    </HelmetProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
