import moment from "moment";
import React from "react";
import { Projet } from "../../../../../../utils/api/launchCampagn/projet.type";
import LikeApi from "../../../../../../utils/api/like/like.api";
import { EmptyMessage } from "../../../../../../utils/emptyMessage";
import { getAvatar } from "../../../../../../utils/utils";
import Pagination from "../../../../../shared/Pagination";

function Aime({ projet }: { projet: Projet }) {
  const [page, setPage] = React.useState(1);
  const { data = { results: [] } } = LikeApi.useLikesbyProjetQuery({
    id: projet.id as number,
    page: page,
  });
  return (
    <div>
      {data.results.length === 0 && EmptyMessage("Pas de j'aime disponible")}
      {data.results.map((item) => (
        <div key={item.id} className="row liste-don-table-row">
          <div className="col-2 col-md-1 d-flex justify-content-center ">
            <img
              src={getAvatar(item.user?.avatar)}
              className="mesprojet-details-projet-table-img"
              alt="img campagne"
            />
          </div>
          <div className="col-10 col-md-11">
            <div className="liste-don-table-name-container">
              <div className="liste-don-table-name">
                {item.user?.first_name} {item.user?.last_name}
              </div>
              <div className="liste-don-table-date">
                {moment(item.created_at).format("DD/MM/YYYY")}
              </div>
            </div>
          </div>
        </div>
      ))}
      <Pagination page={page} onPageChange={setPage} total={data.count} />
    </div>
  );
}

export default Aime;
