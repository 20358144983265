import { Avis } from "../../../utils/api/avis/avis.type";
import { getAvatar } from "../../../utils/utils";

const Testimonials = ({ avis }: { avis: Avis[] }) => {
  return (
    <div className="testimonial-component">
      <div className="row">
        {avis.map((avi) => (
          <div key={avi.id} className="col-md-6 pb-3">
            <div className="card-testimonial-item">
              <div>
                <p className="test-testimonial">{avi.avis}</p>
              </div>
              <div className="bloc-img-testimonial">
                <div>
                  <img
                    src={getAvatar(avi.avatar)}
                    alt="testimonials"
                    className="img-testimonial"
                  />
                </div>
                <div className="ps-3">
                  <p className="nom-partner-testimonial m-0">{avi.fullname}</p>
                  <p className="profession-partner-testimonial m-0">
                    {avi.fonction}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Testimonials;
