import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import { AiOutlineEdit } from "react-icons/ai";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../../../../utils/api/user/user.api";
import { User } from "../../../../../utils/api/user/user.type";
import { COLORS } from "../../../../../utils/constants";
import { formattedDate } from "../../../../../utils/utils";
import Pagination from "../../../../shared/Pagination";
import UtilisateurAdminSkeleton from "./UtilisateurAdminSkeleton";

function UtilisateurAdminTable({ word }: { word: string }) {
  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(1);
  }, [word]);
  const { data = { results: [] }, isLoading } = useGetUsersQuery({
    page,
    word: word,
  });
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser, { isSuccess, isError, error }] = useDeleteUserMutation();
  const onUpdate = (user: User) => {
    Swal.fire({
      title: `Modifier les points de ${user.first_name} ${user.last_name}`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Modifier",
      cancelButtonText: "Annuler",
      showLoaderOnConfirm: true,
      input: "number",
      inputLabel: "Points",
      inputPlaceholder: "Points",
      inputValue: String(user.point),
      inputAttributes: {},
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn  mx-2",
      },
      preConfirm: async (point: number) => {
        const res = await updateUser({ id: user?.id, data: { point } });
        // @ts-ignore
        if (res?.data) {
          Swal.fire({
            icon: "success",
            title: "Utilisateur modifié avec succès",
            iconColor: COLORS.blue,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Utilisateur non modifié",
            iconColor: COLORS.red,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      },
    });
  };
  const onDeleteItem = (slug) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cet utilisateur",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn  mx-2",
      },
      preConfirm: () => {
        return deleteUser(slug);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
      }
    });
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Utilisateur supprimé avec succès",
        iconColor: COLORS.blue,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      console.log(err);

      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);

  const actionFormatter: any = (cell, row) => {
    return (
      <div className="table-actions-btn-container-assurance admin-utilisateur-table-btn-action">
        <NavLink
          className="action-btn "
          title="Details"
          to={`/admin/utilisateurs/${row?.slug}}`}
          state={{ user: row }}
        >
          <FiEye className="voir-btn " />
        </NavLink>

        <button
          className="no-style-btn"
          title="Supprimer"
          onClick={() => onDeleteItem(row?.id)}
        >
          <FiTrash2 className="voir-btn" />
        </button>
        <button
          className="no-style-btn"
          title="Modifier"
          onClick={() => onUpdate(row)}
        >
          <AiOutlineEdit className="edit-btn" />
        </button>
      </div>
    );
  };
  const nameFormatter: any = (cell, row) => {
    return row?.first_name + " " + row?.last_name;
  };
  const dateFormatter: any = (cell, row) => {
    return formattedDate(cell);
  };

  const columns = [
    {
      dataField: "last_name",
      text: "Prénom & Nom",
      formatter: (cell: any, row: any) => nameFormatter(cell, row),
    },
    {
      dataField: "phone",
      text: "Téléphone",
    },
    {
      dataField: "date_joined",
      text: "Date d'inscription",
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <UtilisateurAdminSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data.results}
          columns={columns}
          striped
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive"
          rowStyle={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "21px",
            color: "rgba(0, 0, 0, 0.7)",
          }}
          headerClasses="header-class"
          filter={filterFactory()}
        />
      )}
      <Pagination page={page} onPageChange={setPage} total={data.count} />
    </>
  );
}

export default UtilisateurAdminTable;
