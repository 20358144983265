import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiUrl } from "../../constants";
import { PaginationResults, QueryUrl } from "../common.api";
import { prepareHeaders } from "../user/user.api";
import { Contact, ContactFormData, ResponseContact } from "./contact.type";

const ContactApi = createApi({
  reducerPath: "contact",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["contact"],
  endpoints: (build) => ({
    getContact: build.query<PaginationResults<Contact>, { page: number }>({
      query: (query) => QueryUrl("/contacts/", query),
      providesTags: ["contact"],
    }),
    addContact: build.mutation<Contact, ContactFormData>({
      query: (data) => ({
        url: `/contact/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["contact"],
    }),
    responseContact: build.mutation<Contact, ResponseContact>({
      query: (data) => ({
        url: `/response_contact/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["contact"],
    }),
  }),
});

export const {
  useAddContactMutation,
  useGetContactQuery,
  useResponseContactMutation,
} = ContactApi;

export default ContactApi;
