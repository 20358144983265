import BtnCloseModal from "../../../shared/BtnCloseModal";
import BtnSubmit from "../../../shared/BtnSubmit";

import { Team } from "../../../../utils/api/team/team.type";
import { useModalActions } from "../../../../utils/utils";
import useTeamForm from "./form/useTeamForm";

const TeamForm = ({ item, modalId }: { item?: Team; modalId: string }) => {
  const ma = useModalActions(modalId);
  const { errors, handelImageChange, isLoading, onSubmit, preview, register } =
    useTeamForm(ma.hide, item);

  return (
    <div className="modal fade" id={modalId}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content px-4 py-3">
          <div className="flex-csb mb-4">
            <h1 className="admin-modal-title m-0">
              {item ? "Modifier" : "Ajouter"} un membre
            </h1>
            <BtnCloseModal />
          </div>
          <div className="reponse-form-container">
            <form onSubmit={onSubmit}>
              <div className="form-group mb-4">
                <label htmlFor="fullname" className="majjal-label mb-2">
                  Prénom & Nom
                </label>
                <input
                  type="text"
                  className="form-control-majjal form-control form-control-lg bg-slate"
                  id="fullname"
                  {...register("fullname")}
                />
                {errors.fullname && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.fullname?.message?.toString()}
                  </div>
                )}
              </div>
              <div className="form-group mb-4">
                <label htmlFor="fonction" className="majjal-label mb-2">
                  Fonction
                </label>
                <input
                  type="text"
                  className="form-control-majjal form-control form-control-lg bg-slate"
                  id="fonction"
                  {...register("fonction")}
                />
                {errors.fonction && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.fonction?.message?.toString()}
                  </div>
                )}
              </div>
              <div className="form-group mb-4">
                <label htmlFor="avatar" className="majjal-label mb-2">
                  Image
                </label>
                <input
                  type="file"
                  className="form-control-majjal form-control form-control-lg bg-slate"
                  id="avatar"
                  accept="image/*"
                  onChange={handelImageChange}
                />
                {errors.avatar && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.avatar?.message?.toString()}
                  </div>
                )}
              </div>
              {preview && (
                <div className="row mb-2">
                  <div className="col-md-6">
                    <img
                      src={preview}
                      id="output"
                      width="100%"
                      height={"auto"}
                    />
                  </div>
                </div>
              )}
              <div className="btn-container flex-r mt-5">
                {isLoading && (
                  <button disabled className="admin-submit-btn">
                    En cours&nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isLoading && (
                  <BtnSubmit label={item ? "Modifier" : "Ajouter"} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamForm;
