import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../redux/store";
import { useAddContactMutation } from "../../../../utils/api/contact/contact.api";
import { ContactFormData } from "../../../../utils/api/contact/contact.type";
import { COLORS } from "../../../../utils/constants";

function UseContactForm() {
  const navigate = useNavigate();
  const { user } = useAppSelector((s) => s.user);
  const validationSchema = yup.object().shape({
    message: yup.string().required().label("Le message"),
    email_from: yup.string().email().required().label("L'email"),
    fullname: yup.string().label("Le nom complet").required(),
  });

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    clearErrors,
    setError,
    reset,
  } = useForm<ContactFormData | any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      message: "",
      email_from: user?.email || "",
      fullname: user ? `${user.first_name} ${user.last_name}` : "",
      objet: "",
      ...(user ? { user: user.id } : {}),
    },
  });

  const [sendData, { isLoading, isSuccess, isError, error, data }] =
    useAddContactMutation();

  useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        Object.entries(formState?.errors).map(([key]: any) => {
          window.scrollTo(10, 10);
          clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  useEffect(() => {
    if (isError) {
      console.log("err", error);
      const err = error as any;
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {};
      Swal.fire({
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      console.log("success", data);
      Swal.fire({
        icon: "success",
        title: "Message envoyé avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        reset();
        navigate("/");
      });
    }
  }, [isSuccess]);
  const onSubmit = async (formValues) => {
    await sendData(formValues);
  };
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    formState,
    setValue,
    isLoading,
    setError,
  };
}

export default UseContactForm;
