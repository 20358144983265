import moment from "moment";
import { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/store";
import {
  useDeleteFaqMutation,
  useGetFaqQuery,
} from "../../../../utils/api/faq/faq.api";
import { Faq } from "../../../../utils/api/faq/faq.type";
import { COLORS } from "../../../../utils/constants";
import { getRoutePath } from "../../../routes/routes";
import Pagination from "../../../shared/Pagination";
import AjouterFaqModal from "../../Modals/Faq/AjouterFaqModal";
import ModifierFaqModal from "../../Modals/Faq/ModifierFaqModal";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import "./Faq.css";
const toNumber = (date: any) => moment(date).toDate().getTime();
const FaqAdmin = () => {
  const [page, setPage] = useState(1);
  const { data = { results: [] } } = useGetFaqQuery({ page });
  const [deleteFaq, { isError, error, isSuccess }] = useDeleteFaqMutation();
  // console.log("data",data)

  const onDeleteFaq = (slug) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cette question",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn  mx-2",
      },
      preConfirm: () => {
        return deleteFaq(slug);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
      }
    });
  };
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Question supprimée avec succès",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      console.log(err);

      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);

  return (
    <div className="parametre-page">
      <TopBar index={7} />
      <SideBarAdmin index={7} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="maajjal-card maajjal-admin-card px-2 py-2 p-xxl-3">
            <h1 className="admin-component-title">FAQ</h1>
            <div className="faq-add-btn-container flex-cc">
              <button
                className="faq-add-btn"
                data-bs-toggle="modal"
                data-bs-target="#ajouterFaqModal"
              >
                <BsPlusCircle style={{ fontSize: 20 }} />
                Nouvelle question
              </button>
            </div>
            <div className="faq-container mt-5">
              {data?.results?.map((faq, index) => (
                <div key={index}>
                  <div className="faq-card-item px-3 py-3 mb-3">
                    <div
                      className="info-title-container-container flex-fill"
                      data-bs-toggle="collapse"
                      data-bs-target={`#faqContentCollapse${index}`}
                      aria-expanded="false"
                      aria-controls="faqContentCollapse"
                      role="button"
                    >
                      <div className="d-flex gap-3">
                        <div>
                          <IoIosArrowForward className="custom-faq-arrow" />
                        </div>
                        <div>
                          <h3 className="faq-card-title">{faq?.question}</h3>
                          <p className="faq-publish-date">
                            Le {moment(faq?.created_at).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq-info-action-container">
                      <div className="text-number-consultation-container">
                        <div>{faq?.nb_consultation}</div>
                        <div className="signe">Consultations</div>
                      </div>
                      <div className="action-btn-container d-flex gap-2">
                        <ResponseButton faq={faq} />
                        <button
                          className="faq-action-btn border-red"
                          type="button"
                          onClick={() => onDeleteFaq(faq.id)}
                        >
                          <FiTrash2 />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="collapse" id={`faqContentCollapse${index}`}>
                    <div className="card card-body custom-admin-faq-collapse">
                      {faq.responses.length === 0 && (
                        <div className="accordion-body">
                          Pas encore de réponse
                        </div>
                      )}
                      {faq?.responses.map((r) => {
                        return <p key={r.id}>{r.reponse}</p>;
                      })}
                    </div>
                  </div>
                </div>
              ))}
              <Pagination
                page={page}
                onPageChange={setPage}
                total={data.count}
              />
            </div>
            <AjouterFaqModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAdmin;

export function ResponseButton({
  faq,
  modalId,
}: {
  faq: Faq;
  modalId?: string;
}) {
  const navigate = useNavigate();
  const userId = useAppSelector((s) => s.user?.user?.id);
  modalId = modalId || `modifierFaqModal${faq.id}`;
  const buttonProps = userId
    ? {
        "data-bs-toggle": "modal",
        "data-bs-target": `#${modalId}`,
      }
    : {
        onClick: () => {
          navigate(getRoutePath("login"));
        },
      };
  return (
    <>
      <button className="faq-action-btn border-blue-dark" {...buttonProps}>
        <MdModeEdit />
      </button>
      <ModifierFaqModal modalId={modalId} item={faq} />
    </>
  );
}
