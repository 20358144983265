import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "../../utils/api/user/user.type";
import { AppStorage } from "../../utils/storage";

export type UserState = {
    user?: User;
    token?: string;

}

const initialState = AppStorage.getItem<UserState>("user", {
    user: undefined,
    token: undefined,
  });

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        onSetUser: (state, action: PayloadAction<UserState["user"]>) => {
          state.user = action.payload;
          AppStorage.setItem("user", state);
        },
        onSetToken: (state, action: PayloadAction<UserState["token"]>) => {
          state.token = action.payload;
          AppStorage.setItem("user", state);
        },
        onSetUserToken: (state, action: PayloadAction<UserState>) => {
          state.user = action.payload.user;
          state.token = action.payload.token;
          AppStorage.setItem("user", state);
        },
        onLogout: (state) => {
          state.user = undefined;
          state.token = undefined;
          AppStorage.setItem("user", state);
          // @ts-ignore
          window.location = "/";
      },
    }

})

export const { onLogout, onSetToken, onSetUser, onSetUserToken } = userSlice.actions;

export default userSlice.reducer;