import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiUrl } from "../../constants";
import { UserApi } from "../user/user.api";
import {
  AuthFormData,
  ForgetPasswordFormData,
  LoginResult,
  RegisterFormData,
  RegisterSocialFormData,
  ResetPasswordFormData,
} from "./auth.type";

export const AuthApi = createApi({
  tagTypes: ["auth"],
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
  }),
  endpoints: (builder) => ({
    register: builder.mutation<LoginResult["data"], RegisterFormData>({
      query: (data) => ({
        url: `/auth/register/`,
        method: "POST",
        body: data,
      }),
      transformResponse: ({ data }) => data,
      invalidatesTags: ["auth"],
    }),

    login: builder.mutation<LoginResult, AuthFormData>({
      query: (data) => ({
        url: `/auth/login/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
      onCacheEntryAdded(arg, { dispatch }) {
        dispatch(UserApi.util.invalidateTags(["user"]));
        //   dispatch(CandidatureApi.util.invalidateTags(["candidatures"]));
      },
    }),

    forgetPassword: builder.mutation<any, ForgetPasswordFormData>({
      query: (data) => ({
        url: `/request-password-reset/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    resetPassword: builder.mutation<any, ResetPasswordFormData>({
      query: (data) => ({
        url: `/reset-password/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    registerSocial: builder.mutation<LoginResult, RegisterSocialFormData>({
      query: (data) => ({
        url: `/auth/registersocial/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useRegisterSocialMutation,
} = AuthApi;
