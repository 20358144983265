import moment from "moment";
import InvestApi from "../../../../../../utils/api/invest/invest.api";
import { Projet } from "../../../../../../utils/api/launchCampagn/projet.type";
import { EmptyMessage } from "../../../../../../utils/emptyMessage";
import { formatAmout, getAvatar } from "../../../../../../utils/utils";

function ListeDonateurs({ projet }: { projet: Projet }) {
  const { data = [] } = InvestApi.useInvestsbyProjetQuery(projet.id);
  return (
    <div className="liste-don-table-container">
      {data.length === 0 && EmptyMessage("Pas de donateur disponible")}
      {data.map((item) => (
        <div key={item.id} className="row liste-don-table-row">
          <div className="col-2 col-md-1 d-flex justify-content-center ">
            <img
              src={getAvatar(item.anonyme ? "" : item.user_id?.avatar)}
              className="mesprojet-details-projet-table-img"
              alt="img campagne"
            />
          </div>
          <div className="col-6 col-md-6">
            <div className="liste-don-table-name-container">
              {!item.anonyme && (
                <div className="liste-don-table-name">
                  {item.prenom} {item.nom}
                </div>
              )}
              {item.anonyme && (
                <div className="liste-don-table-name">Anonyme</div>
              )}
              {!!item.commentaire && <small>{item.commentaire}</small>}
              <div className="liste-don-table-date">
                {moment(item.created_at).format("DD/MM/YYYY")}
              </div>
            </div>
          </div>
          <div className="col-4 col-md-5">
            <div className="liste-don-table-prix">
              {formatAmout(item.funds)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ListeDonateurs;
