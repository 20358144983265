import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect } from "react";
import { useAppSelector } from "../../../redux/store";
import { ApiUrl } from "../../constants";
import { PaginationResults, QueryUrl } from "../common.api";
import { prepareHeaders } from "../user/user.api";
import { Like, LikeFormData } from "./like.type";

const LikeApi = createApi({
  reducerPath: "likes",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["like", "like_projet"],
  endpoints: (build) => ({
    likesbyUser: build.query<Like[], number>({
      query: (id) => `/likebyuser/${id}/`,
      providesTags: ["like"],
    }),
    likesbyProjet: build.query<
      PaginationResults<Like>,
      { page?: number; id: number }
    >({
      query: ({ id, ...query }) => QueryUrl(`/likebyprojet/${id}/`, query),
      providesTags: ["like_projet"],
    }),
    maj: build.mutation<Like, LikeFormData>({
      query: ({ id, ...data }) => ({
        url: id ? `/like/${id}/` : "/like/",
        method: id ? "DELETE" : "POST",
        body: data,
      }),
      invalidatesTags: ["like", "like_projet"],
    }),
  }),
});

export default LikeApi;

export function useLikes() {
  const user = useAppSelector((s) => s.user.user);
  const [fetch, { data = [] }] = LikeApi.useLazyLikesbyUserQuery();
  useEffect(() => {
    if (user?.id) {
      fetch(user?.id);
    }
  }, [user?.id]);
  return data;
}
