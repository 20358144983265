import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../../redux/store";
import { useAddCommentMutation } from "../../../../../utils/api/commentaire/commentaire.api";
import { CommentaireFormData } from "../../../../../utils/api/commentaire/commentaire.type";
import { useProjetFromLocation } from "../../../../../utils/api/launchCampagn/projet.api";
import { COLORS } from "../../../../../utils/constants";

function UseAddCommentForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        commentaire: yup.string().required("Le champs est requis"),
      }),
    []
  );

  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const [item] = useProjetFromLocation();

  // console.log('item test', item)

  const [sendData, { isSuccess, isError, isLoading, error }] =
    useAddCommentMutation();

  const { register, handleSubmit, formState, clearErrors, setValue, reset } =
    useForm<CommentaireFormData>({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        commentaire: "",
      },
    });

  const user = useAppSelector((s) => s.user);

  // console.log('user', user)

  const onSubmit = async (data: CommentaireFormData) => {
    // console.log(data);
    const datas = {
      ...data,
      user: user?.user?.id,
      projet: item?.id,
    };
    await sendData(datas);

    // console.log("datas", datas)
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    // console.log("response", isSuccess, isLoading, isError, error);
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Commentaire ajouté avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        reset();
      });
    }

    if (isError) {
      const err = error as any;
      //   const failErr = fail as any;
      setMessage(
        err?.data?.message?.toString()
          ? err?.data?.message?.toString()
          : //   : failErr?.data?.message?.toString()
            //   ? failErr?.data?.message?.toString()
            `Une erreur de statut ${
              err?.status
                ? err?.status
                : // : failErr?.status
                  // ? failErr?.status
                  "inconnu"
            } est survenue.`
      );
    }
  }, [isSuccess, isError, error]);

  return {
    register,
    errors: formState.errors,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    setValue,
    isLoading,
    isSuccess,
  };
}

export default UseAddCommentForm;
