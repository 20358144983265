import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { useAppSelector } from "../../../../redux/store";
import { useAchatsByUserQuery } from "../../../../utils/api/achat/achat.api";
import { usePartageByFilterQuery } from "../../../../utils/api/user/user.api";
import { SharePoint } from "../../../../utils/api/user/user.type";
import { formatAmout } from "../../../../utils/utils";
import MesOffresSkeleton from "./MesPointsSkeleton";

const MesOffresTable = () => {
  const user = useAppSelector((s) => s.user.user);
  const { data = [], isLoading } = useAchatsByUserQuery(user?.id as number);
  const filtred = data.filter(
    (d) => !["annuler", "refuser"].includes(d.status) && d.discount
  );
  const montantFormatter = (cell: any, row: any) => {
    return `${formatAmout(cell)}`;
  };

  //   const discountFormatter = (cell: any, row: any) => {
  //     return `${cell}%`;
  //   };

  const dateFormatter = (cell: any, row: any) => {
    return <span>{moment(cell).format("DD/MM/YYYY")}</span>;
  };

  const columns = [
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
      headerStyle: { fontWeight: 400 },
    },
    {
      dataField: "produit.name",
      text: "Produit acheté",
      headerStyle: { fontWeight: 400 },
    },
    {
      dataField: "montant_total",
      text: "Montant",
      formatter: (cell: any, row: any) => montantFormatter(cell, row),
      headerStyle: { fontWeight: 400 },
    },
    //  {
    //    dataField: "discount",
    //    text: "Discount",
    //    style: { verticalAlign: "middle" },
    //    formatter: (cell: any, row: any) => discountFormatter(cell, row),
    //    headerStyle: { fontWeight: 400 },
    //  },
    {
      dataField: "point",
      text: "Points",
      formatter: (cell) => `-${cell}`,
      style: { verticalAlign: "middle" },
      headerStyle: { fontWeight: 400 },
    },
  ];

  return (
    <>
      {isLoading && <MesOffresSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={filtred}
          columns={columns}
          bordered={false}
          condensed={false}
          responsive={true}
          wrapperClasses="table-responsive"
          rowStyle={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "21px",
            color: "rgba(0, 0, 0, 0.7)",
          }}
          headerClasses="table-header-style"
        />
      )}
    </>
  );
};

export default MesOffresTable;

export const SharedPointTable = ({ index }: { index: number }) => {
  const userId = useAppSelector((s) => s.user.user?.id as number);
  const { data = [], isLoading } = usePartageByFilterQuery({
    sender: index === 1 ? userId : undefined,
    receiver: index === 2 ? userId : undefined,
  });

  const dateFormatter = (cell: any, row: any) => {
    return <span>{moment(cell).format("DD/MM/YYYY")}</span>;
  };

  const columns = [
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
      headerStyle: { fontWeight: 400 },
    },
    {
      dataField: "sender",
      formatter: (_, row: SharePoint) => {
        const fn = (obj) => `${obj.first_name} ${obj.last_name}`;
        if (index === 1) return fn(row.receiver);
        if (index === 2) return fn(row.sender);
      },
      text: index === 1 ? "Envoyé à" : "Envoyé par",
      headerStyle: { fontWeight: 400 },
    },
    {
      dataField: "point",
      text: "Points",
      formatter: (cell) => (index === 1 ? `-${cell}` : cell),
      style: { verticalAlign: "middle" },
      headerStyle: { fontWeight: 400 },
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={data}
        columns={columns}
        bordered={false}
        condensed={false}
        responsive={true}
        wrapperClasses="table-responsive"
        rowStyle={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "21px",
          color: "rgba(0, 0, 0, 0.7)",
        }}
        headerClasses="table-header-style"
      />
    </>
  );
};
