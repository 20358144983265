import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { User } from "../../../../../utils/api/user/user.type";
import { formatAmout } from "../../../../../utils/utils";
import DashboardSkeleton from "./DashboardSkeleton";

function DashboardTable({
  data,
  isLoading,
}: {
  data: { user: User; montant: number }[];
  isLoading: boolean;
}) {
  const columns = [
    {
      dataField: "num",
      text: "N°",
      formatter: (cel, row, index) => {
        return index + 1;
      },
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "user.first_name",
      formatter: (cel, row) =>
        `${row?.user?.first_name} ${row?.user?.last_name}`,
      text: "Prénom & Nom",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "user.phone",
      text: "Téléphone",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "user.email",
      text: "Adresse mail",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {},
    {
      dataField: "montant",
      formatter: (cell) => formatAmout(cell),
      text: "Montant",
      style: {
        verticalAlign: "middle",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
  ];

  return (
    <>
      {isLoading && <DashboardSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive"
          striped={true}
        />
      )}
    </>
  );
}

export default DashboardTable;
