import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiOutlineEdit } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../redux/store";
import { useProjetsByUserQuery } from "../../../../utils/api/launchCampagn/projet.api";
import { formatAmout } from "../../../../utils/utils";
import { getRoutePath } from "../../../routes/routes";
import { DeleteProjetButton } from "../../TableauDeBord/mesprojets/DetailsProjet/DetailsProjet";
import ListeProjetsSkeleton from "./ListeProjetsSkeleton";

function MesProjetsTable() {
  const userId = useAppSelector((s) => s.user.user?.id as number);
  const [page, setPage] = React.useState(1);
  const { data = { results: [] }, isLoading } = useProjetsByUserQuery({
    page,
    id: userId,
  });
  const etatFormat = (cell: any, row: any) => {
    const status = {
      online: { class: "etat-projet-en-ligne", text: "En ligne" },
      offline: { class: "etat-projet-hors-ligne", text: "Hors ligne" },
      termine: { class: "etat-projet-en-termine", text: "Terminé" },
    };
    const obj = status[cell] || status.offline;
    return (
      <>
        <div className={obj.class}>
          <span className="text-statut-format">{obj.text}</span>
        </div>
      </>
    );
  };

  const actionFormatter: any = (cell, row) => {
    return (
      <div className="table-actions-btn-container-projet">
        <NavLink
          className="action-btn "
          title="Voir les détails"
          state={row}
          to={getRoutePath("DetailsProjet", { slug: row.slug })}
        >
          <FiEye className="voir-btn" />
        </NavLink>
        <DeleteProjetButton
          modalId={`DeleteProject${row.id}`}
          item={row}
          className="page-detail-projet-tab-icon"
          iconClassName={`supprimer-btn`}
        />

        <NavLink
          to={getRoutePath("UpdateCampain", { slug: row.slug })}
          state={row}
          className="action-btn "
        >
          <AiOutlineEdit className="edit-btn" />
        </NavLink>
      </div>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Nom",
      style: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "date_debut",
      formatter: (cell) => moment(cell).format("DD/MM/YYYY"),
      text: "Date de début",
      style: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "date_fin",
      formatter: (cell) => moment(cell).format("DD/MM/YYYY"),
      text: "Date de fin",
      style: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "statut",
      text: "Etat actuel",
      formatter: (cell: any, row: any) => etatFormat(cell, row),
      style: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "funds",
      formatter: (cell) => formatAmout(cell),
      text: "Collectés",
      style: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "montant_total_debit",
      formatter: (cell) => formatAmout(cell),
      text: "Débités",
      style: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      style: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
      },
      headerStyle: {
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "700",
      },
    },
  ];

  return (
    <>
      {isLoading && <ListeProjetsSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data.results}
          columns={columns}
          // striped
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive"
          // expandRow={ expandRow }
        />
      )}
    </>
  );
}

export default MesProjetsTable;
