import { IoClose } from 'react-icons/io5'
type PropsType = {
  onClick?: any
}
const BtnCloseModal = ({ onClick }: PropsType) => {
  return (
    <button
      className="bg-transparent border-none"
      data-bs-dismiss="modal"
      aria-label="Close"
      onClick={() => onClick && onClick()}
    >
      <IoClose
        style={{
          color: '#021849',
          fontSize: 25,
        }}
      />
    </button>
  )
}

export default BtnCloseModal
