import React from 'react'
import Skeleton from 'react-loading-skeleton'

function ProjetsAdminSkeleton() {
  return (
    <div className="col-md-12 as-col-table-container">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>N°</th>
                        <th scope="col" style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Nom du projet</th>
                        <th scope="col" style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Nom du porteur</th>
                        <th scope="col" style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Montant demandé</th>
                        <th scope="col" style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Progression</th>
                        <th scope="col" style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Statut</th>
                        <th scope="col" style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.7)', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton /></th>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                    </tr>
                </tbody>
            </table>
        </div>
  )
}

export default ProjetsAdminSkeleton