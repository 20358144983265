import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ConditionApi from "../../../../../utils/api/condition/condition.api";
import { Condition } from "../../../../../utils/api/condition/condition.type";
import { COLORS } from "../../../../../utils/constants";
import { HtmlInput } from "../../Produits/AjoutProduitAdmin";

const AjoutConditionsEtTermes = () => {
  return <ContentForm type="cgu" />;
};

export default AjoutConditionsEtTermes;

export function ContentForm({ type }: { type: Condition["type"] }) {
  const useQuery =
    type === "cgu"
      ? ConditionApi.useCondition_generale_utilisationQuery
      : ConditionApi.useCondition_generale_venteQuery;
  const { data, isLoading } = useQuery();
  const [update, { isLoading: loading, isSuccess }] =
    ConditionApi.useAddConditionMutation();
  const [content, setContent] = useState("");
  useEffect(() => {
    if (data) {
      setContent(data.text);
    }
  }, [data]);
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Données modifiées avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isSuccess]);
  if (isLoading) return <h4>Chargement...</h4>;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        update({
          id: data?.id,
          data: {
            type,
            text: content,
          },
        });
      }}
    >
      <div className="form-group">
        <HtmlInput name="content" data={content} onChange={setContent} />
      </div>
      <div className="btn-container flex-r mt-5">
        {loading && (
          <button type="submit" className="admin-submit-btn">
            Chargement...
          </button>
        )}
        {!loading && (
          <button type="submit" className="admin-submit-btn">
            Modifier
          </button>
        )}
      </div>
    </form>
  );
}
