import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ApiUrl } from '../../constants'
import { prepareHeaders } from '../user/user.api'
import { MontantConfig, MontantConfigFormData } from './montantConfig.type'

const MontantConfigApi = createApi({
  reducerPath: 'montantConfig',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ['montantprojet'],
  endpoints: (build) => ({
    addMontantConfig: build.mutation<
      MontantConfig,
      MontantConfigFormData | any
    >({
      query: ({ slug, ...data }) => ({
        url: slug ? `/montantprojet/${slug}/` : `/montantprojet/`,
        method: slug ? 'PUT' : 'POST',
        body: data,
      }),
      invalidatesTags: ['montantprojet'],
    }),
    deleteMontantConfig: build.mutation<MontantConfig, string>({
      query: (slug) => ({
        url: `/montantprojet/${slug}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['montantprojet'],
    }),
    getMontantConfig: build.query<MontantConfig[], void>({
      query: () => `/montantprojetbyvisitor/`,
      providesTags: ['montantprojet'],
    }),
  }),
})

export const {
  useAddMontantConfigMutation,
  useGetMontantConfigQuery,
  useDeleteMontantConfigMutation,
} = MontantConfigApi

export default MontantConfigApi
