import { FiPlus } from "react-icons/fi";
import AjouterAdministrateurModal from "../../../Modals/Administrateur/AjouterAdministrateurModal";
import ListeAdminsTable from "../../../Tables/ListeAdmins/ListeAdminsTable";

const ListesDesAdmins = () => {
	return (
		<div>
			<div className="flex-r">
				<button
					className="open-modal-add-admin"
					data-bs-toggle="modal"
					data-bs-target="#ajoutAdminModal"
				>
					<FiPlus
						style={{
							color: "#021849",
							marginRight: 10,
						}}
					/>
					<span>Ajouter un administrateur</span>
				</button>
			</div>
			<ListeAdminsTable />
			<AjouterAdministrateurModal />
		</div>
	);
};

export default ListesDesAdmins;
