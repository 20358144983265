import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/store";
import Logo from "../../../../assets/appImage/MaajjalAdminlogo.png";
import VersionChecker from "../../../../utils/VersionChecker";
import { isAccessGranted } from "../../../Modals/Administrateur/ModifierAdministrateurModal";
import { Routes } from "../AdminRoutes/AminRoutes";
import "./SideBarAdmin.css";
interface PropsType {
  index: number;
}

const SideBarAdmin = ({ index }: PropsType) => {
  const user = useAppSelector((s) => s.user.user);
  const [active, setActive] = useState(index);
  const links = Routes;
  return (
    <div id="scrollbar-nav" className="sidebar-admin no-view-md">
      <div className="logo-container flex-cc pt-4">
        <NavLink to="/">
          <img src={Logo} alt="logo" />
        </NavLink>
        <div className="version-viewer-container admin-desktop-side">
          {"v" + VersionChecker()}
        </div>
      </div>

      <div className="content-nav">
        <ul className="sidebar-admin-links mt-4 px-3">
          {links.map((link, index) => {
            if (!isAccessGranted(user, link.permission)) return null;

            return (
              <li
                onClick={() => setActive(index)}
                className={`${active === index && "active-admin-item"}`}
                key={index}
              >
                <Link
                  to={link.pathname}
                  className={`sidebar-admin-link  ${
                    active === index && "active-admin-link"
                  }`}
                >
                  <span className="sidebar-icon-container">{link.icon}</span>
                  {link.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideBarAdmin;
