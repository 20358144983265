import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { usePasswordEditMutation } from "../../../../utils/api/user/user.api";
import { PasswordType } from "../../../../utils/api/user/user.type";
import { COLORS } from "../../../../utils/constants";
import { IconEdit } from "../../../shared/Icons";
import { validatePassword } from "../../auth/UseAuthForm/UseRegisterForm";

function EditPassword({ admin }: { admin?: boolean }) {
  const [sendData, { isSuccess, error, isError, isLoading, data }] =
    usePasswordEditMutation();
  const [message, setMessage] = useState("");
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        old_password: yup
          .string()
          .required("Votre mot de passe actuel est requis."),
        new_password: validatePassword(
          yup,
          "Le nouveau mot de passe est requis."
        ),
        passwordConfirm: yup
          .string()
          .oneOf(
            [yup.ref("new_password"), null],
            "veuillez entrer des mots de passe identiques."
          )
          .required("La confirmation du mot de passe est requis."),
      }),
    []
  );

  const { register, formState, clearErrors, handleSubmit, setError, reset } =
    useForm<PasswordType>({
      resolver: yupResolver(validationSchema),
    });

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          console.log(key);
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (error) {
      console.log("error", error);
      const err = error as any;
      if (
        err?.data?.old_password?.length &&
        err?.data?.old_password[0] === "Wrong password."
      ) {
        setError("old_password", {
          message: "Votre mot de passe est incorrect.",
        });
      } else {
        const msg = err?.data?.message?.toString()
          ? err?.data?.message?.toString()
          : `Une erreur de statut ${err?.status} s'est produite`;
        setMessage(msg);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    }
  }, [error, isError, isLoading]);

  useEffect(() => {
    if (data || isSuccess) {
      //   console.log("succes", data)

      Swal.fire({
        icon: "success",
        title: "Modification réussie!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        reset();
      });
    }
  }, [data, isSuccess]);
  const onSubmit = async (data) => {
    await sendData(data);
  };

  return (
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-5">
        <h3 className="title-change-password mb-3">Modifier mot de passe</h3>
        <div className="form-row row">
          <div className="col-md-12 account-form-col">
            <div className="form-group">
              <label htmlFor="old-password">Ancien mot de passe</label>
              <div className="form-profile-input-group">
                <input
                  type="password"
                  className="form-profile-control form-control"
                  placeholder="Ancien"
                  id="old-password"
                  {...register("old_password")}
                />
                <div className="icon-edit-container">
                  <IconEdit />
                </div>
                {formState?.errors.old_password && (
                  <div className="alert alert-danger sia-alert-danger closer mt-3">
                    {formState?.errors.old_password?.message?.toString()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="form-row row">
          <div className="col-md-12 account-form-col">
            <div className="form-group">
              <label htmlFor="new-password">Nouveau mot de passe</label>
              <div className="form-profile-input-group">
                <input
                  type="password"
                  className="form-profile-control form-control"
                  placeholder="Nouveau"
                  id="new-password"
                  {...register("new_password")}
                />
                <div className="icon-edit-container">
                  <IconEdit />
                </div>
                {formState?.errors.new_password && (
                  <div className="alert alert-danger sia-alert-danger closer mt-3">
                    {formState?.errors.new_password?.message?.toString()}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 account-form-col">
            <div className="form-group">
              <label htmlFor="confirm-password">
                Confirmer le nouveau mot de passe
              </label>
              <div className="form-profile-input-group">
                <input
                  type="password"
                  className="form-profile-control form-control"
                  id="confirm-password"
                  placeholder="Confirmer"
                  {...register("passwordConfirm")}
                />
                <div className="icon-edit-container">
                  <IconEdit />
                </div>
                {formState?.errors.passwordConfirm && (
                  <div className="alert alert-danger sia-alert-danger closer  mt-3">
                    {formState?.errors.passwordConfirm?.message?.toString()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="btn-profile-container mt-5">
          {isLoading && (
            <button
              disabled
              className={admin ? "admin-submit-btn" : "btn btn-profile-submit"}
            >
              Modification&nbsp; <i className="fas fa-spin fa-spinner"></i>
            </button>
          )}
          {!isLoading && (
            <button
              type="submit"
              className={admin ? "admin-submit-btn" : "btn-profile-submit"}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default EditPassword;
