import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Projet } from "../../../../utils/api/launchCampagn/projet.type";
import { useUserByFilterQuery } from "../../../../utils/api/user/user.api";
import { User } from "../../../../utils/api/user/user.type";
import { ApiUrl } from "../../../../utils/constants";
import { getImage, useModalActions } from "../../../../utils/utils";
import BtnCloseModal from "../../../shared/BtnCloseModal";
import FormToSendPoint from "./FormToSendPoint";
import "./SharePoints.css";

const SharePointModal = () => {
  const [search, setSearch] = useState("");
  const ma = useModalActions("sharepoint");
  const [user, setUser] = useState<User | null>(null);
  const { data = [] } = useUserByFilterQuery({ word: search });
  return (
    <div className="modal fade shopping-cart-modal" id="sharepoint">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content py-3">
          {!!!user && (
            <>
              <div className="px-4">
                <div className="form-search-user-container position-relative">
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="form-search-user form-control"
                    placeholder="Entrer nom ou numero telephone client"
                  />
                  <FiSearch
                    style={{
                      color: "#E6E6E6",
                      fontSize: 22,
                      position: "absolute",
                      top: 8,
                      right: 8,
                    }}
                  />
                </div>
              </div>
              <hr className="mt-3 divide-maajjal" />
              <ul className="users-for-share-points-containers m-0  px-2">
                {data.map((user) => (
                  <li
                    className="user-for-share-point d-flex gap-4 mb-2 px-3 py-1"
                    key={user.id}
                    onClick={() => setUser(user)}
                  >
                    <div>
                      <img
                        src={getImage(user.avatar)}
                        alt="user-avatar"
                        style={{ width: 40, height: 40 }}
                        className="w-fluid"
                      />
                    </div>
                    <div className="user-for-share-point-infos">
                      <h3>
                        {user.first_name} {user.last_name}
                      </h3>
                      <h4>{user.phone}</h4>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="modal-btn-container mt-3  px-4">
                <button
                  type="button"
                  className="btn-cancel-share-point "
                  onClick={() => ma.hide()}
                >
                  Fermer
                </button>
              </div>
            </>
          )}
          {!!user && (
            <FormToSendPoint
              user={user}
              resetIndex={() => {
                setUser(null);
                ma.hide();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SharePointModal;

export const ShareModal = ({
  item,
  modalId,
}: {
  item: Projet;
  modalId: string;
}) => {
  const title = item.name;
  const url = `${ApiUrl}/api/share/${item.slug}/`;

  return (
    <div className="modal fade shopping-cart-modal" id={modalId}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content py-3">
          <div className="flex-csb px-2 mb-3 align-items-center">
            <h1 className="admin-modal-title m-0">Partager {title}</h1>
            <BtnCloseModal />
          </div>
          <div className="row justify-content-between">
            <div className="col-3 text-center">
              <FacebookShareButton
                url={url}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>
            <div className="col-3 text-center">
              <TwitterShareButton
                url={url}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
            <div className="col-3 text-center">
              <WhatsappShareButton
                url={url}
                title={title}
                separator=":: "
                className="Demo__some-network__share-button"
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            <div className="col-3 text-center">
              <LinkedinShareButton
                url={url}
                className="Demo__some-network__share-button"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
