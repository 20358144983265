import { useAppSelector } from "../../../../redux/store";
import { useProduitFromLocation } from "../../../../utils/api/produit/produit.api";
import { formatAmout, getImage } from "../../../../utils/utils";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import GoBackBtn from "../../../shared/GoBackBtn";
import Sidebar from "../Sidebar/Sidebar";
import "./MesOffres.css";

const DetailOffre = () => {
  const [item] = useProduitFromLocation();
  if (!item) return null;
  const commercant = useAppSelector((s) => {
    const user = s.user.user;
    if (item?.commercant?.id) return item.commercant?.id;
    if (user?.id === item.commercant) return user;

    return null;
  });
  const DATA = [
    { value: item.name, label: "Nom produit" },
    { value: formatAmout(item.prix), label: "Prix" },
    {
      value: <span dangerouslySetInnerHTML={{ __html: item.description }} />,
      label: "Description",
    },
    { value: `${item.discount}%`, label: "Discount" },
    { value: item.point, label: "Point" },
  ];
  const DATA2 = [
    {
      value: `${commercant?.first_name} ${commercant?.last_name}`,
      label: "Prénom et Nom",
    },
    { value: commercant?.adress, label: "Adresse" },
    { value: commercant?.email, label: "Adresse mail" },
    { value: commercant?.phone, label: "Numéro de téléphone" },
  ];
  return (
    <div className="campagne-page-container">
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={1} />
          <div className="col-md-8 col-lg-9 col-xl-10 dashboard-page-content">
            <div className="m-tableau-bord py-5">
              <div className="maajjal-table-card px-3 py-4">
                <GoBackBtn
                  className="detail-offre-back-btn detail-offre-title"
                  label="Information produit	"
                />

                <div className="flex-c mt-3">
                  <img
                    src={getImage(item.avatar)}
                    alt="produit"
                    style={{ width: "100%", height: "auto" }}
                    className="img-info-produit"
                  />
                </div>

                {DATA.map(({ label, value }) => (
                  <div className="infos-ligne row">
                    <h5 className="col-6 px-0 fw-600">{label}</h5>
                    <h6 className="col-6 px-0 fw-400">{value}</h6>
                  </div>
                ))}
                <div className="mt-4 mb-4 border-b  row">
                  <h3 className="detail-offre-title p-0">
                    Information commerçant
                  </h3>
                </div>
                {DATA2.map(({ label, value }) => (
                  <div className="infos-ligne row">
                    <h5 className="col-6 px-0 fw-600">{label}</h5>
                    <h6 className="col-6 px-0 fw-400">{value}</h6>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailOffre;
