import { useState } from "react";
import { AiOutlineShareAlt } from "react-icons/ai";
import { useAppSelector } from "../../../../redux/store";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import SharePointModal from "../../Modals/SharePoints/SharePointModal";
import MesPointsTable, {
  SharedPointTable,
} from "../../Tables/MesPoints/MesPointsTable";
import Sidebar from "../Sidebar/Sidebar";
import "./MesPoints.css";
const MesPoints = () => {
  const user = useAppSelector((s) => s.user.user);
  const [index, setIndex] = useState(0);

  return (
    <div className="campagne-page-container">
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={3} />
          <div className="col-md-8 col-lg-9 col-xl-10 dashboard-page-content">
            <div className="m-tableau-bord py-5">
              <div className="flex-sb mb-3">
                <h1 className="majjal-table-caption m-0">Mes Points</h1>
                <button
                  className="share-point-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#sharepoint"
                >
                  <AiOutlineShareAlt
                    style={{
                      fontSize: 24,
                    }}
                  />
                  <span>Partager mes points</span>
                </button>
              </div>
              <div className="total-points-card py-5">
                <div className="d-flex align-items-center gap-4">
                  <h5 className="total-label">Total</h5>
                  <div className="d-flex gap-2">
                    <h6 className="total-points">{user?.point}</h6>
                    <h6 className="total-points-signe">points</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="dashboard-commandes-tab-container">
                  <button
                    onClick={() => setIndex(0)}
                    className={`stepper-commandes-tab ${
                      index === 0 && "stepper-active-commandes-tab"
                    }`}
                  >
                    Produit acheté
                  </button>
                  <button
                    onClick={() => setIndex(1)}
                    className={`stepper-commandes-tab ${
                      index === 1 && "stepper-active-commandes-tab"
                    }`}
                  >
                    Points partagés
                  </button>
                  <button
                    onClick={() => setIndex(2)}
                    className={`stepper-commandes-tab ${
                      index === 2 && "stepper-active-commandes-tab"
                    }`}
                  >
                    Points reçus
                  </button>
                </div>
              </div>
              <div className="maajjal-table-card px-3 py-4">
                {index === 0 && <MesPointsTable />}
                {index === 1 && <SharedPointTable index={index} />}
                {index === 2 && <SharedPointTable index={index} />}
              </div>
            </div>
          </div>
        </div>
        <SharePointModal />
      </div>
    </div>
  );
};

export default MesPoints;
