import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { useProjetFromLocation } from "../../../utils/api/launchCampagn/projet.api";
import {
  Projet,
  ProjetFormData,
} from "../../../utils/api/launchCampagn/projet.type";
import { getImage } from "../../../utils/utils";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import { usePhoneHandler } from "../auth/Register/Register";
import Sidebar from "../TableauDeBord/Sidebar/Sidebar";
import { ProjectForm, useFieldHandler } from "./LaunchACampain";
import "./LaunchACampain.css";
import UseLaunchCampagnForm from "./UseLaunchCampagnForm";

const UpdateCampain = ({ projet }: { projet: Projet }) => {
  return (
    <div className="campagne-page-container">
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={0} />
          <div className="col-md-8 col-lg-9 col-xl-10">
            <UpdateCampainComponent projet={projet} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default () => {
  const [item] = useProjetFromLocation();
  if (!item) return null;
  return <UpdateCampain projet={item} />;
};

export function UpdateCampainComponent({ projet }) {
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState(
    projet ? getImage(projet.photo) : null
  );

  const { register, onSubmit, errors, setValue, isLoading, isSuccess } =
    UseLaunchCampagnForm(projet?.id);
  const [phone, setPhone] = usePhoneHandler(
    register,
    setValue,
    projet?.telephone || "",
    "telephone"
  );
  const [description, setDescription] = useFieldHandler(
    register,
    setValue,
    projet?.description || "",
    "description"
  );

  useEffect(() => {
    if (projet) {
      const fields: (keyof ProjetFormData)[] = [
        "category_id",
        "date_debut",
        "date_fin",
        "description",
        "email",
        "links",
        "min_amount",
        "minim_souscription",
        "network",
        "name",
        "objectif_levee",
        "statut",
        "telephone",
        "video_link",
      ];
      for (let field of fields) {
        let val = projet[field];
        if (field === "category_id") {
          val = projet?.category_id?.id || projet?.category_id;
        }
        register(field);
        setValue(field, val);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl: any = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
    }
  }, [selectedFile]);

  const handleChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    register("photo");
    setValue("photo", e.target.files[0]);
  };

  return (
    <div className="details-projet-tab-container">
      <section className="mesprojets-detail-projet-info-sup p-2">
        <h1 className="launch-title">
          <button onClick={() => navigate(-1)} className="back-btn">
            <FiArrowLeft
              style={{
                color: "#021849",
              }}
            />
          </button>
        </h1>
        <ProjectForm
          {...{
            errors,
            onSubmit,
            phone,
            register,
            setPhone,
            handleChange,
            isLoading,
            preview: preview,
            update: !!projet,
            description,
            setDescription,
          }}
        />
      </section>
    </div>
  );
}
