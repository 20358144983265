import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiUrl } from "../../constants";
import { prepareHeaders } from "../user/user.api";
import { Categorie, CategorieFormData } from "./categorie.type";

const CategorieApi = createApi({
  reducerPath: "listCategorie",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["listCategorie", "listCategorie_products"],
  endpoints: (build) => ({
    getCategorie: build.query<Categorie[], void>({
      query: () => `/categorybyvisitor/`,
      providesTags: ["listCategorie"],
    }),

    category_product: build.query<Categorie[], void>({
      query: () => `/categoryproductbyvisitor/`,
      providesTags: ["listCategorie_products"],
    }),
    addProductCategory: build.mutation<
      Categorie,
      { slug?: string; data: CategorieFormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `/category_product/${slug}/` : `/category_product/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["listCategorie_products"],
    }),
    deleteProductCategory: build.mutation<Categorie, string>({
      query: (slug) => ({
        url: `/category_product/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["listCategorie_products"],
    }),
    addCategory: build.mutation<
      Categorie,
      { id?: number; data: CategorieFormData }
    >({
      query: ({ id, data }) => ({
        url: id ? `/category/${id}/` : `/category/`,
        method: id ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["listCategorie"],
    }),
    deleteCategory: build.mutation<Categorie, number>({
      query: (id) => ({
        url: `/category/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["listCategorie"],
    }),
  }),
});

export const { useGetCategorieQuery, useCategory_productQuery } = CategorieApi;

export default CategorieApi;
