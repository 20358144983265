import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useAppSelector } from '../../../../../../redux/store'
import { RegisterFormData } from '../../../../../../utils/api/auth/auth.type'
import { useAddUserAdminMutation } from '../../../../../../utils/api/userAdmin/userAdmin.api'
import { UserAdminFormData } from '../../../../../../utils/api/userAdmin/userAdmin.type'
import { validatePhone } from '../../../../../shared/PhoneInput'

function UseAjoutAdminForm() {
  const { user } = useAppSelector((s) => s.user)

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required("L'email est requis"),
        phone: validatePhone(yup),
        first_name: yup.string().required('Le prénom est requis'),
        last_name: yup.string().required('Le nom est requis'),
        // adress: yup.string(),
        // password: yup.string().required("Le mot de passe est requis"),
      }),
    [],
  )

  const [
    sendData,
    { isSuccess, isError, isLoading, error },
  ] = useAddUserAdminMutation()

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    setError,
    reset,
  } = useForm<UserAdminFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      // password: "",
      // adress: ""
    },
  })

  const onSubmit = async (data: UserAdminFormData) => {
    const datas = {
      ...data,
      parent: user?.id,
    }
    await sendData(datas)
    console.log('donnée form', datas)
  }

  useEffect(() => {
    if (isError) {
      console.log(error)
    }
  }, [isError])

  return {
    register,
    formState,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    isSuccess,
    isLoading,
    error,
    isError,
    setValue,
    setError,
    reset,
    // isSuccessful,
    // isFailed,
    // loading,
    // fail,
  }
}

export default UseAjoutAdminForm
