import BtnCloseModal from "../../../shared/BtnCloseModal";
import BtnSubmit from "../../../shared/BtnSubmit";

import { Categorie } from "../../../../utils/api/categorie/categorie.type";
import { useModalActions } from "../../../../utils/utils";
import useTeamForm from "./form/useTeamForm";

const TeamForm = ({ item, modalId }: { item?: Categorie; modalId: string }) => {
  const ma = useModalActions(modalId);
  const { errors, isLoading, onSubmit, register } = useTeamForm(ma.hide, item);

  return (
    <div className="modal fade" id={modalId}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content px-4 py-3">
          <div className="flex-csb mb-4">
            <h1 className="admin-modal-title m-0">
              {item ? "Modifier" : "Ajouter"} une catégorie
            </h1>
            <BtnCloseModal />
          </div>
          <div className="reponse-form-container">
            <form onSubmit={onSubmit}>
              <div className="form-group mb-4">
                <label htmlFor="fullname" className="majjal-label mb-2">
                  Nom
                </label>
                <input
                  type="text"
                  className="form-control-majjal form-control form-control-lg bg-slate"
                  id="fullname"
                  {...register("name")}
                />
                {errors.name && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.name?.message?.toString()}
                  </div>
                )}
              </div>

              <div className="btn-container flex-r mt-5">
                {isLoading && (
                  <button disabled className="admin-submit-btn">
                    En cours&nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isLoading && (
                  <BtnSubmit label={item ? "Modifier" : "Ajouter"} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamForm;
