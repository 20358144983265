import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../redux/store";
import { ApiUrl } from "../../constants";
import { openPopup } from "../../utils";
import { PaginationResults, QueryUrl } from "../common.api";
import { Complaint } from "../produit/produit.type";
import { prepareHeaders, UserApi } from "../user/user.api";
import { Achat, AchatFormData } from "./achat.type";

const AchatApi = createApi({
  reducerPath: "listAchat",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiUrl}/api`,
    prepareHeaders,
  }),
  tagTypes: ["achat_user", "achats_admin", "achat_commercant"],
  endpoints: (build) => ({
    getcommandes: build.query<
      PaginationResults<Achat>,
      {
        page: number;
        wordproduit: string;
        wordcommercant: string;
        wordclient: string;
      }
    >({
      query: (query) => QueryUrl("/achatbyfilteradmin/", query),
      providesTags: ["achats_admin"],
    }),
    achatsByUser: build.query<Achat[], number>({
      query: (id) => `/achatbyuser/${id}/`,
      providesTags: ["achat_user"],
    }),
    achatsByCommercant: build.query<Achat[], number>({
      query: (id) => `/orderbycommercant/${id}/`,
      providesTags: ["achat_commercant"],
    }),
    achatPay: build.query<{ response_text: string; message?: string }, string>({
      query: (slug) => `/achat_pay/${slug}/`,
    }),
    complaint: build.mutation<any, Complaint>({
      query: (data) => {
        return {
          url: `/complaint/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [],
    }),
    AddAchat: build.mutation<Achat, AchatFormData>({
      query: (data) => ({
        url: `/achat/`,
        method: "POST",
        body: data,
      }),
      onCacheEntryAdded(arg, { dispatch }) {
        dispatch(UserApi.util.invalidateTags(["user", "state"]));
      },
      invalidatesTags: ["achat_user", "achat_commercant", "achats_admin"],
    }),
    updateAchat: build.mutation<Achat, { id: number; data: AchatFormData }>({
      query: ({ id, data }) => ({
        url: `/achat/${id}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["achat_user", "achat_commercant", "achats_admin"],
    }),
    deleteAchat: build.mutation<Achat, number>({
      query: (id) => ({
        url: `/achat/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["achat_user", "achat_commercant", "achats_admin"],
    }),
  }),
});

export const {
  useAchatsByUserQuery,
  useDeleteAchatMutation,
  useAddAchatMutation,
  useLazyAchatPayQuery,
  useUpdateAchatMutation,
  useAchatsByCommercantQuery,
  useGetcommandesQuery,
} = AchatApi;

export default AchatApi;

export function useAchatPay(): [(achat: Achat) => Promise<boolean>, boolean] {
  const is_double_type = useAppSelector((s) => s.user.user?.is_double_type);
  const [updateAchat, { isLoading }] = useUpdateAchatMutation();
  const [achatPay, { isLoading: loading }] = useLazyAchatPayQuery();

  const onPay = useCallback(
    async (achat: Achat) => {
      const page = is_double_type
        ? "commercant/liste-des-commandes"
        : "app/tableau-de-bord/mes-commandes";
      //@ts-ignore
      const { data } = await updateAchat({
        id: achat.id,
        data: {
          env: window.location.origin,
          page: page,
        },
      });
      if (data) {
        const { data } = await achatPay(achat.slug);
        if (data?.response_text) {
          openPopup(data?.response_text);
          return true;
        }
      }
      Swal.fire({
        icon: "error",
        title: `Une erreur  s'est produite`,
        showConfirmButton: false,
        timer: 3000,
      });
      return false;
    },
    [is_double_type]
  );

  return [onPay, isLoading || loading];
}
