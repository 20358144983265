import { Faq } from "../../../../utils/api/faq/faq.type";
import BtnCloseModal from "../../../shared/BtnCloseModal";
import BtnSubmit from "../../../shared/BtnSubmit";

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../redux/store";
import { useResponseByFaqMutation } from "../../../../utils/api/faq/faq.api";
import { FaqFormData } from "../../../../utils/api/faq/faq.type";
import { COLORS } from "../../../../utils/constants";
import { useModalActions } from "../../../../utils/utils";

const ModifierFaqModal = ({
  item,
  modalId,
}: {
  item: Faq;
  modalId: string;
}) => {
  const ma = useModalActions(modalId);
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    reponse: yup.string().required().label("la reponse").nullable(),
  });

  const { register, handleSubmit, formState, clearErrors, reset } = useForm<
    FaqFormData | any
  >({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      reponse: "",
    },
  });

  const [sendData, { isLoading, isSuccess, isError, error, data }] =
    useResponseByFaqMutation();
  const userId = useAppSelector((s) => s.user?.user?.id);
  const faqId = item.id;

  useEffect(() => {
    if (formState?.errors) {
      setTimeout(() => {
        Object.entries(formState?.errors).map(([key]: any) => {
          window.scrollTo(10, 10);
          clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  useEffect(() => {
    if (isError) {
      console.log("err", error);
      const err = error as any;
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {};
      Swal.fire({
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      console.log("success", data);
      Swal.fire({
        icon: "success",
        title: "Réponse envoyé avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        reset();
        ma.hide();
      });
    }
  }, [isSuccess]);
  const onSubmit = async (formValues) => {
    const datas = {
      ...formValues,
      user: userId,
      faq: faqId,
    };
    await sendData(datas);

    console.log("datas", datas);
  };

  return (
    <div className="modal fade" id={modalId}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content px-4 py-3">
          <div className="flex-csb mb-4">
            <h1 className="admin-modal-title m-0">Question/Réponses</h1>
            <BtnCloseModal />
          </div>
          <div className="reponse-form-container">
            <h3 className="admin-modal-sous-title">Réponse</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-4">
                <label htmlFor="question" className="admin-form-label">
                  Question
                </label>
                <div className="faq-response-question">{item?.question}</div>
              </div>
              <div className="form-group">
                <label htmlFor="question" className="admin-form-label">
                  Réponses
                </label>
                <textarea
                  id="reponse"
                  placeholder="Ajouter une réponse"
                  className="form-control form-control-lg form-admin-textarea"
                  {...register("reponse")}
                />
                {formState?.errors?.reponse && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {formState?.errors?.reponse?.message?.toString()}
                  </div>
                )}
              </div>

              <div className="btn-container flex-r mt-5">
                {isLoading && (
                  <button disabled className="admin-submit-btn">
                    En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isLoading && <BtnSubmit label="Envoyer" />}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifierFaqModal;
