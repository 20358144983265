import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { onSetUser } from "../../../../redux/slices/user.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useAddProduitMutation } from "../../../../utils/api/produit/produit.api";
import { ProduitFormData } from "../../../../utils/api/produit/produit.type";
import { useUserEditMutation } from "../../../../utils/api/user/user.api";
import { COLORS } from "../../../../utils/constants";
import { getRoutePath } from "../../../routes/routes";

function UseAjouterProduitForm(id?: number) {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required("Le nom du produit est requis"),
        description: yup.string(),
        prix: yup
          .number()
          .typeError("Vous devez entrer le prix du produit")
          .min(0)
          .required(),
        avatar: id ? yup.mixed() : yup.mixed().required("L'image est requise."),
        created_at: yup.string(),
        commercant: yup.string(),
        point: yup
          .number()
          .typeError("Vous devez entrer un nombre")
          .min(0)
          .required(),
        discount: yup.number().typeError("Vous devez entrer un nombre").min(0),
        slug: yup.string(),
        category: yup.string(),
      }),
    []
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [updateUser] = useUserEditMutation();
  const [sendData, { isSuccess, isError, isLoading, error }] =
    useAddProduitMutation();

  const { register, handleSubmit, formState, clearErrors, setValue } =
    useForm<ProduitFormData>({
      resolver: yupResolver(validationSchema),
    });

  const user = useAppSelector((s) => s.user);

  const onSubmit = async (data: ProduitFormData) => {
    console.log("donne", data);

    let fd = new FormData();
    if (user?.user?.id) {
      fd.append("commercant", String(user?.user?.id));
    }

    Object.keys(data).map((key) => {
      fd.append(key, data[key]);
    });

    if (id) {
      await sendData({ id, data: fd });
    } else {
      await sendData(fd);
    }
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: id
          ? "Produit modifié avec succès!"
          : "Produit ajouté avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        if (!id) {
          if (!user?.user?.is_double_type) {
            updateUser({
              is_double_type: true,
              is_commercant: true,
            });
            if (user?.user) {
              dispatch(
                onSetUser({
                  ...user?.user,
                  is_double_type: true,
                  is_commercant: true,
                })
              );
            }
          }
          navigate(getRoutePath("ProductList"));
        } else {
          navigate(getRoutePath("ProductList"));
        }
      });
    }
  }, [isSuccess, isError, error]);

  return {
    register,
    errors: formState.errors,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    setValue,
    isLoading,
    isSuccess,
  };
}

export default UseAjouterProduitForm;
