import { NavLink } from "react-router-dom";
import { getRoutePath } from "../../../../routes/routes";
import FrontFooter from "../../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../../shared/FrontHeader/FrontHeader";
import MesProjetsTable from "../../../Tables/mesProjets/MesProjetsTable";
import Sidebar from "../../Sidebar/Sidebar";
import "./ListeProjets.css";

function ListeProjets() {
  return (
    <div className="campagne-page-container">
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={0} />
          <div className="col-md-8 col-lg-9 col-xl-10 dashboard-page-content">
            <div className="mt-tableau-bord py-5">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-8">
                    <h3 className="tb-mes-projet-titre mb-4">Mes projets</h3>
                  </div>
                  <div className="col-md-4 justify-content-end">
                    <NavLink
                      to={getRoutePath("LaunchACampain")}
                      className="btn-shop btn-shop-next fw-500 market-btn-link"
                    >
                      Ajouter un projet
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-md-12 maajjal-table-card px-3 py-4">
                <MesProjetsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default ListeProjets;
