import moment from "moment";
import { useState } from "react";
import { useGetContactQuery } from "../../../../utils/api/contact/contact.api";
import { Contact } from "../../../../utils/api/contact/contact.type";
import { getAvatar } from "../../../../utils/utils";
import Pagination from "../../../shared/Pagination";
import RepondreMessageModal from "../../Modals/Messages/RepondreMessageModal";
import SideBarAdmin from "../Layouts/SideBarAdmin/SideBarAdmin";
import TopBar from "../Layouts/TopBar/TopBar";
import "./Messages.css";
const Messages = () => {
  const [page, setPage] = useState(1);
  const { data = { results: [] } } = useGetContactQuery({ page });
  return (
    <div className="parametre-page">
      <TopBar index={8} />
      <SideBarAdmin index={8} />
      <div className="parametre-component m-tableau-bord pt-3 flex-r">
        <div className="admin-page-container">
          <div className="maajjal-card maajjal-admin-card px-2 py-2 p-xxl-3">
            <h1 className="admin-component-title">Messages</h1>
            <div className="messages-container">
              {data.results.map((message) => (
                <MessageItem message={message} />
              ))}
              <Pagination
                page={page}
                onPageChange={setPage}
                total={data.count}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;

export function MessageItem({
  message,
  modalId,
}: {
  message: Contact;
  modalId?: string;
}) {
  modalId = modalId || `repondreMessageModal${message.id}`;
  const buttonProps = {
    "data-bs-toggle": "modal",
    "data-bs-target": `#${modalId}`,
  };

  return (
    <>
      <div
        className="message-item px-3"
        key={message.id}
        role="button"
        {...buttonProps}
      >
        <div className="avatar-messages-container pb-3">
          <img
            src={getAvatar(message?.user?.avatar)}
            alt="avatar"
            className="custom-avatar-message"
          />
        </div>
        <div className="messages-text w-100">
          <div className="message-infos flex-csb">
            <h3 className="author-message-name">{message.fullname}</h3>
            <h6 className="hour-publish-message">
              {moment(message.created_at).fromNow()}
            </h6>
          </div>
          <div className="message-content">
            <p>{message.message}</p>
          </div>
        </div>
      </div>
      <RepondreMessageModal message={message} modalId={modalId} />
    </>
  );
}
