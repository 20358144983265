import { useEffect, useState } from "react";
const PER_PAGE = 24;
type Page = {
  page: number;
  next?: boolean;
  previous?: boolean;
  active: boolean;
};
type PaginationProps = {
  page: number;
  onPageChange: (nextPage: number) => any;
  total?: number;
  perPage?: number;
};
type PageItemProps = {
  page: Page;
  onClick: (page: number) => any;
};

function PageItem({ page, onClick }: PageItemProps) {
  return (
    <li className={`page-item ${page.active && "active"}`}>
      <a
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick(page.page);
        }}
        className="page-link"
        href="#"
      >
        {page.page}
      </a>
    </li>
  );
}

const Pagination = ({
  page,
  onPageChange,
  total = 0,
  perPage = PER_PAGE,
}: PaginationProps) => {
  const [pages, setPages] = useState<Page[]>([]);
  useEffect(() => {
    const numberOfPages = Math.ceil((total || 0) / perPage);
    const pages: Page[] = [];
    for (let i = 1; i <= numberOfPages; i++) {
      const newPage: Page = {
        active: page === i,
        page: i,
      };
      pages.push(newPage);
    }

    setPages(pages);
  }, [total, page, perPage]);
  // if (pages?.length < 2) return null;
  return (
    <nav aria-label="pagination" className="my-2">
      <ul className="pagination">
        {pages?.map((p) => (
          <PageItem page={p} onClick={onPageChange} />
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
