import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const CampainsSkeleton = () => {
	return (
        <div className="col-md-4 pt-0">
            <div className="card custom-card-campagne pt-0 mt-0">
                <div className='w-100'>
                    <Skeleton
                        height={220}
                        circle={false}
                        borderRadius="1rem"
                    />
                </div>
            <div className="card-body">
                <h5 className="card-title card-campagne-title">
                    <Skeleton
                    height={10}
                    width={130}
                    circle={false}
                    />
                </h5>
                <div className="card-campagne-progresse-container">
                <div className="card-campagne-progresse-price mb-1">
                    <Skeleton
                        height={10}
                        width={100}
                        circle={false}
                     />
                </div>
                    <div className="mb-3 w-100" >
                    <Skeleton
                        height={8}
                        circle={false}
                    />
                    </div>
                <div className="card-campagne-progresse-details mb-3">
                    <p>
                        <Skeleton
                            height={10}
                            width={95}
                            circle={false}
                        />
                    </p>
                    <p>
                        <Skeleton
                            height={10}
                            width={95}
                            circle={false}
                        />
                    </p>
                    <p>
                        <Skeleton
                            height={10}
                            width={95}
                            circle={false}
                        />
                    </p>
                </div>
                </div>
                <div className="card-campagne-button-share-container">
                <div className="card-campagne-button-share-like">
                    <Skeleton
                        height={40}
                        width={65}
                        circle={false}
                    />
                    <Skeleton
                        height={40}
                        width={55}
                        circle={false}
                    />
                </div>
                    <Skeleton
                        height={40}
                        width={100}
                        circle={false}
                    />
                </div>
            </div>
            </div>
        </div>
	);
};

export default CampainsSkeleton;
