import { formatAmout } from "../../../utils/utils";

function AlerteConfirmation({
  loading,
  onConfirm,
  projectName,
  funds,
  onHide,
}) {
  return (
    <>
      <div className="modal-investissement-description-content2">
        <div className="modal-investissement-description-confirm">
          Confirmation
        </div>
        <div className="modal-investissement-confirm-para-container">
          <div className="modal-investissement-confirm-para">
            Vous allez effectuer une contribution de{" "}
            <span className="modal-investissement-confirm-para-span">
              {formatAmout(funds)}
            </span>{" "}
            au projet {projectName}.
          </div>
        </div>
        <div className="modal-investissement-montants-button-container">
          <button
            type="button"
            disabled={loading}
            className="btn modal-investissement-confirm-btn-annuler"
            onClick={onHide}
          >
            Annuler
          </button>
          {loading && (
            <button
              type="button"
              className="btn modal-investissement-montants-btn-continuer"
            >
              Chargement...
            </button>
          )}
          {!loading && (
            <button
              type="button"
              onClick={onConfirm}
              className="btn modal-investissement-montants-btn-continuer"
            >
              Confirmer
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default AlerteConfirmation;

export function AlerteDebitConfirmation({
  loading,
  onConfirm,
  projectName,
  funds,
  onHide,
}) {
  return (
    <>
      <div className="modal-investissement-description-content2">
        <div className="modal-investissement-description-confirm">
          Confirmation
        </div>
        <div className="modal-investissement-confirm-para-container">
          <div className="modal-investissement-confirm-para">
            Vous allez effectuer un débit de{" "}
            <span className="modal-investissement-confirm-para-span">
              {formatAmout(funds)}
            </span>{" "}
            sur le projet {projectName}.
          </div>
        </div>
        <div className="modal-investissement-montants-button-container">
          <button
            type="button"
            disabled={loading}
            className="btn modal-investissement-confirm-btn-annuler"
            onClick={onHide}
          >
            Annuler
          </button>
          {loading && (
            <button
              type="button"
              className="btn modal-investissement-montants-btn-continuer"
            >
              Chargement...
            </button>
          )}
          {!loading && (
            <button
              type="button"
              onClick={onConfirm}
              className="btn modal-investissement-montants-btn-continuer"
            >
              Confirmer
            </button>
          )}
        </div>
      </div>
    </>
  );
}
