import moment from "moment";
import React from "react";
import Chart from "react-apexcharts";
import { useDashboardByCommercantQuery } from "../../../../utils/api/user/user.api";
import ImgBag from "../../../assets/icons/Bag.png";
import ImgWallet from "../../../assets/icons/Wallet.png";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import Sidebar from "../../TableauDeBord/Sidebar/Sidebar";
import "./MerchantDashboard.css";

const MerchantDashboard = () => {
  const {
    data = {
      data: [],
      nb_commandes: 0,
      nb_commandes_livrees: 0,
      nb_produit: 0,
    },
  } = useDashboardByCommercantQuery();
  const [ordersChartData, setOrdersChartData] = React.useState({
    labels: [
      "Lundi",
      "Mardi",
      "Mercredi",
      "jeudi",
      "vendredi",
      "Samdi",
      "Dimanche",
    ],
    data: [0, 0, 0, 0, 0, 0, 0],
  });

  const series = [
    {
      name: "Commandes",
      data: ordersChartData.data,
    },
  ];
  const options: any = {
    chart: {
      height: 350,
      background: "#FFFFFF",
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        formatter: undefined,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      z: {
        formatter: undefined,
        title: "Size: 20px",
      },
      marker: {
        show: true,
      },
    },
    colors: ["#77869D"],

    fill: {
      colors: ["#77869D"],
    },

    grid: {
      show: true,

      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: ordersChartData.labels,
    },

    yaxis: {
      show: true,
    },

    legend: {
      show: false,
    },
  };

  React.useEffect(() => {
    if (data) {
      const { data: graph } = data;
      const start = moment().startOf("week");
      const days: typeof data.data = [];
      for (let i = 0; i < 7; i++) {
        const day = moment(start.format("YYYY-MM-DD")).add(i, "days");
        const finded = graph.find(
          (g) => moment(g.day).format("L") === day.format("L")
        );
        if (finded) {
          days.push(finded);
        } else {
          days.push({
            day: day.format("YYYY-MM-DD"),
            achat_count: 0,
          });
        }
      }
      setOrdersChartData({
        labels: days.map((day) => moment(day.day).format("dddd")),
        data: days.map((day) => day.achat_count),
      });
    }
  }, [data]);

  return (
    <div className="campagne-page-container">
      <FrontHeader />
      <div className="mon-compte">
        <div className="row">
          <Sidebar index={0} />
          <div className="col-md-8 col-lg-9 col-xl-10 dashboard-page-content">
            <div className="m-tableau-bord">
              <div className="maajjal-card maajjal-admin-card px-2 py-2 p-xxl-3">
                <div className="row maajaal-admin-dashboard-card-container commercant-dashboard-stat-row">
                  <div className="col-lg-4">
                    <div className="card card-body maajaal-admin-dashboard-card1">
                      <div className="ma-dashboard-card-title-container">
                        <div className="dashboard-stat-block-header">
                          <div className="ma-dashboard-card-title">
                            Nombre de produit
                          </div>
                        </div>
                        <div className="ma-dashboard-card-icon">
                          <img
                            src={ImgWallet}
                            alt="icon"
                            className="ma-dashboard-card-icon-wallet"
                          />
                        </div>
                      </div>
                      <div className="ma-dashboard-card-price">
                        {data.nb_produit}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card card-body maajaal-admin-dashboard-card2 ">
                      <div className="ma-dashboard-card-title-container">
                        <div className="dashboard-stat-block-header">
                          <div className="ma-dashboard-card-title">
                            Nombre de commandes{" "}
                          </div>
                        </div>
                        <div className="ma-dashboard-card-icon">
                          <img
                            src={ImgBag}
                            alt="Bag count"
                            className="ma-dashboard-card-icon-wallet"
                          />
                        </div>
                      </div>
                      <div className="ma-dashboard-card-price">
                        {data.nb_commandes}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card card-body maajaal-admin-dashboard-card5 ">
                      <div className="ma-dashboard-card-title-container">
                        <div className="dashboard-stat-block-header">
                          <div className="ma-dashboard-card-title">
                            Nombre de commandes livrées{" "}
                          </div>
                        </div>
                        <div className="ma-dashboard-card-icon">
                          <img
                            src={ImgBag}
                            alt="Dashboard Count Item"
                            className="ma-dashboard-card-icon-wallet"
                          />
                        </div>
                      </div>
                      <div className="ma-dashboard-card-price">
                        {data.nb_commandes_livrees}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-5">
                <Chart
                  className="chart-dashboard-marketplace"
                  options={options}
                  series={series}
                  data="Soins"
                  type="area"
                  width="100%"
                  height="350"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantDashboard;
