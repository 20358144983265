type StorageType = typeof localStorage | typeof sessionStorage;
export const AppStorage = {
  getStorage(): StorageType {
    const remember = localStorage.getItem("remember");
    if (remember && parseInt(remember) === 1) return localStorage;
    return sessionStorage;
  },
  getItem<T>(key: string, defaultValue: any = null): T {
    const data = this.getStorage().getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return defaultValue;
  },
  setItem(key: string, value: any) {
    this.getStorage().setItem(key, JSON.stringify(value));
  },
  removeItem(key: string) {
    this.getStorage().removeItem(key);
  },
  clear() {
    this.getStorage().clear();
  },
};
