import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { useGetCategorieQuery } from "../../../utils/api/categorie/categorie.api";
import { useGetMontantConfigQuery } from "../../../utils/api/montantConfig/montantConfig.api";
import { etatProjet } from "../../../utils/constants";
import { EmptyMessage } from "../../../utils/emptyMessage";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import PhoneInput from "../../shared/PhoneInput";
import { SEO } from "../../shared/SEO";
import { HtmlInput } from "../admin/Produits/AjoutProduitAdmin";
import { usePhoneHandler } from "../auth/Register/Register";
import "./LaunchACampain.css";
import UseLaunchCampagnForm from "./UseLaunchCampagnForm";

export function useFieldHandler(
  register,
  setValue,
  defaultValue,
  field = "description"
) {
  const [valueData, setValueData] = useState(defaultValue);
  useEffect(() => {
    register(name);
  }, []);

  useEffect(() => {
    setValue(field, valueData);
  }, [valueData]);
  useEffect(() => {
    setValueData(defaultValue);
  }, [defaultValue]);

  return [valueData, setValueData];
}
const LaunchACampain = () => {
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  // console.log('categorie', categorie)

  const { register, onSubmit, errors, setValue, isLoading, isSuccess } =
    UseLaunchCampagnForm(undefined);
  const [phone, setPhone] = usePhoneHandler(
    register,
    setValue,
    "",
    "telephone"
  );
  const [description, setDescription] = useFieldHandler(
    register,
    setValue,
    "",
    "description"
  );
  useEffect(() => {
    register("statut");
    setValue("statut", "offline");
  }, []);
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);

      return;
    }

    const objectUrl: any = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    console.log("photo", e.target.files);
    register("photo");
    setValue("photo", e.target.files[0]);
  };

  return (
    <>
      <SEO title="Lancer une campagne" />
      <div className="launch-campaign-component">
        <FrontHeader />
        <section className="launch-campaing pb-5  custom-container margin-container">
          <div className="launch-camapaign-form-container custom-container py-5 px-2 px-sm-4">
            <div className="row">
              <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                <h1 className="launch-title">
                  <button onClick={() => navigate(-1)} className="back-btn">
                    <FiArrowLeft
                      style={{
                        color: "#021849",
                      }}
                    />
                  </button>
                  Lancer une campagne
                </h1>
                <ProjectForm
                  {...{
                    errors,
                    onSubmit,
                    phone,
                    update: false,
                    register,
                    setPhone,
                    handleChange,
                    isLoading,
                    description,
                    setDescription,
                    preview: selectedFile ? preview : null,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <FrontFooter />
      </div>
    </>
  );
};

export default LaunchACampain;

export function ProjectForm({
  onSubmit,
  errors,
  register,
  phone,
  setPhone,
  handleChange,
  isLoading,
  preview,
  update,
  description,
  setDescription,
}) {
  const iconStyle = {
    fontSize: "18",
    color: "#5a5a5a",
    opacity: 0.3,
  };

  const { data: categorie } = useGetCategorieQuery();
  const { data: results = [], isLoading: loading } = useGetMontantConfigQuery();

  return (
    <form className="mt-4 add-update-form" onSubmit={onSubmit}>
      <div className="row add-update-form-row">
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="name" className="majjal-label mb-2">
              Nom du projet
            </label>
            <input
              type="text"
              className="form-control-majjal form-control form-control-lg"
              id="name"
              placeholder="nom"
              {...register("name")}
              data-testid="nameId"
            />
            {errors.name && (
              <div className="alert alert-danger sia-alert-danger closer mt-2">
                {errors.name?.message?.toString()}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="desc-product" className="majjal-label mb-2">
              Décrivez votre projet
            </label>
            <HtmlInput
              data={description}
              name="description"
              onChange={(data) => setDescription(data)}
            />
            {errors.description && (
              <div className="alert alert-danger sia-alert-danger closer mt-2">
                {errors.description?.message?.toString()}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="price" className="majjal-label mb-2">
              Objectif de la levée (EN FCFA)
            </label>
            <input
              type="text"
              className="form-control-majjal form-control form-control-lg"
              id="price"
              placeholder="Objectif de la levée (EN FCFA)"
              {...register("objectif_levee")}
              data-testid="objectif_leveeId"
            />
            {errors.objectif_levee && (
              <div className="alert alert-danger sia-alert-danger closer mt-2">
                {errors.objectif_levee?.message?.toString()}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="price" className="majjal-label mb-2">
              Quel est le montant minimum que vous souhaitez collecter sur
              maajjal
            </label>
            <div
              className="row admin-ajout-projet-porteur-radio-container2"
              style={{ gap: "5px", marginLeft: "0.1rem" }}
            >
              {loading && <p>Chargement...</p>}
              {!loading &&
                (results?.length ? (
                  results
                    ?.slice()
                    ?.sort(
                      (a, b) => parseInt(a?.montant) - parseInt(b?.montant)
                    )
                    ?.map((mtn, i) => (
                      <div
                        className="col-md-3 form-check modal-investissement-montants-radio-label"
                        key={mtn?.id}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          id={`ajoutporteurradio${mtn?.id}`}
                          value={mtn?.montant}
                          {...register("min_amount")}
                          data-testid={`min_amounId${i}`}
                        />
                        <label
                          className="form-check-label pt-1"
                          htmlFor={`ajoutporteurradio${mtn?.id}`}
                        >
                          {mtn?.montant} f
                        </label>
                      </div>
                    ))
                ) : (
                  <div>
                    {EmptyMessage("Aucun montant disponible pour le moment.")}
                  </div>
                ))}

              {/* <div className="form-check modal-investissement-montants-radio-label">
                <input
                  className="form-check-input"
                  type="radio"
                  id="ajoutporteurradio5"
                  value="5000"
                  {...register('min_amount')}
                />
                <label
                  className="form-check-label pt-1"
                  htmlFor="ajoutporteurradio5"
                >
                  5 000 f
                </label>
              </div>
              <div className="form-check modal-investissement-montants-radio-label">
                <input
                  className="form-check-input"
                  type="radio"
                  id="ajoutporteurradio6"
                  value="10000"
                  {...register('min_amount')}
                />
                <label
                  className="form-check-label pt-1"
                  htmlFor="ajoutporteurradio6"
                >
                  10 000 f
                </label>
              </div> */}
            </div>
            {/* <div className="admin-ajout-projet-porteur-radio-container3">
              <div className="form-check modal-investissement-montants-radio-label">
                <input
                  className="form-check-input"
                  type="radio"
                  id="ajoutporteurradio7"
                  value="15000"
                  {...register('min_amount')}
                />
                <label
                  className="form-check-label pt-1"
                  htmlFor="ajoutporteurradio7"
                >
                  15 000 f
                </label>
              </div>
              <div className="form-check modal-investissement-montants-radio-label">
                <input
                  className="form-check-input"
                  type="radio"
                  id="ajoutporteurradio8"
                  value="20000"
                  {...register('min_amount')}
                />
                <label
                  className="form-check-label pt-1"
                  htmlFor="ajoutporteurradio8"
                >
                  20 000 f
                </label>
              </div>
              <div className="form-check modal-investissement-montants-radio-label">
                <input
                  className="form-check-input"
                  type="radio"
                  id="ajoutporteurradio9"
                  value="25000"
                  {...register('min_amount')}
                />
                <label
                  className="form-check-label pt-1"
                  htmlFor="ajoutporteurradio9"
                >
                  25 000 f
                </label>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="lien" className="majjal-label mb-2">
              Ajouter des liens de vos reseaux et sites web
            </label>
            <input
              type="text"
              className="form-control-majjal form-control form-control-lg"
              id="lien"
              placeholder="liens site web ou reseau soial"
              {...register("links")}
              data-testid="linkId"
            />
            {errors.links && (
              <div className="alert alert-danger sia-alert-danger closer mt-2">
                {errors.links?.message?.toString()}
              </div>
            )}
            <div className="form-check modal-investissement-montants-radio-label">
              <input
                className="form-check-input"
                type="checkbox"
                id="ajoutporteurradio10"
                {...register("network")}
                data-testid="networkId"
              />
              <label
                className="form-check-label pt-1"
                htmlFor="ajoutporteurradio10"
              >
                Je n’ai pas encore de réseau social ou de site web
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="contact" className="majjal-label mb-2">
              Comment pouvons-nous vous contacter
            </label>
            <input
              type="email"
              className="form-control-majjal form-control form-control-lg mb-2"
              id="contact"
              placeholder="Adresse mail"
              {...register("email")}
              data-testid="emailId"
            />
            {errors.email && (
              <div className="alert alert-danger sia-alert-danger closer mt-2">
                {errors.email?.message?.toString()}
              </div>
            )}
            <PhoneInput
              placeholder="Téléphone"
              error={errors.telephone?.message?.toString()}
              value={phone}
              onChnage={setPhone}
              name="telephone"
            />
          </div>
        </div>
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="date_debut" className="majjal-label mb-2">
              Date de debut
            </label>
            <input
              type="date"
              className="form-control-majjal form-control form-control-lg"
              id="date_debut"
              placeholder="Date de debut"
              {...register("date_debut")}
              data-testid="date_debutId"
            />
            {errors.date_debut && (
              <div className="alert alert-danger sia-alert-danger closer mt-2">
                {errors.date_debut?.message?.toString()}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="date_fin" className="majjal-label mb-2">
              Date de fin
            </label>
            <input
              type="date"
              className="form-control-majjal form-control form-control-lg"
              id="date_fin"
              placeholder="Date de fin"
              {...register("date_fin")}
              data-testid="date_finId"
            />
            {errors.date_fin && (
              <div className="alert alert-danger sia-alert-danger closer mt-2">
                {errors.date_fin?.message?.toString()}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="category" className="majjal-label mb-2">
              Catégorie
            </label>
            <div className="position-relative">
              <select
                id="category"
                className="form-control-majjal form-select-majjal"
                {...register("category_id")}
                data-testid="categoryId"
              >
                <option value={""}>Choisir une catégorie</option>
                {categorie?.map((category) => (
                  <option value={category?.id} key={category?.id}>
                    {category?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="category" className="majjal-label mb-2">
              Etat du projet
            </label>
            <div className="position-relative">
              <select
                id="category"
                className="form-control-majjal form-select-majjal"
                {...register("statut")}
                data-testid="statutId"
              >
                {etatProjet.map((etat) => (
                  <option value={etat.value} key={etat.value}>
                    {etat.label}
                  </option>
                ))}
              </select>
              
            </div>
          </div>
        </div>
        <div className="col-md-12 form-input-col">
          {/* <DropZoneImage setValue={setValue} /> */}
          <label htmlFor="image" className="majjal-label mb-2">
            Image
          </label>
          <label htmlFor="imag" className="auth-identite-file flex-sb">
            <span className="img-produit-placeholder">
              Image (PNG, JPEG, GIF)
            </span>
            <GrAttachment className="auth-file-input-icon" style={iconStyle} />
          </label>
          <input
            type="file"
            className="op-0 h-0 imgInp"
            placeholder="Photo d’identité recto"
            id="imag"
            onChange={handleChange}
            accept="image/*"
          />
          {!!preview && (
            <div className="img-preview-container">
              <img src={preview} className="cni-img-preview" />
            </div>
          )}

          {errors.photo && (
            <div className="alert alert-danger sia-alert-danger closer">
              {errors.photo?.message?.toString()}
            </div>
          )}
        </div>
        <div className="col-md-12 form-input-col">
          <div className="form-group mb-4">
            <label htmlFor="lien_video" className="majjal-label mb-2">
              Vidéo
            </label>
            <input
              type="text"
              className="form-control-majjal form-control form-control-lg"
              id="lien_video"
              placeholder="Lien de la vidéo"
              {...register("video_link")}
              data-testid="video_linkId"
            />
            {errors.video_link && (
              <div className="alert alert-danger sia-alert-danger closer">
                {errors.video_link?.message?.toString()}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 submit-input-col">
          <div className="submit-btn-container">
            {isLoading && (
              <button disabled className="btn-submit">
                En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
              </button>
            )}
            {!isLoading && (
              <button
                className="btn-submit"
                type="submit"
                data-testid="btnAjouterId"
              >
                {update ? "Modifier" : "Ajouter"}
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
