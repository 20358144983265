import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/store";
import { useSharePointMutation } from "../../../../utils/api/user/user.api";
import { User } from "../../../../utils/api/user/user.type";
import { COLORS } from "../../../../utils/constants";

interface PropsType {
  resetIndex: () => void;
  user: User;
}

const FormToSendPoint: React.FC<PropsType> = ({ resetIndex, user }) => {
  const cu = useAppSelector((s) => s.user.user as User);
  const [point, setPoint] = useState(0);
  const [error, setError] = useState<null | string>(null);
  const [share, { isLoading, isSuccess }] = useSharePointMutation();
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "partage effectué avec succès!",
        iconColor: COLORS.green,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        resetIndex();
      });
    }
  }, [isSuccess]);
  return (
    <div className="px-4">
      <div className="form-group">
        <label htmlFor="points" className="form-send-point-label mb-2">
          Nombre de point à partager à{" "}
          <span style={{ fontWeight: "bold" }}>
            {user?.first_name} {user.last_name}
          </span>
        </label>
        <input
          type="number"
          onChange={(e) => setPoint(parseInt(e.target.value))}
          value={point}
          placeholder="Nombre de points"
          className="form-control form-send-point-control"
          id="points"
        />
        <small>Mes points: {cu.point} pts</small>
        {!!error && (
          <div className="alert alert-danger sia-alert-danger closer mt-2">
            {error}
          </div>
        )}
      </div>
      <div className="modal-btn-container mt-4 flex-r gap-3">
        <button
          type="button"
          className="btn-cancel-share-point "
          onClick={resetIndex}
        >
          Annuler
        </button>
        {isLoading && (
          <button className="btn-shop btn-shop-next">Chargement</button>
        )}
        {!isLoading && (
          <button
            onClick={() => {
              if (!point || point <= 0) {
                setError("Merci de remplir ce champ");
              } else {
                if (cu.point < point) {
                  setError("Pas assez de points");
                } else {
                  setError(null);
                  share({
                    point,
                    receiver: user.id,
                    sender: cu.id,
                  });
                }
              }
            }}
            className="btn-shop btn-shop-next"
          >
            Envoyer
          </button>
        )}
      </div>
    </div>
  );
};

export default FormToSendPoint;
