import React, { useEffect, useMemo } from 'react'
import { useUpdateCommentMutation } from '../../../../utils/api/commentaire/commentaire.api';
import BtnCloseModal from '../../../shared/BtnCloseModal'
import BtnSubmit from '../../../shared/BtnSubmit'
import Swal from "sweetalert2";
import * as yup from "yup";
import { CommentaireFormData } from '../../../../utils/api/commentaire/commentaire.type';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

function ModifCommentaireModal({comment}) {
    console.log('comment', comment)

    const [sendData, { isSuccess, isLoading, data }] = useUpdateCommentMutation();

    const validationSchema = useMemo(
        () =>
          yup.object().shape({
            commentaire: yup.string()
          }),
        []
      );
      const { register, setValue, formState, clearErrors, handleSubmit } =
        useForm<CommentaireFormData>({
          resolver: yupResolver(validationSchema),
        });

        useEffect(() => {
            if (comment?.id) {
                setValue("commentaire", comment?.commentaire);
            }
        }, [comment?.id]);

        const onSubmit = async (data) => {
            await sendData(data);
        };
        

  return (
    <div className="modal fade" id="modifierCommentaireModal">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content px-4 py-3">
          <div className="flex-csb mb-4">
            <h1 className="admin-modal-title m-0">Modifier le commentaire</h1>
            <BtnCloseModal />
          </div>
          <div className="reponse-form-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-lg-8">
                        <input
                            type="text"
                            className="form-control mb-3 mb-lg-0 campagne-detail-projet-formulaire-input"
                            id="inputCommentaire"
                            placeholder="Laisser un commentaire"
                            {...register("commentaire")}
                        />
                        {formState.errors.commentaire && (
                            <div className="alert alert-danger sia-alert-danger closer mt-2">
                            {formState.errors.commentaire?.message?.toString()}
                            </div>
                        )}
                    </div>
                    <div className="col-lg-4">
                        {isLoading && (
                            <button
                            disabled
                            className="btn mb-3 campagne-detail-projet-formulaire-buton"
                            >
                            En cours&nbsp; <i className="fas fa-spin fa-spinner"></i>
                            </button>
                        )}
                        {!isLoading && (
                            <button
                            type="submit"
                            className="btn mb-3 campagne-detail-projet-formulaire-buton"
                            >
                            Modifier
                            </button>
                        )}
                    </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModifCommentaireModal