import React from "react";
import Logo from "../../../assets/appImage/LogoMajjal.png";

const AuthPanel: React.FC = () => {
  return (
    <div className="col-md-4">
      <div className="no-view-mobile py-5">
        <div className="auth-panel bg-theme">
          <div className="logo-container">
            <img src={Logo} alt="app-logo" className="logo img-fluid" />
          </div>
        </div>
      </div>
      <div className="no-view-desktop">
        <div className="py-2 bg-theme text-center">
          <div className="logo-container">
            <img
              src={Logo}
              alt="app-logo"
              style={{ width: 60, height: 60 }}
              className="logo img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPanel;
