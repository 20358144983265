import { NavLink } from "react-router-dom";

import moment from "moment";
import { useEffect, useState } from "react";
import { AiFillHeart } from "react-icons/ai";
import { FiClock, FiHeart, FiShare2, FiUsers } from "react-icons/fi";
import { useAppSelector } from "../../../redux/store";
import { Projet } from "../../../utils/api/launchCampagn/projet.type";
import LikeApi, { useLikes } from "../../../utils/api/like/like.api";
import { LikeFormData } from "../../../utils/api/like/like.type";
import { formatAmout, getImage } from "../../../utils/utils";
import { getRoutePath } from "../../routes/routes";
import CampainsSkeleton from "../compains/CompainsListe/CampainsSkeleton";
import { ShareModal } from "../Modals/SharePoints/SharePointModal";

const ProjectsList = ({
  projects,
  isLoading,
}: {
  projects: Projet[];
  isLoading: boolean;
}) => {
  return (
    <div className="row">
      {isLoading &&
        [...Array(6)].map((item, index) => {
          return <CampainsSkeleton key={index} />;
        })}
      {!isLoading && projects.map((p) => <ProjectItem item={p} key={p.id} />)}
    </div>
  );
};

export default ProjectsList;

function ProjectItem({ item }: { item: Projet }) {
  const modalShareId = `sharemodal${item.id}`;
  let duree = moment(item.date_fin).diff(moment(), "days");
  const { user } = useAppSelector((s) => s.user);
  const isAdmin = user?.user_type === "admin";

  if (duree < 0) {
    duree = 0;
  }
  return (
    <div className="col-md-6 col-lg-4">
      <div className="card custom-card-campagne">
        <NavLink
          state={item}
          to={getRoutePath("CampainsDetails", { slug: item.slug })}
          className="menu-link"
        >
          <img
            src={getImage(item.photo)}
            className="card-img-top custom-card-img-top"
            alt="img campagne"
          />
        </NavLink>
        <div className="card-body">
          <h5 className="card-title card-campagne-title">{item.name}</h5>
          <div className="card-campagne-progresse-container">
            <div className="card-campagne-progresse-price mb-1">
              {formatAmout(item.objectif_levee)}
            </div>
            <div className="progress mb-3" style={{ height: "3px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${Math.ceil(item.pourcentage)}%`,
                  backgroundColor: "#0FBF56",
                }}
                aria-valuenow={Math.ceil(item.pourcentage)}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
            <div className="card-campagne-progresse-details mb-4">
              <p>{Math.ceil(item.pourcentage)}% Financement</p>
              <p>
                <FiUsers className="card-campagne-icons" />{" "}
                {item.nb_investisseur} bailleurs
              </p>
              <p>
                <FiClock className="card-campagne-icons" /> {duree} jours
              </p>
            </div>
          </div>
          <div className="card-campagne-button-share-container">
            <div className="card-campagne-button-share-like">
              <LikeButton item={item} modalShareId={modalShareId} />
            </div>
            {isAdmin ? (
              <NavLink
                state={item}
                className="btn campagne-button-contribuer"
                to={getRoutePath("CampainsDetails", { slug: item.slug })}
              >
                Détails
              </NavLink>
            ) : (
              <NavLink
                to={getRoutePath("CampainsDetails", { slug: item.slug })}
                state={item}
                className="btn campagne-button-contribuer"
              >
                Contribuer
              </NavLink>
            )}
          </div>
        </div>
      </div>
      <ShareModal item={item} modalId={modalShareId} />
    </div>
  );
}

export function LikeButton({
  item,
  modalShareId,
}: {
  item: Projet;
  modalShareId: string;
}) {
  const likes = useLikes();
  const [updateLike, { isLoading }] = LikeApi.useMajMutation();
  const userId = useAppSelector((s) => s.user?.user?.id);

  const [liked, setLiked] = useState(false);
  const [nb_likes, setNbLikes] = useState(item.nb_like);
  const [like, setLike] = useState<any>(null);

  const { user } = useAppSelector((s) => s.user);
  const isAdmin = user?.user_type === "admin";

  useEffect(() => {
    setLike(likes.find((l) => l.projet === item?.id));
  }, [likes]);
  useEffect(() => {
    setLiked(!!like);
  }, [like]);

  return (
    <>
      {isAdmin && (
        <button
          onClick={() => {
            if (isLoading) return;
            if (userId && userId !== (item.user?.id || item.user)) {
              if (!liked) {
                setNbLikes((old) => old + 1);
              } else {
                setNbLikes((old) => old - 1);
              }
              setLiked((old) => !old);
              const sendData: LikeFormData = {
                projet: item.id,
                user: userId,
                id: like?.id,
              };
              updateLike(sendData);
            }
          }}
          disabled
          type="button"
          className="btn campagne-button-share-like"
        >
          {liked && <AiFillHeart />}
          {!liked && <FiHeart />} {nb_likes}
        </button>
      )}
      {!isAdmin && (
        <button
          onClick={() => {
            if (isLoading) return;
            if (userId && userId !== (item.user?.id || item.user)) {
              if (!liked) {
                setNbLikes((old) => old + 1);
              } else {
                setNbLikes((old) => old - 1);
              }
              setLiked((old) => !old);
              const sendData: LikeFormData = {
                projet: item.id,
                user: userId,
                id: like?.id,
              };
              updateLike(sendData);
            }
          }}
          type="button"
          className="btn campagne-button-share-like"
        >
          {liked && <AiFillHeart />}
          {!liked && <FiHeart />} {nb_likes}
        </button>
      )}
      {/* <button
        onClick={() => {
          if (isLoading) return;
          if (userId && userId !== (item.user?.id || item.user)) {
            if (!liked) {
              setNbLikes((old) => old + 1);
            } else {
              setNbLikes((old) => old - 1);
            }
            setLiked((old) => !old);
            const sendData: LikeFormData = {
              projet: item.id,
              user: userId,
              id: like?.id,
            };
            updateLike(sendData);
          }
        }}
        type="button"
        className="btn campagne-button-share-like"
      >
        {liked && <AiFillHeart />}
        {!liked && <FiHeart />} {nb_likes}
      </button> */}
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target={`#${modalShareId}`}
        className="btn campagne-button-share-like"
      >
        <FiShare2 />
      </button>
    </>
  );
}
