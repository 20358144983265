import { NavLink } from "react-router-dom";
import ConfigApi from "../../../utils/api/config/config.api";
import ImgFoter from "../../assets/appImage/logo-footer.png";
import Facebook from "../../assets/icons/facebook.png";
import WhatsappImg from "../../assets/icons/icon-whatsapp.png";
import Linkedin from "../../assets/icons/linkedin.png";
import Twitter from "../../assets/icons/twitter.png";
import Youtube from "../../assets/icons/youtube.png";
import { getRoutePath } from "../../routes/routes";
import "./FrontFooter.css";

const FrontFooter = () => {
  const { data } = ConfigApi.useGetConfigQuery();
  return (
    <>
      {!!data && (
        <a
          href={`https://wa.me/${data?.phone2?.replace("+", "")}/`}
          target="_blank"
          className="btn btn-float-whatsapp"
        >
          <img
            src={WhatsappImg}
            alt="icon whatsapp"
            style={{ width: 100, height: 100, objectFit: "contain" }}
          />
        </a>
      )}

      <footer className="component-front-footer">
        <div className="container-maajjal-footer">
          <div className="body-footer">
            <div className="row">
              <div className="col-xl-5 col-lg-5 mb-5 col-md-6 footer-grid">
                <img src={ImgFoter} alt="Footer Company Informations" />
                <p
                  className="link-footer trans-0-2 d-block my-3"
                  dangerouslySetInnerHTML={{
                    __html: data?.description as string,
                  }}
                />
                <p className="title-footer-contact mb-2 mt-4">Téléphone</p>
                <a
                  href={`tel:${data?.phone1}`}
                  target="_blank"
                  className="link-footer trans-0-2 d-block mb-1"
                >
                  {data?.phone1}
                </a>
                <p className="title-footer-contact mb-2 mt-4">Email</p>
                <p className="link-footer trans-0-2 d-block mb-3">
                  <a className="text-white" href={`mailto:${data?.email}`}>
                    {data?.email}
                  </a>
                </p>
              </div>
              <div className="col-xl-3 col-lg-3 mb-5 col-md-6 footer-grid">
                <p className="title-footer mb-4">Navigation</p>
                <NavLink
                  className="link-footer-navigation trans-0-2 d-block mb-3"
                  to={"/"}
                >
                  Accueil
                </NavLink>
                <NavLink
                  className="link-footer-navigation trans-0-2 d-block mb-3"
                  to={getRoutePath("CompainsListe")}
                >
                  Campagne
                </NavLink>
                <NavLink
                  className="link-footer-navigation trans-0-2 d-block mb-3"
                  to={getRoutePath("MarketPlace")}
                >
                  Market place
                </NavLink>
                <NavLink
                  className="link-footer-navigation trans-0-2 d-block mb-3"
                  to={getRoutePath("Apropos")}
                >
                  Qui-sommes-nous
                </NavLink>
              </div>
              <div className="col-xl-4 col-lg-4 mb-5 col-md-12  footer-grid">
                <div className="d-flex flex-column">
                  <p className="title-footer mb-4">Suivez-nous</p>
                  <div className="d-flex mb-4">
                    <a
                      className="me-2 trans-0-2"
                      href={data?.facebook || "#"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={Facebook}
                        alt="Facebook"
                        className="icon-social-footer"
                      />
                    </a>
                    <a
                      className="mx-2 trans-0-2"
                      href={data?.twitter || "#"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={Twitter}
                        alt="Twitter"
                        className="icon-social-footer"
                      />
                    </a>
                    <a
                      className="mx-2 trans-0-2"
                      href={data?.linkedin || "#"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={Linkedin}
                        alt="Instagram"
                        className="icon-social-footer"
                      />
                    </a>
                    <a
                      className="mx-2 trans-0-2"
                      href={data?.youtube || "#"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={Youtube}
                        alt="Instagram"
                        className="icon-social-footer"
                      />
                    </a>
                  </div>
                  <NavLink
                    className="link-footer-cgu trans-0-2 mb-4 d-block"
                    to={getRoutePath("CGU")}
                  >
                    Conditions et termes
                  </NavLink>
                  <NavLink
                    className="link-footer-cgu trans-0-2 mb-4 d-block"
                    to={getRoutePath("PC")}
                  >
                    Mentions legales
                  </NavLink>
                  <NavLink
                    className="link-footer-nous-contacter btn w-50 trans-0-2 mb-3"
                    to={getRoutePath("ContactezNous")}
                  >
                    Nous contacter
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mx-0 end-footer py-md-4 py-4 d-flex justify-content-center align-items-center">
          <div className="mt-lg-0 mt-5 d-flex justify-content-center">
            <p className="copyright">2022 © Maajjal</p>
          </div>
        </div>
      </footer>
    </>
  );
};
export default FrontFooter;
