import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";
import { getRoutePath } from "../../routes/routes";

function ConfirmationFinal({ point, onHide, phone }) {
  const user = useAppSelector((s) => s.user.user);
  return (
    <>
      <div className="modal-investissement-description-content2">
        <div className="modal-investissement-description-confirm">
          Confirmation
        </div>
        <div className="modal-investissement-confirm-para-container">
          <div className="modal-investissement-confirm-para">
            Votre contribution est bien prise en compte. Vous venez de recevoir{" "}
            <span className="modal-investissement-confirm-para-span">
              {point} points.
            </span>
          </div>
          {!user?.id && (
            <div className="modal-investissement-confirm-para">
              <NavLink
                to={getRoutePath("register")}
                onClick={onHide}
                state={{ phone }}
                className="modal-investissement-confirm-para-span"
              >
                je m'inscris pour récupérer mes points
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ConfirmationFinal;
